import { FC } from "react";

import {
  CreditEvaluationLoanAffordability,
  CreditEvaluationLoanAffordabilityEnum,
} from "api/creditEvaluations/types";

import LoanAffordabilitiesItem from "./LoanAffordabilitiesItem";

interface ILoanAffordabilities {
  data: CreditEvaluationLoanAffordability[];
}

export const loanAffordabilitiesSort = (
  loanAffordabilitiesList: CreditEvaluationLoanAffordability[],
) => {
  const incomeOverviewPriority = {
    [CreditEvaluationLoanAffordabilityEnum.SELECTED_INCOME]: 0,
    [CreditEvaluationLoanAffordabilityEnum.AFFORDABILITY_INCLUDING_STUDENT_LOAN_DEBT]: 1,
    [CreditEvaluationLoanAffordabilityEnum.AFFORDABILITY_INCLUDING_RENT]: 2,
    [CreditEvaluationLoanAffordabilityEnum.AFFORDABILITY_INCLUDING_RENT_AND_DEFERRED_STUDENT_LOANS]: 3,
    [CreditEvaluationLoanAffordabilityEnum.AFFRODABILITY_HALF_MORTAGE]: 4,
    [CreditEvaluationLoanAffordabilityEnum.HOUSEHOLD_INCOME]: 5,
    [CreditEvaluationLoanAffordabilityEnum.HOUSEHOLD_AFFORDABILITY_INCLUDING_STUDENT_LOAN_DEBT]: 6,
    [CreditEvaluationLoanAffordabilityEnum.HOUSEHOLD_AFFORDABILITY_INCLUDING_RENT]: 7,
    [CreditEvaluationLoanAffordabilityEnum.HOUSEHOLD_AFFORDABILITY_INCLUDING_RENT_AND_DEFERRED_STUDENT_LOANS]: 8,
  };

  return loanAffordabilitiesList.sort(
    (a, b) =>
      incomeOverviewPriority[a.source] - incomeOverviewPriority[b.source],
  );
};

const LoanAffordabilities: FC<ILoanAffordabilities> = ({ data }) => {
  return (
    <>
      {loanAffordabilitiesSort(data).map((item, index) => (
        <LoanAffordabilitiesItem data={item} defaultExpanded={index < 2} />
      ))}
    </>
  );
};

export default LoanAffordabilities;
