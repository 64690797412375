import {
  Business as BusinessIcon,
  Group as GroupIcon,
  Handshake as LoanPackagesIcon,
  Note as NoteIcon,
  Person as PersonIcon,
} from "@mui/icons-material";
import { Box, Divider, MenuList, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

// COMPONENTS:
import { UserTypeRoleType } from "api/user/types";
import { hasPermissions, hasRole } from "components/stores/UserStore";
import { PermissionsType } from "components/stores/UserStore/permissions";

import SidebarMenuItem, { SidebarMenuItemsProps } from "./SidebarMenuItem";

// ICONS:

export type SidebarMenuListChild = Array<
  SidebarMenuItemsProps & {
    requiredPermissions?: PermissionsType[];
    requiredRole?: UserTypeRoleType[];
  }
>;

type SidebarMenuList = Array<{
  id: string;
  text: string | JSX.Element;
  list: SidebarMenuListChild;
}>;

const sidebarMenuListDashboard: SidebarMenuListChild = [
  // {
  //   icon: <SearchIcon />,
  //   text: <FormattedMessage id="GLOBAL.SEARCH" />,
  //   link: "/dashboard",
  // },
  {
    icon: <NoteIcon />,
    text: <FormattedMessage id="HEADER.CREDIT_EVALUATIONS" />,
    link: "/credit-evaluations",
    requiredPermissions: ["read:credit-evaluations"],
  },
  {
    icon: <LoanPackagesIcon />,
    text: <FormattedMessage id="HEADER.LOAN_PACKAGES" />,
    link: "/loan-packages",
    requiredPermissions: ["read:loan-packages"],
  },
  {
    icon: <GroupIcon />,
    text: <FormattedMessage id="HEADER.CUSTOMERS" />,
    link: "/customers",
    requiredPermissions: ["read:customers"],
  },
];

const sidebarMenuListAdmin: SidebarMenuListChild = [
  {
    icon: <PersonIcon />,
    text: <FormattedMessage id="HEADER.USERS" />,
    link: "/users",
    requiredPermissions: ["read:users"],
  },
  {
    icon: <BusinessIcon />,
    text: <FormattedMessage id="HEADER.ORGANISATIONS" />,
    link: "/organisations",
    requiredPermissions: ["read:organisations"],
  },
];

const sidebarMenuList: SidebarMenuList = [
  {
    id: "dashboard",
    text: <FormattedMessage id="HEADER.APPLICATION_DASHBOARD" />,
    list: sidebarMenuListDashboard,
  },
  {
    id: "admin",
    text: <FormattedMessage id="HEADER.ADMIN" />,
    list: sidebarMenuListAdmin,
  },
];

interface Props {
  onClose?: () => void;
}

const SidebarMenu = ({ onClose }: Props) => {
  return (
    <MenuList
      sx={{
        overflowY: "auto",
        overflowX: "hidden",
        maxHeight: "calc(100% - 45px)",
        pt: 2,
        msOverflowStyle: "none",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        pl: 1,
        pr: 1,
      }}
    >
      {sidebarMenuList.map(({ id, list, text }) => {
        const filteredList = list.filter(
          ({ requiredRole, requiredPermissions }) =>
            hasPermissions(requiredPermissions) && hasRole(requiredRole),
        );

        if (!filteredList.length) {
          return null;
        }

        return (
          <Box key={id}>
            <Typography
              variant="body2"
              sx={{
                ml: 1,
                my: 1.5,
                width: "80%",
                overflowX: "hidden",
                textOverflow: "ellipsis",
                color: (t) => t.palette.text.disabled,
                whiteSpace: "nowrap",
              }}
            >
              {text}
            </Typography>
            {filteredList.map(({ icon, text, link }) => {
              return (
                <SidebarMenuItem
                  key={link}
                  icon={icon}
                  text={text}
                  link={link}
                  onClick={onClose}
                />
              );
            })}
            <Divider sx={{ mx: 1 }} />
          </Box>
        );
      })}
    </MenuList>
  );
};

export default SidebarMenu;
