import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { QueryStatus } from "@tanstack/react-query";
import React, { FC, useCallback } from "react";
import { useIntl } from "react-intl";
import { CellProps, Column, Row } from "react-table";

import {
  CreditEvaluationIncome,
  CreditEvaluationIncomeSource,
} from "api/creditEvaluations/types";
import { hasPermissions } from "components/stores/UserStore";
import Table from "components/Table";
import { priceFormatter } from "components/Table/formatters/priceFormatter";
import { useModal } from "utils/hooks/useModal";

import DeleteIncomeModal from "../DeleteIncomeModal";
import EditIncomeModal from "../EditIncomeModal";
import IncomesActionsFormatter from "./formatters/IncomesActionsFormatter";
import { incomeTypeFormatter } from "./formatters/incomeTypeFormatter";
import IncomesPaystubsTable from "./SubTables/IncomesPaystubsTable";
import IncomesRetirementTable from "./SubTables/IncomesRetirementTable";
import IncomesSelfEmploymentTable from "./SubTables/IncomesSelfEmploymentTable";

interface Props {
  creditEvaluationId: string;
  data: CreditEvaluationIncome[];
  status: QueryStatus;
}
const IncomesTable: FC<Props> = ({ creditEvaluationId, data, status }) => {
  const intl = useIntl();

  const {
    isOpen: isOpenDelete,
    handleClose: handleCloseDelete,
    handleOpen: handleOpenDelete,
    context: deleteContext,
  } = useModal<CreditEvaluationIncome>();
  const {
    isOpen: isOpenEdit,
    handleClose: handleCloseEdit,
    handleOpen: handleOpenEdit,
    context: editContext,
  } = useModal<CreditEvaluationIncome>();

  const columns: Column<CreditEvaluationIncome>[] = [
    {
      accessor: "type",
      Header: intl.formatMessage({
        id: "CREDIT_EVALUATIONS.INCOMES.TABLE.TYPE",
      }),
      Aggregated: ({ row }: CellProps<CreditEvaluationIncome>) => {
        return (
          <Stack
            direction="row"
            alignItems="center"
            spacing={0.5}
            {...row.getToggleRowExpandedProps()}
          >
            <>
              {row.original.incomeSources?.length}
              {row.isExpanded ? (
                <KeyboardArrowDown sx={{ mr: 1 }} />
              ) : (
                <KeyboardArrowRight sx={{ mr: 1 }} />
              )}
              {incomeTypeFormatter(row.original)}
            </>
          </Stack>
        );
      },
      Cell: () => null,

      // Cell: ({ row }: CellProps<CreditEvaluationIncome>) => (
      //   <>{"#" + (row.index + 1)}</>
      // )
    },
    {
      accessor: "source",
      Header: "Source",
    },
    {
      accessor: "period",
      Header: intl.formatMessage({
        id: "CREDIT_EVALUATIONS.INCOMES.TABLE.PERIOD",
      }),
      Aggregated: ({ value }: CellProps<CreditEvaluationIncome>) => (
        <Box sx={{ textTransform: "capitalize" }}>{value}</Box>
      ),
      Cell: () => null,
    },
    {
      accessor: "averageCheckAmount",
      Header: intl.formatMessage({
        id: "CREDIT_EVALUATIONS.INCOMES.TABLE.AVERAGE_CHECK_AMOUNT",
      }),
      Aggregated: ({ value }: CellProps<CreditEvaluationIncome>) => (
        <>{priceFormatter(value)}</>
      ),
      Cell: () => null,
    },
    {
      accessor: "averageCheckAmountBasedOnYTD",
      Header: intl.formatMessage({
        id: "CREDIT_EVALUATIONS.INCOMES.TABLE.AVERAGE_CHECK_AMOUNT_BASED_ON_YTD",
      }),
      Aggregated: ({ value }: CellProps<CreditEvaluationIncome>) => (
        <>{priceFormatter(value)}</>
      ),
      Cell: () => null,
    },
    {
      id: "actions",
      Header: intl.formatMessage({ id: "GLOBAL.ACTIONS" }),
      Aggregated: (cell: CellProps<CreditEvaluationIncome>) => (
        <IncomesActionsFormatter
          {...cell}
          handleOpenEdit={handleOpenEdit}
          handleOpenDelete={handleOpenDelete}
        />
      ),
      Cell: () => null,
      align: "right",
    },
  ];

  const getSubRows = useCallback(
    (incomeRow: any) =>
      incomeRow.incomeSources?.length ? [incomeRow.incomeSources] : [],
    [],
  );

  return (
    <>
      <Paper>
        <Table
          data={data}
          columns={columns}
          status={status}
          getSubRows={getSubRows}
          autoResetExpanded={false}
          initialState={{
            sortBy: [
              {
                id: "type",
                desc: true,
              },
            ],
          }}
          subComponent={(row: Row<CreditEvaluationIncomeSource[]>) => {
            let subTableTitle = "No Income Sources Founded";
            let subTable;

            if (row.original.length) {
              const income = data.find((income) =>
                income.incomeSources.some(
                  //@ts-expect-error
                  (incomeSource) => incomeSource._id === row.original[0]._id,
                ),
              );

              if (row.original[0].amount) {
                subTableTitle = "W2/Paystub Income Sources";
                subTable = (
                  <IncomesPaystubsTable
                    creditEvaluationId={creditEvaluationId}
                    income={income!}
                    data={row.original}
                    status={status}
                  />
                );
              } else if (row.original[0].grossRevenue) {
                subTableTitle = "Self Employment Income Sources";
                subTable = (
                  <IncomesSelfEmploymentTable
                    creditEvaluationId={creditEvaluationId}
                    income={income!}
                    data={row.original}
                    status={status}
                  />
                );
              } else if (row.original[0].monthlyBenefit) {
                subTableTitle = "Additional Income Sources";
                subTable = (
                  <IncomesRetirementTable data={row.original} status={status} />
                );
              }
            }

            return (
              <Box component="td" colSpan={columns.length + 1} sx={{ p: 2 }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                  {subTableTitle}
                </Typography>
                {subTable}
              </Box>
            );
          }}
          manualPagination
        />
      </Paper>

      {hasPermissions("update:credit-evaluations") && (
        <DeleteIncomeModal
          isOpen={isOpenDelete}
          handleClose={handleCloseDelete}
          creditEvaluationId={creditEvaluationId}
          income={deleteContext}
        />
      )}
      {hasPermissions("update:credit-evaluations") && (
        <EditIncomeModal
          creditEvaluationId={creditEvaluationId}
          isOpen={isOpenEdit}
          handleClose={handleCloseEdit}
          context={editContext}
        />
      )}
    </>
  );
};

export default IncomesTable;
