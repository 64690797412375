import { Checkbox, Paper, Stack, TextField } from "@mui/material";
import {
  QueryStatus,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { mean } from "lodash";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { NumericFormat } from "react-number-format";
import { CellProps, Column } from "react-table";

import { editCreditEvaluationIncome } from "api/creditEvaluations";
import { creditEvaluationsKeys } from "api/creditEvaluations/queries";
import {
  CreditEvaluationIncome,
  CreditEvaluationIncomeSource,
} from "api/creditEvaluations/types";
import Table from "components/Table";
import { dateFormatter } from "components/Table/formatters/dateFormatter";
import { priceFormatter } from "components/Table/formatters/priceFormatter";
import { simpleNumberFormatter } from "components/Table/formatters/simpleNumberFormatter";
interface IncomesPaystubsTableProps {
  income: CreditEvaluationIncome;
  creditEvaluationId: string;
  data: CreditEvaluationIncomeSource[];
  status: QueryStatus;
}

const IncomesPaystubsTable: FC<IncomesPaystubsTableProps> = ({
  income,
  creditEvaluationId,
  data,
  status,
}) => {
  const intl = useIntl();
  const queryClient = useQueryClient();

  const { mutateAsync: editIncome, isLoading } = useMutation(
    async (newIncome: any) => {
      const res = await editCreditEvaluationIncome(
        creditEvaluationId,
        newIncome._id,
        newIncome,
      );

      await queryClient.invalidateQueries(
        creditEvaluationsKeys.details(creditEvaluationId),
      );

      return res.data;
    },
  );

  const handleChangeCalculatedIncome = async (id: string, value: number) => {
    const incomeSources = [...data].map((incomeSource) => {
      //@ts-expect-error
      if (incomeSource._id === id) {
        incomeSource.calculatedIncome = value;
      }

      return incomeSource;
    });

    const newIncome = {
      ...income,
      incomes: incomeSources,
    };

    await editIncome(newIncome);
  };

  const columns: Column<CreditEvaluationIncomeSource>[] = [
    {
      id: "#",
      Header: intl.formatMessage({
        id: "CREDIT_EVALUATIONS.INCOMES.TABLE.ID",
      }),

      Cell: ({ row }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{"#" + (row.index + 1)}</>
      ),
    },

    {
      accessor: "date",
      Header: "Pay Date",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{dateFormatter(value)}</>
      ),
    },
    {
      accessor: "amount",
      Header: "Amount",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{priceFormatter(value)}</>
      ),
    },
    {
      accessor: "ytd",
      Header: "YTD",
      Cell: ({ value, row }: CellProps<CreditEvaluationIncomeSource>) => (
        <Stack
          direction="row"
          alignItems="center"
          sx={{ ml: -1.5 }}
          spacing={1}
        >
          <Checkbox
            disabled={isLoading}
            checked={value === row.original.calculatedIncome}
            onChange={() => {
              //@ts-expect-error
              handleChangeCalculatedIncome(row.original._id, value);
            }}
          />
          {priceFormatter(value)}
        </Stack>
      ),
    },
    {
      accessor: "averageAnnual",
      Header: "Avg. Annual",
      Cell: ({ value, row }: CellProps<CreditEvaluationIncomeSource>) => (
        <Stack
          direction="row"
          alignItems="center"
          sx={{ ml: -1.5 }}
          spacing={1}
        >
          <Checkbox
            disabled={isLoading}
            checked={value === row.original.calculatedIncome}
            onChange={() => {
              //@ts-expect-error
              handleChangeCalculatedIncome(row.original._id, value);
            }}
          />
          {priceFormatter(value)}
        </Stack>
      ),
    },
    {
      accessor: "numberOfPeriodsToDate",
      Header: "# of Periods to Date",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{simpleNumberFormatter(value)}</>
      ),
    },
    {
      accessor: "avgPerPeriod",
      Header: "Avg. Per Period",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{priceFormatter(value)}</>
      ),
    },
    {
      accessor: "averageAnnual2",
      Header: "Avg. Annual 2",
      Cell: ({ row, value }: CellProps<CreditEvaluationIncomeSource>) => (
        <Stack
          direction="row"
          alignItems="center"
          sx={{ ml: -1.5 }}
          spacing={1}
        >
          <Checkbox
            disabled={isLoading}
            checked={value === row.original.calculatedIncome}
            onChange={() => {
              //@ts-expect-error
              handleChangeCalculatedIncome(row.original._id, value);
            }}
          />
          {priceFormatter(value)}
        </Stack>
      ),
    },
    {
      accessor: "numberOfPeriodsRemaining",
      Header: "# of Periods Remaining",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{simpleNumberFormatter(value)}</>
      ),
    },
    {
      accessor: "amountOfPayRemaining",
      Header: "$ of Pay Remaining",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{priceFormatter(value)}</>
      ),
    },
    {
      accessor: "endOfYearExpectedIncome",
      Header: "End of Year Expected Income",
      Cell: ({ value, row }: CellProps<CreditEvaluationIncomeSource>) => (
        <Stack
          direction="row"
          alignItems="center"
          sx={{ ml: -1.5 }}
          spacing={1}
        >
          <Checkbox
            disabled={isLoading}
            checked={value === row.original.calculatedIncome}
            onChange={() => {
              //@ts-expect-error
              handleChangeCalculatedIncome(row.original._id, value);
            }}
          />
          {priceFormatter(value)}
        </Stack>
      ),
    },
    {
      accessor: "calculatedIncome",
      Header: "Calculated Income",
      Cell: ({ value, row }: CellProps<CreditEvaluationIncomeSource>) => {
        let editTimeout: NodeJS.Timeout | undefined;
        return (
          <>
            <NumericFormat
              fullWidth
              thousandSeparator
              prefix="$"
              disabled={isLoading}
              value={value.toFixed(2)}
              onChange={() => {}}
              onValueChange={(values) => {
                const { floatValue } = values;
                clearTimeout(editTimeout);
                editTimeout = setTimeout(() => {
                  //@ts-expect-error
                  handleChangeCalculatedIncome(row.original._id, floatValue);
                }, 1000);
              }}
              label="Calculated Income"
              customInput={TextField}
            />
            {/* <FormikNumberField
            name="overrideDebtPayment"
            label="Override Debt PMT"
            InputProps={{
              inputProps: {
                style: { textAlign: "right" },
              },
            }}
            prefix="$"
            sx={{ width: "200px", mt: 0.5 }}
            disabled={!hasPermissions("update:credit-evaluations")}
          /> */}
          </>
        );
      },
    },
  ];

  return (
    <Paper>
      <Table
        data={data}
        columns={columns}
        status={status}
        additionalRows={[
          {
            cells: [
              { value: <b>Average:</b> },
              { value: null },
              { value: null },
              { value: null },
              {
                value: (
                  <b>
                    {priceFormatter(mean(data.map((d) => d.averageAnnual)))}
                  </b>
                ),
              },
              { value: null },
              { value: null },
              {
                value: (
                  <b>
                    {priceFormatter(mean(data.map((d) => d.averageAnnual2)))}
                  </b>
                ),
              },
              { value: null },
              { value: null },
              { value: null },
              { value: null },
            ],
          },
        ]}
        manualPagination
      />
    </Paper>
  );
};

export default IncomesPaystubsTable;
