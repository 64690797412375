import { Box, Paper } from "@mui/material";
import { QueryStatus } from "@tanstack/react-query";
import { FC } from "react";
import { useIntl } from "react-intl";
import { CellProps, Column } from "react-table";

import { CreditEvaluationCollection } from "api/creditEvaluations/types";
import Table from "components/Table";
import { dateFormatter } from "components/Table/formatters/dateFormatter";
import { priceFormatter } from "components/Table/formatters/priceFormatter";

interface ICollectionsTable {
  data: CreditEvaluationCollection[];
  status: QueryStatus;
}

const CollectionsTable: FC<ICollectionsTable> = ({ data, status }) => {
  const intl = useIntl();

  const columns: Column<CreditEvaluationCollection>[] = [
    {
      accessor: "dateVerified",
      Header: "Date Verified",
      Cell: ({ value }: CellProps<CreditEvaluationCollection>) =>
        dateFormatter(value),
    },
    {
      accessor: "status",
      Header: "Status",
      Cell: ({ value }: CellProps<CreditEvaluationCollection>) => (
        <Box textTransform="capitalize">{value}</Box>
      ),
    },
    {
      accessor: "memberNumber",
      Header: "Member Number",
    },
    {
      accessor: "narrativesCreditorAccountNumber",
      Header: "Narratives Creditor Account Number",
    },
    {
      accessor: "industryCode",
      Header: "Industry Code",
    },

    {
      accessor: "amount",
      Header: "Amount",
      Cell: ({ value }: CellProps<CreditEvaluationCollection>) =>
        priceFormatter(value),
    },
    {
      accessor: "balance",
      Header: intl.formatMessage({ id: "GLOBAL.BALANCE" }),
      Cell: ({ value }: CellProps<CreditEvaluationCollection>) =>
        priceFormatter(value),
    },
    {
      accessor: "dateClosed",
      Header: "Date Closed",
      Cell: ({ value }: CellProps<CreditEvaluationCollection>) =>
        dateFormatter(value),
    },
    {
      accessor: "dateReported",
      Header: "Report Date",
      Cell: ({ value }: CellProps<CreditEvaluationCollection>) =>
        dateFormatter(value),
    },
  ];

  return (
    <Paper>
      <Table
        data={data}
        columns={columns}
        status={status}
        initialState={{
          sortBy: [
            {
              id: "dateReported",
              desc: false,
            },
          ],
        }}
        manualPagination
      />
    </Paper>
  );
};

export default CollectionsTable;
