import { AxiosRequestConfig } from "axios";

import request, { APICall } from "../request";
import {
  CurrentUserType,
  EditUserType,
  ForgotPasswordPayloadType,
  LoginPayloadType,
  LoginType,
  RegisterPayloadType,
  UnregisteredUserType,
  UpdatePasswordPayloadType,
} from "./types";

export const getCurrentUser = (): APICall<{ data: CurrentUserType }> =>
  request({
    url: `/auth/me`,
    method: "GET",
  });

export const postLogin = (body: LoginPayloadType): APICall<LoginType> =>
  request({
    data: body,
    url: `/auth/login`,
    method: "POST",
  });

export const postRegister = (
  body: RegisterPayloadType,
  id: string,
): APICall<LoginType> =>
  request({
    data: body,
    url: `/auth/register/${id}`,
    method: "POST",
  });

export const postForgotPassword = (
  body: ForgotPasswordPayloadType,
): APICall<null> =>
  request({
    data: body,
    url: `/auth/forgot-password`,
    method: "POST",
  });

export const getUnregisteredData = (
  id: string,
): APICall<{ data: UnregisteredUserType }> =>
  request({
    url: `/user/unconfirmed/${id}`,
    method: "GET",
  });

export const updateProfile = (body: EditUserType): APICall<any> =>
  request({
    data: body,
    url: `/auth/me`,
    method: "PUT",
  });

export const updateProfilePassword = (
  body: UpdatePasswordPayloadType,
  headers?: AxiosRequestConfig["headers"],
): APICall<any> =>
  request({
    data: body,
    url: `/auth/me/password`,
    method: "PUT",
    headers,
  });
