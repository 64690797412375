import { Skeleton, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { FC, ReactNode } from "react";

import { random } from "utils/random";

interface ICustomerField {
  label: ReactNode | string;
  value: ReactNode | string | number;
  isLoading?: boolean;
}

const CustomerField: FC<ICustomerField> = ({ label, value, isLoading }) => {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Stack
      sx={{
        py: 1.5,
        px: 2,
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
        cursor: typeof value === "string" ? "pointer" : "default",
      }}
      spacing={2}
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      onClick={async () => {
        if (Boolean(value) && typeof value === "string") {
          await navigator.clipboard.writeText(value);

          enqueueSnackbar("Copied to clipboard", {
            variant: "info",
          });
        }
      }}
    >
      <Typography variant="body2" color="#000" fontWeight="bold">
        {label}
      </Typography>

      {isLoading ? (
        <Skeleton sx={{ ml: "auto" }} height={20} width={random(120, 220)} />
      ) : (
        <Typography variant="body2" textAlign="right" sx={{ maxWidth: "80%" }}>
          {value ?? "-"}
        </Typography>
      )}
    </Stack>
  );
};

export default CustomerField;
