export const userInitials = (userName: string = "/") => {
  if (!Boolean(userName)) return "/";

  return (
    userName
      ?.split(" ")
      .map((chunk: string) => chunk.charAt(0))
      .join("")
      .toUpperCase() || ""
  );
};
