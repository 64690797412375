import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { FC } from "react";

import { CreditEvaluationLoanAffordability } from "api/creditEvaluations/types";
import {
  percentageFormatter,
  precisePercentageFormatter,
} from "components/Table/formatters/percentageFormatter";
import { priceFormatter } from "components/Table/formatters/priceFormatter";

import { loanAffordabilityFormatter } from "./formatters/loanAffordabilityFormatter";

// import { incomeOverviewTypeFormatter } from "../Income/IncomesTable/formatters/incomeOverviewTypeFormatter";

interface ILoanAffordabilitiesItem {
  data: CreditEvaluationLoanAffordability;
  defaultExpanded: boolean;
}

const LoanAffordabilitiesItem: FC<ILoanAffordabilitiesItem> = ({
  data,
  defaultExpanded,
}) => {
  return (
    <Accordion
      sx={{ background: "transparent", border: "none", mb: 1 }}
      defaultExpanded={defaultExpanded}
    >
      <AccordionSummary
        sx={{
          background: "#e6eaf4",
          borderBottom: "1px solid rgba(224, 224, 224, 1)",
          p: 0,
          px: 2,
          py: 1.5,
          minHeight: "0!important",
          ".Mui-expanded": {
            m: "0!important",
          },
          ".MuiAccordionSummary-content": {
            m: 0,
          },
        }}
        expandIcon={<ExpandMoreIcon />}
      >
        <Grid container justifyContent="flex-start">
          <Grid item xs={12}>
            <Paper sx={{ border: "none", background: "transparent" }}>
              <Box>
                <Typography fontWeight="bold">
                  {loanAffordabilityFormatter(data?.source)}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails sx={{ px: 0, py: 0 }}>
        <Grid container mb={2} justifyContent="flex-start">
          <Grid item xs={12}>
            <Paper>
              <Stack
                sx={{
                  py: 0.5,
                  px: 2,
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body2">Term</Typography>
                <Typography component="span" variant="body2" color="green">
                  {precisePercentageFormatter(data.rate)}
                </Typography>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper>
              <Stack
                sx={{
                  py: 1.5,
                  px: 2,
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body2">60</Typography>
                <Typography variant="body2">
                  {priceFormatter(data.term60)}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  py: 1.5,
                  px: 2,
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body2">72</Typography>
                <Typography variant="body2">
                  {priceFormatter(data.term72)}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  py: 1.5,
                  px: 2,
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body2">84</Typography>
                <Typography variant="body2">
                  {priceFormatter(data.term84)}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  py: 1.5,
                  px: 2,
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body2">120</Typography>
                <Typography variant="body2">
                  {priceFormatter(data.term120)}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  py: 1.5,
                  px: 2,
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body2">144</Typography>
                <Typography variant="body2">
                  {priceFormatter(data.term144)}
                </Typography>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper>
              <Stack
                sx={{
                  py: 1.5,
                  px: 2,
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body2">DTI</Typography>
                <Typography variant="body2">
                  {percentageFormatter(data.dti)}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  py: 1.5,
                  px: 2,
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body2">
                  {priceFormatter(data.monthlyTotal)}
                </Typography>
                <Typography variant="body2">
                  {priceFormatter(data.annualTotal)}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  py: 1.5,
                  px: 2,
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body2">
                  {priceFormatter(data.monthlyTotalWithDebt)}
                </Typography>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default LoanAffordabilitiesItem;
