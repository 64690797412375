import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Formik, Form as FormikForm } from "formik";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import * as yup from "yup";

import { updateProfilePassword } from "api/user";
import {
  // ForgotPasswordPayloadType,
  UpdatePasswordPayloadType,
} from "api/user/types";
import FormikTextField from "components/forms/FormikTextField";

interface IResetPasswordForm {
  token: string;
}

const ResetPasswordForm: FC<IResetPasswordForm> = ({ token }) => {
  const history = useHistory();

  const initialValues = {
    password: "",
    repeatPassword: "",
  };

  const schema = yup.object({
    password: yup.string().required(),
    repeatPassword: yup
      .string()
      .oneOf([yup.ref("password")], "Passwords must match")
      .required(),
  });

  const { mutateAsync: resetPassword, isLoading } = useMutation(
    async (values: UpdatePasswordPayloadType) => {
      const res = await updateProfilePassword(values, {
        Authorization: `Bearer ${token}`,
      });
      return res.data;
    },
  );

  const handleSubmit = async (values: UpdatePasswordPayloadType) => {
    await resetPassword(values, {
      onSuccess: () => history.push("/login"),
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      <FormikForm>
        <FormControl fullWidth sx={{ marinBottom: 2 }}>
          <Typography mb={0.3}>Password</Typography>
          <FormikTextField name="password" type="password" />
        </FormControl>

        <FormControl fullWidth sx={{ marinBottom: 2 }}>
          <Typography mb={0.3}>Repeat Password</Typography>
          <FormikTextField name="repeatPassword" type="password" />
        </FormControl>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mt={2}
          mb={3.5}
        >
          <FormGroup>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Remember Me"
              sx={{ fontSize: 14 }}
            />
          </FormGroup>
        </Stack>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={isLoading}
          fullWidth
        >
          Reset Password
        </LoadingButton>
      </FormikForm>
    </Formik>
  );
};

export default ResetPasswordForm;
