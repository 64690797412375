const customersKeys = {
  all: ["customers"] as const,
  list: () => [...customersKeys.all, "list"],
  listFiltered: (filters: Record<string, unknown>) => [
    ...customersKeys.list(),
    filters,
  ],
  details: (id: string) => [...customersKeys.all, "detail", id] as const,
  hubspotDetails: (id: string) =>
    [...customersKeys.all, "hubspotDetails", id] as const,
};

export { customersKeys };
