import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent } from "@mui/material";
import { MutationStatus } from "@tanstack/react-query";
import { Formik, Form as FormikForm } from "formik";
import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as yup from "yup";

// COMPONENTS:

import { UserTypeRoleType } from "api/user/types";
import FormikAutocomplete from "components/forms/FormikAutocomplete";
import FormikTextField from "components/forms/FormikTextField";

export type UserFormValues = {
  organisation: string;
  email: string;
  role: UserTypeRoleType | null;
};

interface IUserForm {
  handleSubmit: (values: UserFormValues) => void;
  handleClose: () => void;
  initialValues?: UserFormValues;
  submitStatus?: MutationStatus;
}

const UserForm: FC<IUserForm> = ({
  handleSubmit,
  handleClose,
  initialValues = {
    organisation: "",
    email: "",
    role: null,
  },
  submitStatus,
}) => {
  const { formatMessage } = useIntl();
  const schema = yup.object({
    email: yup.string().email("Wrong email format").required(),
    role: yup.string().nullable().required(),
  });

  let roleOptions = [
    {
      value: "admin",
      label: formatMessage({ id: "ROLE.ADMIN" }),
    },
  ];

  if (true) {
    roleOptions = [
      {
        value: "partner-admin",
        label: formatMessage({ id: "ROLE.PARTNER_ADMIN" }),
      },
      {
        value: "partner-sales-rep",
        label: formatMessage({ id: "ROLE.PARTNER_SALES_REP" }),
      },
      {
        value: "partner",
        label: formatMessage({ id: "ROLE.PARTNER" }),
      },
    ];
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      <FormikForm>
        <DialogContent>
          <FormikAutocomplete
            label={<FormattedMessage id="GLOBAL.ROLE" />}
            name="role"
            options={roleOptions}
          />
          <FormikTextField
            name="email"
            label={<FormattedMessage id="GLOBAL.EMAIL" />}
          />
        </DialogContent>

        <DialogActions>
          <Button
            // data-testid="new-edit-port-close-button"
            onClick={handleClose}
          >
            <FormattedMessage id="GLOBAL.CLOSE" />
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={submitStatus === "loading"}
          >
            <FormattedMessage id="GLOBAL.SAVE" />
          </LoadingButton>
        </DialogActions>
      </FormikForm>
    </Formik>
  );
};

export default UserForm;
