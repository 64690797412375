import { Paginated, QueryParamsType, normalizeQueryParams } from "api/utils";

import request, { APICall } from "../request";
import { EditUserType, NewUserPayloadType, UsersType } from "./types";

export const getUsers = (
  queryParams?: QueryParamsType<UsersType>,
): APICall<Paginated<UsersType[]>> =>
  request({
    url: `/user${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });

export const getSingleUser = (userId: string): APICall<{ data: UsersType }> =>
  request({
    url: `/user/${userId}`,
    method: "GET",
  });

export const inviteUser = (user: NewUserPayloadType): APICall<UsersType> =>
  request({
    data: user,
    url: "/user",
    method: "POST",
  });

export const editUser = (user: EditUserType): APICall<EditUserType> =>
  request({
    data: user,
    url: `/user/${user._id}`,
    method: "PUT",
  });

export const deleteUser = (userId: string): APICall<null> =>
  request({
    url: `/user/${userId}`,
    method: "DELETE",
  });
