import {
  KeyboardDoubleArrowLeft as KeyboardDoubleArrowLeftIcon,
  KeyboardDoubleArrowRight as KeyboardDoubleArrowRightIcon,
} from "@mui/icons-material";
import {
  Box,
  Divider,
  Drawer,
  Fade,
  IconButton,
  Stack,
  Toolbar,
} from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import logoShrinked from "assets/icons/logo-shrinked.png";
import logo from "assets/icons/logo.svg";

import SidebarMenu from "./SidebarMenu";

interface DesktopSidebarDrawerProps {
  wideDrawerWidth: number;
  menuDrawerWidth: number;
  isDesktopDrawerShrinked: boolean;
  toggleDesktopDrawer: () => void;
}

const DesktopSidebarDrawer = ({
  wideDrawerWidth,
  menuDrawerWidth,
  isDesktopDrawerShrinked,
  toggleDesktopDrawer,
}: DesktopSidebarDrawerProps) => {
  const [isMouseOver, setIsMouseOver] = useState(false);

  return (
    <Drawer
      variant="permanent"
      sx={{
        "& .MuiDrawer-paper": {
          width: !isDesktopDrawerShrinked ? wideDrawerWidth : menuDrawerWidth,
          transition: "width 0.1s ease-out",
          overflow: "hidden",
          border: "none",
          boxShadow: "4px 0px 16px rgba(16, 30, 115, 0.08)",
        },
      }}
    >
      <Box
        sx={{ height: "100%" }}
        onMouseEnter={() => setIsMouseOver(true)}
        onMouseLeave={() => setIsMouseOver(false)}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: !isDesktopDrawerShrinked
              ? "space-between"
              : "center",
            alignItems: "center",
            borderBottom: "none",
            height: "68px",
          }}
          disableGutters
        >
          {!isDesktopDrawerShrinked && (
            <Stack direction="row" alignItems="center" sx={{ pl: 2 }}>
              <Link to="/credit-evaluations">
                <img
                  alt="logo"
                  style={{
                    height: 40,
                    objectFit: "contain",
                    objectPosition: "0 50%",
                  }}
                  src={logo}
                />
              </Link>
            </Stack>
          )}

          {isDesktopDrawerShrinked ? (
            <Fade
              in={isMouseOver}
              timeout={{
                appear: 0,
                enter: 250,
                exit: 0,
              }}
            >
              <Box
                sx={{
                  px: isMouseOver ? 1 : 0,
                  width: isMouseOver ? "auto" : "0px",
                }}
              >
                <IconButton color="primary" onClick={toggleDesktopDrawer}>
                  <KeyboardDoubleArrowRightIcon />
                </IconButton>
              </Box>
            </Fade>
          ) : (
            <Box sx={{ px: 1 }}>
              <IconButton color="primary" onClick={toggleDesktopDrawer}>
                <KeyboardDoubleArrowLeftIcon />
              </IconButton>
            </Box>
          )}
          {isDesktopDrawerShrinked && !isMouseOver && (
            <Link to="/credit-evaluations">
              <img
                alt="logo"
                style={{
                  height: 40,
                  objectFit: "contain",
                  objectPosition: "0 50%",
                }}
                src={logoShrinked}
              />
            </Link>
          )}
        </Toolbar>
        <Divider />
        <SidebarMenu />
      </Box>
    </Drawer>
  );
};

export default DesktopSidebarDrawer;
