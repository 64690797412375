import { Shadows, ThemeOptions, createTheme } from "@mui/material/styles";

const themeOptions: ThemeOptions = {
  shadows: Array(25).fill("none") as Shadows,
  palette: {
    primary: {
      main: "#1B75BC",
      // light: "#0382ff",
      // dark: "#336CFB",
      contrastText: "#f5f5f5",
    },
    secondary: {
      main: "#39B54A",
      // dark: "#159631",
      // light: "#159631",
      contrastText: "#f5f5f5",
    },
    background: {
      default: "#F6F8FB",
      paper: "#ffffff",
    },
    text: {
      primary: "#25282B",
      secondary: "#52575C",
      disabled: "#A0A4A8",
    },
    warning: {
      main: "#F1D15E",
    },
    error: {
      main: "#FF6760",
    },
  },
  typography: {
    // overline: {
    //   fontFamily:
    //     '"Roboto Condensed", "Roboto", "Helvetica", "Arial", sans-serif',
    // },
    fontFamily: '"Lato", "Raleway", "Roboto", "Helvetica", "Arial", sans-serif',
    // body2: {
    //   fontFamily:
    //     '"Roboto Condensed", "Roboto", "Helvetica", "Arial", sans-serif',
    // },
    h1: {
      fontSize: "2rem",
      fontWeight: 600,
    },
    h2: {
      fontSize: "1.875rem",
      fontWeight: 400,
    },
    h3: {
      fontSize: "1.625rem",
    },
    h4: {
      fontSize: "1.375rem",
      fontWeight: 600,
    },
    h5: {
      fontSize: "1.125rem",
      fontWeight: 600,
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 600,
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          overflow: "hidden",
          borderColor: "#EEE",
          borderWidth: "1px",
          borderStyle: "solid",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          opacity: 0.4,
          fontSize: "12px",
          fontWeight: "500",
          color: "#000",
          marginTop: "11px",
          marginBottom: "11px",
          [`&::before, &::after`]: {
            borderTop: "2px solid rgba(0,0,0,0.3)",
          },
          [`&.MuiDivider-withChildren`]: {
            marginTop: 0,
            marginBottom: 0,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          display: "flex",
          alignItems: "center",
          borderRadius: 8,
        },
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        size: "small",
      },
    },
    MuiCheckbox: {
      defaultProps: {
        size: "small",
      },
      styleOverrides: {
        root: {
          marginLeft: 5,
        },
      },
    },
    MuiFab: {
      defaultProps: {
        size: "small",
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: "dense",
        size: "small",
      },
    },
    MuiFormHelperText: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: "small",
      },
    },

    MuiInputBase: {
      defaultProps: {
        margin: "dense",
      },
      styleOverrides: {
        root: {
          "&.MuiInputBase-root": {
            backgroundColor: "#fff",
            borderRadius: 8,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: 8,
            borderColor: "#C2CBE3",
          },
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        margin: "dense",
      },
    },
    MuiRadio: {
      defaultProps: {
        size: "small",
      },
    },
    MuiSwitch: {
      defaultProps: {
        size: "small",
      },
    },
    MuiTextField: {
      defaultProps: {
        margin: "dense",
        size: "small",
      },
    },
    MuiDialog: {
      defaultProps: {
        fullWidth: true,
        maxWidth: "sm",
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
    },
    MuiList: {
      defaultProps: {
        dense: false,
      },
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true,
      },
    },
    MuiTable: {
      defaultProps: {
        size: "small",
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        // TODO: most likely this gets overriden by some styles from MUIv4
        // try removing when MUIv4 is gone
        endAdornment: {
          top: "inherit",
        },
        // TODO: remove when this gets merged https://github.com/mui/material-ui/issues/28465
        root: {
          ".MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
            paddingRight: "39px",
            // or padding: theme.spacing(X) if you want to be more precise & already defined your theme
          },
        },
        paper: {
          marginTop: "2px",
          border: "1px solid #C5C3C5",
        },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
};

const theme = createTheme(themeOptions);

export default theme;
