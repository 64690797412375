import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Formik, Form as FormikForm } from "formik";
import * as yup from "yup";

import { postForgotPassword } from "api/user";
import { ForgotPasswordPayloadType } from "api/user/types";
import FormikTextField from "components/forms/FormikTextField";

const ForgotPasswordForm = () => {
  const initialValues = {
    email: "",
  };

  const schema = yup.object({
    email: yup.string().email("Wrong email format").required(),
  });

  const { mutateAsync: forgotPassword, isLoading } = useMutation(
    async (values: ForgotPasswordPayloadType) => {
      const res = await postForgotPassword(values);
      return res.data;
    },
  );

  const handleSubmit = async (values: ForgotPasswordPayloadType) => {
    await forgotPassword(values);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      <FormikForm>
        <FormControl fullWidth sx={{ marinBottom: 2 }}>
          <Typography mb={0.3}>Email</Typography>
          <FormikTextField name="email" />
        </FormControl>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mt={2}
          mb={3.5}
        >
          <FormGroup>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Remember Me"
              sx={{ fontSize: 14 }}
            />
          </FormGroup>
        </Stack>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={isLoading}
          fullWidth
        >
          Reset Password
        </LoadingButton>
      </FormikForm>
    </Formik>
  );
};

export default ForgotPasswordForm;
