import { Box, Divider, Grid, Link, Paper, Stack } from "@mui/material";
import React from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

import logo from "assets/icons/logo.svg";
import loginImage from "assets/images/login-image.png";

import ForgotPasswordForm from "./ForgotPasswordForm";
import ResetPasswordForm from "./ResetPasswordForm";

const ForgotPasswordPage = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  const token = query.get("token") ?? undefined;

  return (
    <Grid container py={8} px={6} height="100vh" sx={{ overflowY: "scroll" }}>
      <Grid
        item
        sm={6}
        width="50%"
        height="100%"
        sx={{ display: { md: "block", xs: "none" } }}
      >
        <img
          src={loginImage}
          alt="Login"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </Grid>
      <Grid item xl={2} lg={1}></Grid>
      <Grid item xl={4} lg={5} md={6} xs={12}>
        <Stack direction="row" alignItems="center" height="100%">
          <Paper
            sx={{
              p: { lg: 7, xs: 5 },
              py: 5,
              width: "100%",
              marginLeft: { lg: 0, sm: "50px" },
            }}
          >
            <Box sx={{ textAlign: "center", mb: 3 }}>
              <img
                alt="logo"
                style={{
                  height: 50,
                  objectFit: "contain",
                  objectPosition: "0 50%",
                }}
                src={logo}
              />
            </Box>

            {token ? (
              <>
                <Divider
                  sx={{ backgroundColor: "#fff", fontSize: "0.9rem" }}
                  color="rgba(130, 149, 198, 0.49)"
                >
                  Reset Password
                </Divider>

                <ResetPasswordForm token={token} />
              </>
            ) : (
              <>
                <Divider
                  sx={{ backgroundColor: "#fff", fontSize: "0.9rem" }}
                  color="rgba(130, 149, 198, 0.49)"
                >
                  Forgot Password
                </Divider>

                <ForgotPasswordForm />

                <Box sx={{ mt: 2 }}>
                  <Link component={RouterLink} to="/login">
                    Back to Login
                  </Link>
                </Box>
              </>
            )}
          </Paper>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ForgotPasswordPage;
