import { Add as AddIcon } from "@mui/icons-material";
import { Dialog, DialogTitle, Divider, Stack } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FC } from "react";

import { saveAndRefetchCustomer } from "api/customers";
import { customersKeys } from "api/customers/queries";

import AddSocialNumberForm, {
  AddSocialNumberFormValues,
} from "./AddSocialNumberForm";

interface AddSocialNumberModalProps {
  isOpen: boolean;
  handleClose: () => void;
  customerId: string;
}

const AddSocialNumberModal: FC<AddSocialNumberModalProps> = ({
  isOpen,
  handleClose,
  customerId,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const queryCache = useQueryClient();

  const { mutateAsync: addSocialNumber, status } = useMutation(
    async (data: any) => {
      const res = await saveAndRefetchCustomer(customerId, data);
      await queryCache.invalidateQueries(customersKeys.details(customerId));
      return res.data;
    },
  );
  const handleSubmit = async (values: AddSocialNumberFormValues) => {
    const mappedValues: any = {
      ...values,
    };

    await addSocialNumber(mappedValues, {
      onSuccess: () => handleClose(),
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm">
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <AddIcon sx={{ mr: 1 }} />
          Add Social Number
        </Stack>
      </DialogTitle>
      <Divider />

      <AddSocialNumberForm
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        submitStatus={status}
      />
    </Dialog>
  );
};

export default AddSocialNumberModal;
