import {
  CreditEvaluationLoan,
  CreditEvaluationTradeline,
} from "api/creditEvaluations/types";

export const calcaulatePaydown = (
  precentage: number,
  trade: CreditEvaluationLoan | CreditEvaluationTradeline,
) => {
  precentage = precentage / 100;

  if (trade.balance && trade.hpb) {
    const paydown = trade.balance - trade.hpb * precentage;
    if (paydown < 0) return 0;

    return paydown;
  }
};
