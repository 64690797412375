import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault("Iceland");

declare module "dayjs" {
  interface Dayjs {
    lastWorkingDay(): dayjs.Dayjs;
  }
}

dayjs.prototype.lastWorkingDay = () => {
  const currentDay = dayjs();

  if (currentDay.day() === 6) {
    return currentDay.subtract(1, "day");
  } else if (currentDay.day() === 0) {
    return currentDay.subtract(2, "day");
  }
  return currentDay;
};

export default dayjs;
