import { Link, Paper, Stack, Typography } from "@mui/material";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import { Link as RouteLink } from "react-router-dom";
import { Cell, Column } from "react-table";

import { getCreditEvaluations } from "api/creditEvaluations";
import { creditEvaluationsKeys } from "api/creditEvaluations/queries";
import { CreditEvaluationsType } from "api/creditEvaluations/types";
import { QueryParamsType } from "api/utils";
import Table from "components/Table";
import { dateFormatter } from "components/Table/formatters/dateFormatter";
import useRemoteTableLogic from "components/Table/useRemoteTableLogic";
import { useCreditEvaluationsFilter } from "containers/creditEvaluations/CreditEvaluationsTable/useCreditEvaluationsFilter";
import { generateCreditScoreColor } from "utils/generateCreditScoreColor/generateCreditScoreColor";

interface CustomerCreditEvaluationsTableProps {
  customerId: string;
}

const CustomerCreditEvaluationsTable: FC<
  CustomerCreditEvaluationsTableProps
> = ({ customerId }) => {
  const intl = useIntl();
  const customerFilter: QueryParamsType<CreditEvaluationsType> = {
    filter: [
      {
        field: "customer",
        operator: "is",
        value: customerId,
      },
    ],
  };
  const { filters } = useCreditEvaluationsFilter(customerFilter);

  const { data, status, sortOptions, paginationOptions } = useRemoteTableLogic(
    creditEvaluationsKeys.list(),
    getCreditEvaluations,
    filters,
  );

  const columns: Column<CreditEvaluationsType>[] = [
    {
      id: "customer",
      Header: intl.formatMessage({
        id: "CUSTOMERS.DETAILS.CREDIT_EVALUATIONS_TABLE.CUSTOMER",
      }),
      Cell: ({ row }: Cell<CreditEvaluationsType>) =>
        `${row.original.customer?.firstName} ${row.original.customer?.lastName}`,
    },
    {
      id: "creditScore",
      Header: "Credit Score",
      Cell: ({ row }: Cell<CreditEvaluationsType>) => (
        <>
          {row.original.creditScores.map((creditScore) => (
            <Typography>
              {creditScore.type}:{" "}
              <span
                style={{
                  fontWeight: "bold",
                  color: generateCreditScoreColor(creditScore.score),
                }}
              >
                {creditScore.score}
              </span>
            </Typography>
          ))}
        </>
      ),
    },
    {
      id: "reprots",
      Header: intl.formatMessage({
        id: "CUSTOMERS.DETAILS.CREDIT_EVALUATIONS_TABLE.REPORTS",
      }),
      Cell: ({ row }: Cell<CreditEvaluationsType>) => (
        <Stack direction="column" spacing={0.5}>
          <Link
            component={RouteLink}
            to={`/credit-evaluations/${row.original._id}`}
          >
            <FormattedMessage id="CREDIT_EVALUATIONS.TABLE.CREDIT_EVALUATION" />
          </Link>
          <Link
            component={RouteLink}
            to={row.original.html.replace("html", "pdf")}
          >
            <FormattedMessage id="CREDIT_EVALUATIONS.TABLE.PDF_REPORT" />
          </Link>
        </Stack>
      ),
    },
    {
      accessor: "reportDate",
      Header: "Report Date",
      align: "right",
      Cell: ({ value }: Cell<CreditEvaluationsType>) => dateFormatter(value),
    },
  ];

  return (
    <>
      <Paper>
        <Table
          data={data}
          columns={columns}
          sort={sortOptions}
          pagination={paginationOptions}
          status={status}
        />
      </Paper>
    </>
  );
};

export default CustomerCreditEvaluationsTable;
