const usersKeys = {
  all: ["users"] as const,
  list: () => [...usersKeys.all, "list"],
  listFiltered: (filters: Record<string, unknown>) => [
    ...usersKeys.list(),
    filters,
  ],
  details: (id: string) => [...usersKeys.all, "detail", id] as const,
};

export { usersKeys };
