import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent } from "@mui/material";
import { MutationStatus } from "@tanstack/react-query";
import { Form as FormikForm, FormikProvider, useFormik } from "formik";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";

// COMPONENTS:
import FormikTextField from "components/forms/FormikTextField";

export type HubspotDealIdFormValues = {
  hubspotDealId: string;
};

interface IHubspotDealIdForm {
  handleSubmit: (values: HubspotDealIdFormValues) => void;
  handleClose: () => void;
  initialValues?: HubspotDealIdFormValues;
  submitStatus?: MutationStatus;
}

const HubspotDealIdForm: FC<IHubspotDealIdForm> = ({
  handleSubmit,
  handleClose,
  initialValues = {
    hubspotDealId: "",
  },
  submitStatus,
}) => {
  const schema = yup.object({
    hubspotDealId: yup.string().nullable().required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <FormikForm>
        <DialogContent>
          <FormikTextField label={"Hubspot Deal Id"} name="hubspotDealId" />
        </DialogContent>

        <DialogActions>
          <Button
            // data-testid="new-edit-port-close-button"
            onClick={handleClose}
          >
            <FormattedMessage id="GLOBAL.CLOSE" />
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={submitStatus === "loading"}
          >
            <FormattedMessage id="GLOBAL.SAVE" />
          </LoadingButton>
        </DialogActions>
      </FormikForm>
    </FormikProvider>
  );
};

export default HubspotDealIdForm;
