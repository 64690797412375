import { Avatar } from "@mui/material";

import { userInitials } from "utils/userInitials/userInitials";

const UserAvatar = ({
  userName = "N N",
  fontSize,
  size = 60,
}: {
  userName: string;
  fontSize?: number;
  size?: number;
}) => {
  const generatedUserInitials = userInitials(userName);

  return (
    <Avatar
      sx={{
        width: size,
        height: size,
        fontSize: fontSize || Math.min(45, 55 / generatedUserInitials.length),
        bgcolor: "primary.main",
        border: "1px solid #E8E8E8",
      }}
    >
      {generatedUserInitials}
    </Avatar>
  );
};

export default UserAvatar;
