import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent } from "@mui/material";
import { MutationStatus } from "@tanstack/react-query";
import { Form as FormikForm, FormikProvider, useFormik } from "formik";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";

import FormikDatePicker from "components/forms/FormikDatePicker";
import FormikTextField from "components/forms/FormikTextField";
import MaskSocialInput from "components/forms/FormikTextField/MaskSocialInput";

export type UpdateGeneralInfoFormValues = {
  firstName: string;
  lastName: string;
  middleName?: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  phone?: string;
  social?: string;
  email: string;
  birthday: Date | null;
};

interface IUpdateGeneralInfoForm {
  handleSubmit: (values: UpdateGeneralInfoFormValues) => void;
  handleClose: () => void;
  initialValues?: UpdateGeneralInfoFormValues;
  submitStatus?: MutationStatus;
}

const UpdateGeneralInfoForm: FC<IUpdateGeneralInfoForm> = ({
  handleClose,
  handleSubmit,
  initialValues = {
    firstName: "",
    lastName: "",
    middleName: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    social: "",
    email: "",
    birthday: null,
  },
  submitStatus,
}) => {
  const schema = yup.object({
    firstName: yup.string().required().nullable(),
    lastName: yup.string().required().nullable(),
    address: yup.string().required().nullable(),
    city: yup.string().required().nullable(),
    state: yup.string().required().nullable(),
    zip: yup.string().required().nullable(),
    email: yup.string().required().nullable(),
  });

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <FormikForm>
        <DialogContent>
          <FormikTextField name="firstName" label="First name" />
          <FormikTextField name="middleName" label="Middle name" />
          <FormikTextField name="lastName" label="Last name" />
          <FormikTextField name="address" label="Address" />
          <FormikTextField name="city" label="City" />
          <FormikTextField name="state" label="State" />
          <FormikTextField name="zip" label="ZIP" />
          <FormikTextField name="phone" label="Phone" />
          <FormikTextField name="email" label="Email" />
          <FormikTextField
            name="social"
            label="Social"
            InputProps={{
              inputComponent: MaskSocialInput as any,
            }}
          />
          <FormikDatePicker name="birthday" label="Birthday" />
        </DialogContent>
        <DialogActions>
          <Button
            // data-testid="new-edit-port-close-button"
            onClick={handleClose}
          >
            <FormattedMessage id="GLOBAL.CLOSE" />
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={submitStatus === "loading"}
          >
            Save & Refetch
          </LoadingButton>
        </DialogActions>
      </FormikForm>
    </FormikProvider>
  );
};

export default UpdateGeneralInfoForm;
