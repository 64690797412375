import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
} from "@mui/material";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { MutationStatus, useQuery } from "@tanstack/react-query";
import { Form as FormikForm, FormikProvider, useFormik } from "formik";
import React, { FC, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import * as yup from "yup";

// COMPONENTS:

import { getCustomers, getHubspotCustomer } from "api/customers";
// import FormikAutocomplete from "components/forms/FormikAutocomplete";
import { customersKeys } from "api/customers/queries";
// import { CustomersType } from "api/customers/types";
import FormikAutocomplete from "components/forms/FormikAutocomplete";
import FormikAutocompleteMultiple from "components/forms/FormikAutocompleteMultiple";
import FormikCheckbox from "components/forms/FormikCheckbox";
import FormikDatePicker from "components/forms/FormikDatePicker";
import FormikNumberField from "components/forms/FormikNumberField";
import FormikTextField from "components/forms/FormikTextField";
import MaskSocialInput from "components/forms/FormikTextField/MaskSocialInput";
import { generateCustomerValues } from "utils/generateCustomerValues/generateCustomerValues";

import CustomerFormStep1, {
  CustomerFormStep1Values,
} from "./CustomerFormStep1";

export type CustomerFormValues = any;
interface ICustomerForm {
  handleSubmit: (values: CustomerFormValues) => void;
  handleClose: () => void;
  initialValues?: CustomerFormValues;
  submitStatus?: MutationStatus;
  edit?: boolean;
  customerId?: string;
}

const CustomerForm: FC<ICustomerForm> = ({
  handleSubmit,
  handleClose,
  edit,
  customerId,
  initialValues = {
    spouse: "",
    // GENERAL INFORMATION
    firstName: "",
    middleName: "",
    lastName: "",
    salutation: "",
    birthday: null,
    address: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    mobilePhone: "",
    referralSource: "",
    email: "",

    // Additional
    associatedBrand: "",
    leadSource: "",
    franchiseChoice: "",
    submissionEmail: "",
    submissionPassword: "",

    // PERSONAL INFORMATION
    personalInfo: {
      driversLicenseId: null,
      driversLicenseIssueDate: null,
      driversLicenseExpireDate: null,
      creditUnion: null,
      personalBank: null,
      militaryStatus: null,
      militaryAffiliation: null,
      bankRoutingNumber: null,
      bankAccountNumber: null,
      creditRepairBefore: false,
      judgementsLiensBankruptcy: null,
      previoiusFinanceCompany: false,
      maritalStatus: null,
    },
    // HOUSING INFO
    housingInfo: {
      houstingStatus: null,
      monthlyHousingPayment: null,
      estimatedLengthOfTimeAtResidence: null,
      moveInDate: null,
      calculatedLengthOfTimeAtResidence: null,
      yearsAtCurrentAddress: null,
    },
    // EMPLOYMENT INFO
    employmentInfo: {
      incomeType: null,
      employerName: null,
      employerPhone: null,
      employerAddress: null,
      estimatedTimeAtJob: null,
      startDateWithEmployer: null,
      calculatedLengthOfEmployment: null,
      occupationPosition: null,
      monthlyGrossIncome: null,
      annualPersonalIncome: null,
      frontEndRtiRatio: null,
      totalAnnualHouseholdIncome: null,
      householdFrontEndDtiRatio: null,
      statedMonthlyIncome: null,
      statedAnnualIncome: null,
      statedAnnualHouseholdIncome: null,
    },
    // SECURITY QUESTIONS
    securityQuestions: {
      birthCity: null,
      bronInForeignCountry: false,
      legalPermanentResident: false,
      greenCardExpirationDate: null,
      mothersMaidenName: null,
      highSchoolMascot: null,
      highSchoolCity: null,
      nameOfStreetYouGrewUp: null,
      favoritePetsName: null,
    },
    // EDUCATION
    educationInfo: {
      collegeAttended: null,
      fieldOfStudy: null,
      degree: null,
      graduatedDate: null,
      graduateSchoolAttended: null,
      graduateSchoolFieldOfStudy: null,
      graduateDegreeReceived: null,
      graduateGraduationDate: null,
    },
    // ASSET INFORMATION
    assetInfo: {
      combinedCheckingSavingsBalance: null,
      stocksBondsMutualFunds: null,
      retirementAccountBalance: null,
    },
    // PRIMARY RESIDENCE VALUATION
    primaryResidenceValuation: {
      avmValue: null,
      marketValue: null,
      zillowValue: null,
      estimatedPropertyValue: null,
      estimatedEquityPrimaryResidence: null,
      calculatedValue: null,
      calculatedEquity: null,
    },
  },
  submitStatus,
}) => {
  const intl = useIntl();

  const schema = yup.object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    address: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
    zip: yup.string().required(),
    social: yup.string(),
    email: yup.string().email("Wrong email format").required(),
    birthday: yup.string().required(),
    associatedBrand: yup.string().required(),
  });

  const [activeStep, setActiveStep] = useState(edit ? 1 : 0);
  const [form2Values, setForm2Values] = useState(initialValues);

  const { data: spouses, isLoading: areSpousesLoading } = useQuery(
    customersKeys.list(),
    async () => {
      const { data: res } = await getCustomers({ limit: 1000 });
      return res.data;
    },
  );

  const spouseOptions = useMemo(() => {
    if (spouses?.length) {
      let filteredSpouses = spouses.filter(
        (spouse) => spouse._id !== customerId,
      );
      return filteredSpouses.map((spouse) => ({
        value: spouse._id,
        label: `${spouse.firstName} ${spouse.lastName}`,
      }));
    }

    return [];
  }, [spouses, customerId]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: form2Values,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  const steps = [
    intl.formatMessage({ id: "CUSTOMERS.ADD.STEP1" }),
    intl.formatMessage({ id: "CUSTOMERS.ADD.STEP2" }),
  ];

  const handleStep1Submit = async (values: CustomerFormStep1Values) => {
    try {
      const { data } = await getHubspotCustomer(values?.email);
      setForm2Values({
        ...generateCustomerValues(data?.data),
        email: values?.email,
      });
      setActiveStep(1);
    } catch (err) {}
  };

  const CustomerFormStepper = () => {
    return (
      <Box maxWidth={600} mb={4} mx="auto">
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
    );
  };

  return (
    <>
      {activeStep === 0 && (
        <CustomerFormStep1
          handleClose={handleClose}
          submitStatus={submitStatus}
          handleSubmit={handleStep1Submit}
          customerFormStepper={<CustomerFormStepper />}
        />
      )}
      {activeStep === 1 && (
        <FormikProvider value={formik}>
          <FormikForm>
            <DialogContent>
              {!edit && <CustomerFormStepper />}
              <>
                <Grid item xs={12} sx={{ mb: 2 }}>
                  <Divider>General Information</Divider>
                </Grid>
                <Grid container spacing={2} rowSpacing={1} pb={1}>
                  {/* 1 */}
                  <Grid item xs={12} lg={3}>
                    <FormikTextField label="Salutation" name={"salutation"} />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <FormikTextField label="First Name" name={"firstName"} />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <FormikTextField label="Middle Name" name={"middleName"} />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <FormikTextField label="Last Name" name={"lastName"} />
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <FormikTextField
                      label={<FormattedMessage id="GLOBAL.ADDRESS" />}
                      name={"address"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <FormikTextField
                      label={<FormattedMessage id="CUSTOMERS.CITY" />}
                      name={"city"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <FormikTextField
                      label={<FormattedMessage id="CUSTOMERS.STATE" />}
                      name={"state"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <FormikTextField
                      label={<FormattedMessage id="CUSTOMERS.ZIP" />}
                      name={"zip"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormikTextField label="Phone" name="phone" />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormikTextField label="Mobilephone" name={"mobilePhone"} />
                  </Grid>

                  <Grid item xs={12} lg={12}>
                    <FormikTextField
                      label={<FormattedMessage id="CUSTOMERS.SOCIAL" />}
                      name={"social"}
                      InputProps={{
                        inputComponent: MaskSocialInput as any,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormikAutocomplete
                      label="Marital Status"
                      name={"personalInfo.maritalStatus"}
                      options={[
                        { value: "Single", label: "Single" },
                        { value: "Married", label: "Married" },
                        { value: "Divorced", label: "Divorced" },
                        {
                          value: "Prefer not to answer",
                          label: "Prefer not to answer",
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormikAutocomplete
                      label={<FormattedMessage id="CUSTOMERS.SPOUSE" />}
                      name="spouse"
                      options={spouseOptions}
                      loading={areSpousesLoading}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikTextField label="Email" name={"email"} />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikTextField
                      label="Place of Birth"
                      name={"securityQuestions.birthCity"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikCheckbox
                      label="Born in foreign country"
                      name={"securityQuestions.bronInForeignCountry"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikCheckbox
                      label="Legal Permanent Resident"
                      name={"securityQuestions.legalPermanentResident"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikDatePicker
                      label="Green Card Expiration Date"
                      name={"securityQuestions.greenCardExpirationDate"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikDatePicker
                      label="Birthday"
                      name={"birthday"}
                      timezone="UTC"
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikTextField
                      label="Mothers Maiden Name"
                      name={"securityQuestions.mothersMaidenName"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikTextField
                      label="High School Mascot"
                      name={"securityQuestions.highSchoolMascot"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikTextField
                      label="High School City"
                      name={"securityQuestions.highSchoolCity"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikTextField
                      label="Name of Street you grew up on"
                      name={"securityQuestions.nameOfStreetYouGrewUp"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikTextField
                      label="Name of Favorite Pet"
                      name={"securityQuestions.favoritePetsName"}
                    />
                  </Grid>

                  {/* UNRECOGNISED */}
                  <Grid item xs={12} lg={3}>
                    <FormikTextField
                      label="Franchise Choice"
                      name="franchiseChoice"
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <FormikTextField
                      label="Associate Brand"
                      name={"associatedBrand"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <FormikTextField
                      label="Referral Source"
                      name={"referralSource"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <FormikAutocomplete
                      label="Lead Source"
                      name={"leadSource"}
                      options={[
                        {
                          label: "Agoura Group",
                          value: "Agoura Group",
                        },
                        {
                          label: "ALL CAP FUNDING",
                          value: "ALLCAP",
                        },
                        {
                          label: "All Fundy",
                          value: "All Fundy",
                        },
                        {
                          label: "All Fundy Capital",
                          value: "All Fundy Capital",
                        },
                        {
                          label: "BitX Funding LLC",
                          value: "BitX Funding LLC",
                        },
                        {
                          label: "Brent Harper",
                          value: "Brent Harper",
                        },
                        {
                          label: "Business Capital Experts",
                          value: "Business Capital Experts",
                        },
                        {
                          label: "CannabisTestingSimplified",
                          value: "CannabisTestingSimplified",
                        },
                        {
                          label: "Cannabiz.Media",
                          value: "Cannabiz.Media",
                        },
                        {
                          label: "cannabizloans.net",
                          value: "cannabizloans.net",
                        },
                        {
                          label: "CannaBusinessFinancing",
                          value: "CannaBusinessFinancing",
                        },
                        {
                          label: "CannaInvestor Magazine",
                          value: "CannaInvestor Magazine",
                        },
                        {
                          label: "Cannbiz.Media",
                          value: "Cannbiz.Media",
                        },
                        {
                          label: "CBD Plus USA",
                          value: "CBD Plus USA",
                        },
                        {
                          label: "CBF - Alternative Small Business Funding",
                          value: "CBF - Alternative Small Business Funding",
                        },
                        {
                          label: "CBF - CannabisTestingSimplified",
                          value: "CBF - CannabisTestingSimplified",
                        },
                        {
                          label: "CBF - CannaInvestor Magazine",
                          value: "CBF - CannaInvestor Magazine",
                        },
                        {
                          label: "CBF - CBD Plus USA",
                          value: "CBF - CBD Plus USA",
                        },
                        {
                          label: "CBF - Healthcare",
                          value: "CBF - Healthcare",
                        },
                        {
                          label: "CBF Solutions Group - Healthcare",
                          value: "CBF Solutions Group - Healthcare",
                        },
                        {
                          label: "CBFsg",
                          value: "CBFsg",
                        },
                        {
                          label: "CBFsg - 1West Finance",
                          value: "CBFsg - 1West Finance",
                        },
                        {
                          label: "CBFsg - American Finance Group",
                          value: "CBFsg - American Finance Group",
                        },
                        {
                          label: "CBFsg - Cardinal Business Financing",
                          value: "CBFsg - Cardinal Business Financing",
                        },
                        {
                          label: "CBFsg - Diverse Merchant Solutions",
                          value: "CBFsg - Diverse Merchant Solutions",
                        },
                        {
                          label: "CBFsg - Fund Me With Ease",
                          value: "CBFsg - Fund Me With Ease",
                        },
                        {
                          label: "CBFsg - Laster Financial",
                          value: "CBFsg - Laster Financial",
                        },
                        {
                          label: "Champs",
                          value: "Champs",
                        },
                        {
                          label: "Dewey Vaughn",
                          value: "Dewey Vaughn",
                        },
                        {
                          label: "Diamond Business Loans",
                          value: "Diamond Business Loans",
                        },
                        {
                          label: "Dynamic Alternative Finance",
                          value: "Dynamic Alternative Finance",
                        },
                        {
                          label: "Facebook",
                          value: "Facebook",
                        },
                        {
                          label: "finder.com LLC",
                          value: "finder.com LLC",
                        },
                        {
                          label: "Fix Your Credit Consulting",
                          value: "Fix Your Credit Consulting",
                        },
                        {
                          label: "Foresight Funding Advisors, LLC",
                          value: "Foresight Funding Advisors, LLC",
                        },
                        {
                          label: "Fortune Builders",
                          value: "Fortune Builders",
                        },
                        {
                          label: "Friendship Holdings, LLC",
                          value: "Friendship Holdings, LLC",
                        },
                        {
                          label: "FundMeWithMeEase",
                          value: "FundMeWithMeEase",
                        },
                        {
                          label: "GapFundingNow",
                          value: "GapFundingNow",
                        },
                        {
                          label: "GLM - Infinite Capital Funding",
                          value: "GLM - Infinite Capital Funding",
                        },
                        {
                          label: "Green Leaf Money",
                          value: "Green Leaf Money",
                        },
                        {
                          label: "HMB",
                          value: "HMB",
                        },
                        {
                          label: "HMB - 1-800-Accountant",
                          value: "HMB - 1-800-Accountant",
                        },
                        {
                          label: "HMB - 1-800-BizFund",
                          value: "HMB - 1-800-BizFund",
                        },
                        {
                          label: "HMB - Band Box Capital",
                          value: "HMB - Band Box Capital",
                        },
                        {
                          label: "HMB - CBD Authority",
                          value: "HMB - CBD Authority",
                        },
                        {
                          label: "HMB - Central Business Funder",
                          value: "HMB - Central Business Funder",
                        },
                        {
                          label: "HMB - ColdStone Creamery",
                          value: "HMB - ColdStone Creamery",
                        },
                        {
                          label: "HMB - CraftAxeThrowing",
                          value: "HMB - CraftAxeThrowing",
                        },
                        {
                          label: "HMB - Dog Training Elite",
                          value: "HMB - Dog Training Elite",
                        },
                        {
                          label: "HMB - Fast Business Financial",
                          value: "HMB - Fast Business Financial",
                        },
                        {
                          label: "HMB - FIRE Fitness Camp",
                          value: "HMB - FIRE Fitness Camp",
                        },
                        {
                          label: "HMB - Franchise Opportunities",
                          value: "HMB - Franchise Opportunities",
                        },
                        {
                          label: "HMB - Franchise Succeed",
                          value: "HMB - Franchise Succeed",
                        },
                        {
                          label: "HMB - friospops",
                          value: "HMB - friospops",
                        },
                        {
                          label: "HMB - Future-Funders",
                          value: "HMB - Future-Funders",
                        },
                        {
                          label: "HMB - Goliath Tech Franchise",
                          value: "HMB - Goliath Tech Franchise",
                        },
                        {
                          label: "HMB - grabbagreen",
                          value: "HMB - grabbagreen",
                        },
                        {
                          label: "HMB - Hawaii Fluid Art",
                          value: "HMB - Hawaii Fluid Art",
                        },
                        {
                          label: "HMB - HydroDog",
                          value: "HMB - HydroDog",
                        },
                        {
                          label: "HMB - Just Love Coffee Cafe",
                          value: "HMB - Just Love Coffee Cafe",
                        },
                        {
                          label: "HMB - Kahala Brands",
                          value: "HMB - Kahala Brands",
                        },
                        {
                          label: "HMB - Knutson Holdings, Inc.",
                          value: "HMB - Knutson Holdings, Inc.",
                        },
                        {
                          label: "HMB - Main Street Funding Group",
                          value: "HMB - Main Street Funding Group",
                        },
                        {
                          label: "HMB - Marketing",
                          value: "HMB - Marketing",
                        },
                        {
                          label: "HMB - Naturals2go",
                          value: "HMB - Naturals2go",
                        },
                        {
                          label: "HMB - Neighborhood Kids",
                          value: "HMB - Neighborhood Kids",
                        },
                        {
                          label: "HMB - OctoClean",
                          value: "HMB - OctoClean",
                        },
                        {
                          label: "HMB - Pomperdale NY Deli",
                          value: "HMB - Pomperdale NY Deli",
                        },
                        {
                          label: "HMB - PPC",
                          value: "HMB - PPC",
                        },
                        {
                          label: "HMB - PrimeIV",
                          value: "HMB - PrimeIV",
                        },
                        {
                          label: "HMB - Realty One Group",
                          value: "HMB - Realty One Group",
                        },
                        {
                          label: "HMB - SnapChef",
                          value: "HMB - SnapChef",
                        },
                        {
                          label: "HMB - Tacos 4 Life",
                          value: "HMB - Tacos 4 Life",
                        },
                        {
                          label: "HMB - Wetzel's Pretzels",
                          value: "HMB - Wetzel's Pretzels",
                        },
                        {
                          label: "HMB - YoNutz",
                          value: "HMB - YoNutz",
                        },
                        {
                          label: "HMB - Zoom Room Dog Training",
                          value: "HMB - Zoom Room Dog Training",
                        },
                        {
                          label: "Hypernova Capital LLC",
                          value: "Hypernova Capital LLC",
                        },
                        {
                          label: "Incfunder",
                          value: "Incfunder",
                        },
                        {
                          label: "Journeys End Funding",
                          value: "Journeys End Funding",
                        },
                        {
                          label: "Keith Aichele",
                          value: "Keith Aichele",
                        },
                        {
                          label: "LinkedIn",
                          value: "LinkedIn",
                        },
                        {
                          label: "Los Angeles Hydroponics",
                          value: "Los Angeles Hydroponics",
                        },
                        {
                          label: "Lynnda Weight",
                          value: "Lynnda Weight",
                        },
                        {
                          label: "MariWaste",
                          value: "MariWaste",
                        },
                        {
                          label: "Marshall",
                          value: "Marshall",
                        },
                        {
                          label: "MC Consulting",
                          value: "MC Consulting",
                        },
                        {
                          label: "MJ Biz Con",
                          value: "MJ Biz Con",
                        },
                        {
                          label: "NCH",
                          value: "NCH",
                        },
                        {
                          label: "Ripe Funding",
                          value: "Ripe Funding",
                        },
                        {
                          label: "Rotay Capital Finance",
                          value: "Rotay Capital Finance",
                        },
                        {
                          label: "Royale Holdings LLC",
                          value: "Royale Holdings LLC",
                        },
                        {
                          label: "Scott Shaul",
                          value: "Scott Shaul",
                        },
                        {
                          label: "WD Real Estate Solutions",
                          value: "WD Real Estate Solutions",
                        },
                        {
                          label: "XS Capital - Marshall",
                          value: "XS Capital - Marshall",
                        },
                        {
                          label: "XS Capital Solutions",
                          value: "XS Capital Solutions",
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormikTextField
                      label="Submission Email"
                      name="submissionEmail"
                    />
                  </Grid>
                  <Grid item xs={12} lg={6}>
                    <FormikTextField
                      label="Submission Password"
                      name="submissionPassword"
                    />
                  </Grid>

                  {/* UNRECOGNISED */}
                </Grid>

                <Grid item xs={12} sx={{ my: 2 }}>
                  <Divider>Education Info</Divider>
                </Grid>
                <Grid container spacing={2} rowSpacing={1} pb={1}>
                  <Grid item xs={12} lg={12}>
                    <FormikTextField
                      label="Driver's License Number"
                      name={"personalInfo.driversLicenseId"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikDatePicker
                      label="Driver's License Issuance Date"
                      name={"personalInfo.driversLicenseIssueDate"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikDatePicker
                      label="Driver's License Expiration Date"
                      name={"personalInfo.driversLicenseExpireDate"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikAutocomplete
                      label="Housing Status"
                      name={"housingInfo.houstingStatus"}
                      options={[
                        {
                          value: "Own",
                          label: "Own",
                        },
                        {
                          value: "Rent",
                          label: "Rent",
                        },
                        {
                          value: "Live with Family",
                          label: "Live with Family",
                        },
                        {
                          value: "Other",
                          label: "Other",
                        },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikNumberField
                      label="Monthly Housing Payment"
                      name={"housingInfo.monthlyHousingPayment"}
                      prefix="$"
                    />
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <FormikDatePicker
                      label="Move in Date"
                      name={"housingInfo.moveInDate"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <FormikTextField
                      label="Time at Residence"
                      name={"housingInfo.calculatedLengthOfTimeAtResidence"}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <FormikNumberField
                      label="Years at Current Addresss"
                      name={"housingInfo.yearsAtCurrentAddress"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <FormikNumberField
                      label="Estimated Length of time at Residence"
                      name={"housingInfo.estimatedLengthOfTimeAtResidence"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikNumberField
                      label="Monthly Personal Gross Income"
                      name={"employmentInfo.monthlyGrossIncome"}
                      prefix="$"
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikNumberField
                      label="Annual Personal Income"
                      name={"employmentInfo.annualPersonalIncome"}
                      prefix="$"
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikNumberField
                      label="Annual Household Income"
                      name={"employmentInfo.totalAnnualHouseholdIncome"}
                      prefix="$"
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikTextField
                      label="Member of Credit Union"
                      name={"personalInfo.creditUnion"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikTextField
                      label="Personal Banking Relationship"
                      name={"personalInfo.personalBank"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikTextField
                      label="Bank Routing Number"
                      name={"personalInfo.bankRoutingNumber"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikTextField
                      label="Bank Account Number"
                      name={"personalInfo.bankAccountNumber"}
                    />
                  </Grid>

                  <Grid item xs={12} lg={12}>
                    <FormikCheckbox
                      label="Has Credit Repair Before"
                      name={"personalInfo.creditRepairBefore"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikTextField
                      label="BK in past 20 years"
                      name={"personalInfo.judgementsLiensBankruptcy"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikCheckbox
                      label="Worked with a Finance Company"
                      name={"personalInfo.previoiusFinanceCompany"}
                    />
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <FormikTextField
                      label="College/University Attended"
                      name={"educationInfo.collegeAttended"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <FormikTextField
                      label="Field of study"
                      name={"educationInfo.fieldOfStudy"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <FormikTextField
                      label="Degree"
                      name={"educationInfo.degree"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <FormikDatePicker
                      label="Graduation date"
                      name={"educationInfo.graduatedDate"}
                    />
                  </Grid>

                  <Grid item xs={12} lg={3}>
                    <FormikTextField
                      label="Graduate School Attended"
                      name={"educationInfo.graduateSchoolAttended"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <FormikTextField
                      label="Graduate School Field of Study"
                      name={"educationInfo.graduateSchoolFieldOfStudy"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <FormikTextField
                      label="Graduate Degree Received"
                      name={"educationInfo.graduateDegreeReceived"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <FormikTextField
                      label="Graduate Graduation date"
                      name={"educationInfo.graduateGraduationDate"}
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} lg={12}>
                  <FormikAutocomplete
                    label="Military Status"
                    name={"personalInfo.militaryStatus"}
                    options={[
                      {
                        value: "Active Duty",
                        label: "Active Duty",
                      },
                      {
                        value: "Retired",
                        label: "Retired",
                      },
                      {
                        value: "Family Member",
                        label: "Family Member",
                      },
                      {
                        value: "Reserves",
                        label: "Reserves",
                      },
                      {
                        value: "Veteran",
                        label: "Veteran",
                      },
                      {
                        value: "Non-Military",
                        label: "Non-Military",
                      },
                      {
                        value: "Military Spouse",
                        label: "Military Spouse",
                      },
                    ]}
                  />
                </Grid>

                <Grid item xs={12} lg={12}>
                  <FormikTextField
                    label="Military Affiliation"
                    name={"personalInfo.militaryAffiliation"}
                  />
                </Grid>

                <Grid item xs={12} sx={{ my: 2 }}>
                  <Divider>
                    <FormattedMessage id="CUSTOMERS.EMPLOYMENT_INFO" />
                  </Divider>
                </Grid>

                <Grid container spacing={2} rowSpacing={1} pb={1}>
                  <Grid item xs={12} lg={12}>
                    <FormikTextField
                      label="Employer"
                      name={"employmentInfo.employerName"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikTextField
                      label="Employer Phone Number"
                      name={"employmentInfo.employerPhone"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikTextField
                      label="Employer Address"
                      name={"employmentInfo.employerAddress"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormikTextField
                      label="Est. time at job"
                      name={"employmentInfo.estimatedTimeAtJob"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormikDatePicker
                      label="Start Date with Employer"
                      name={"employmentInfo.startDateWithEmployer"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <FormikTextField
                      label="Calculated Length of Employment"
                      name={"employmentInfo.calculatedLengthOfEmployment"}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikTextField
                      label="Occupation/Position"
                      name={"employmentInfo.occupationPosition"}
                    />
                  </Grid>
                  {/* 1 */}
                  <Grid item xs={12} lg={12}>
                    <FormikAutocompleteMultiple
                      label="Income Type"
                      name={"employmentInfo.incomeType"}
                      options={[
                        {
                          value: "W2",
                          label: "W2",
                        },
                        {
                          value: "Business Owner",
                          label: "Business Owner",
                        },
                        {
                          value: "1099",
                          label: "1099",
                        },
                        {
                          value: "Retired",
                          label: "Retired",
                        },
                        {
                          value: "Other",
                          label: "Other",
                        },
                      ]}
                    />
                  </Grid>

                  <Grid item xs={12} lg={12}>
                    <FormikNumberField
                      label="Stated Monthly Income"
                      name={"employmentInfo.statedMonthlyIncome"}
                      prefix="$"
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikNumberField
                      label="Stated Annual Income"
                      name={"employmentInfo.statedAnnualIncome"}
                      prefix="$"
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikNumberField
                      label="Stated Annual Household Income"
                      name={"employmentInfo.statedAnnualHouseholdIncome"}
                      prefix="$"
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} sx={{ my: 2 }}>
                  <Divider>
                    <FormattedMessage id="CUSTOMERS.ASSET_INFO" />
                  </Divider>
                </Grid>
                <Grid container spacing={2} rowSpacing={1} pb={1}>
                  <Grid item xs={12} lg={12}>
                    <FormikNumberField
                      label="Combined Checking & Savings Balance"
                      name={"assetInfo.combinedCheckingSavingsBalance"}
                      prefix="$"
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikNumberField
                      label="Stocks, Bonds & Mutual Funds"
                      name={"assetInfo.stocksBondsMutualFunds"}
                      prefix="$"
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikNumberField
                      label="Retirement Account Balance"
                      name={"assetInfo.retirementAccountBalance"}
                      prefix="$"
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikNumberField
                      label="AVM in Response"
                      name={"primaryResidenceValuation.avmValue"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikNumberField
                      label="Market Value in Response"
                      name={"primaryResidenceValuation.marketValue"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikNumberField
                      label="Zillow Value"
                      name={"primaryResidenceValuation.zillowValue"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <FormikNumberField
                      label="Est. Value of Primary Residence"
                      name={"primaryResidenceValuation.estimatedPropertyValue"}
                      prefix="$"
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <FormikNumberField
                      label="Est. Equity in Primary Residence"
                      name={
                        "primaryResidenceValuation.estimatedEquityPrimaryResidence"
                      }
                      prefix="$"
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <FormikNumberField
                      label="Cal. Value of Primary Residence"
                      name={"primaryResidenceValuation.calculatedValue"}
                      prefix="$"
                    />
                  </Grid>
                  <Grid item xs={12} lg={3}>
                    <FormikNumberField
                      label="Cal. Equity of Primary Residence"
                      name={"primaryResidenceValuation.calculatedEquity"}
                      prefix="$"
                    />
                  </Grid>
                </Grid>

                <Grid item xs={12} sx={{ my: 2 }}>
                  <Divider>Business Info</Divider>
                </Grid>

                <Grid container spacing={2} rowSpacing={1} pb={1}>
                  <Grid item xs={12} lg={12}>
                    <FormikTextField
                      label="Business Account Number"
                      name={"personalInfo.businessBankAccountNumber"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikTextField
                      label="Business Account Routing Number"
                      name={"personalInfo.businessBankRoutingNumber"}
                    />
                  </Grid>
                  <Grid item xs={12} lg={12}>
                    <FormikTextField
                      label="Business Banking Relationship"
                      name={"personalInfo.businessBank"}
                    />
                  </Grid>
                </Grid>
              </>
            </DialogContent>

            <DialogActions>
              <Button
                // data-testid="new-edit-port-close-button"
                onClick={handleClose}
              >
                <FormattedMessage id="GLOBAL.CLOSE" />
              </Button>
              <LoadingButton
                type="submit"
                variant="contained"
                loading={submitStatus === "loading"}
              >
                <FormattedMessage id="GLOBAL.SAVE" />
              </LoadingButton>
            </DialogActions>
          </FormikForm>
        </FormikProvider>
      )}
    </>
  );
};

export default CustomerForm;
