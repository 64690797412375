export const percentageFormatter = (
  value: number | null | undefined,
  multiplier?: 1 | 100,
) =>
  !Number.isNaN(value) && value !== null && value !== undefined
    ? `${(value * (multiplier || 1))?.toFixed(0)} %`
    : "-";

export const precisePercentageFormatter = (
  value: number | null | undefined,
  multiplier?: 1 | 100,
) =>
  !Number.isNaN(value) && value !== null && value !== undefined
    ? `${(value * (multiplier || 1))?.toFixed(2)} %`
    : "-";
