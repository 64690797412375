import {
  Business as BusinessIcon,
  EmailOutlined as EmailOutlinedIcon,
  LockOpenOutlined as LockOpenOutlinedIcon,
  ManageAccounts as ManageAccountsIcon,
  Webhook as WebhookIcon,
} from "@mui/icons-material";
import {
  Avatar,
  Button,
  Divider,
  Drawer,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
// import { useAuth0 } from "@auth0/auth0-react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router";

import { hasPermissions, useUserStore } from "components/stores/UserStore";
import UserAvatar from "components/UserAvatar/UserAvatar";
interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const UserDrawer = ({ isOpen, onClose }: Props) => {
  const queryClient = useQueryClient();
  const [user] = useUserStore((s) => [s.user]);
  const history = useHistory();

  const handleLogout = () => {
    localStorage.removeItem("JWToken");
    queryClient.removeQueries([]);
    history.push("/login");

    onClose();
  };

  const userName = user?.name;

  return (
    <Drawer
      PaperProps={{
        sx: { width: "400px", p: 3 },
      }}
      variant="temporary"
      anchor="right"
      open={isOpen}
      onClose={onClose}
    >
      <Typography variant="h4" sx={{ p: 1 }}>
        <FormattedMessage id="USER_SIDEBAR.HEADER" />
      </Typography>
      <Stack direction="row" spacing={2} sx={{ mt: 2, p: 1 }}>
        <UserAvatar userName={userName || ""} />
        <Stack sx={{ minWidth: 0 }}>
          <Typography
            variant="h4"
            noWrap
            sx={{ fontWeight: (t) => t.typography.fontWeightMedium }}
          >
            {userName}
          </Typography>
          <Button
            variant="contained"
            sx={{ width: "100%", mt: 1 }}
            size="small"
            onClick={handleLogout}
          >
            <FormattedMessage id="HEADER.LOGOUT" />
          </Button>
        </Stack>
      </Stack>

      <Divider sx={{ my: 3, mx: 1 }} />

      <Stack sx={{ minWidth: 0, mx: 1 }}>
        <Stack direction="row" sx={{ mb: 2 }}>
          <BusinessIcon sx={{ mr: 1 }} />
          <Typography noWrap textTransform="capitalize">
            {user?.organisation.name}
          </Typography>
        </Stack>

        <Stack direction="row" sx={{ mb: 2 }}>
          <LockOpenOutlinedIcon sx={{ mr: 1 }} />
          <Typography noWrap textTransform="capitalize">
            {user?.role}
          </Typography>
        </Stack>

        <Stack direction="row">
          <EmailOutlinedIcon sx={{ mr: 1 }} />
          <Typography noWrap>{user?.email}</Typography>
        </Stack>
      </Stack>

      <Divider sx={{ my: 3, mx: 1 }} />

      <List>
        <ListItemButton
          onClick={() => {
            history.push("/user/profile");
            onClose();
          }}
          sx={{ px: 1, borderRadius: 1.5 }}
        >
          <ListItemAvatar>
            <Avatar
              // variant="rounded"
              sx={{
                backgroundColor: (t) => t.palette.grey[300],
                color: (t) => t.palette.primary.main,
                borderRadius: 1,
              }}
            >
              <ManageAccountsIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={<FormattedMessage id="USER_SIDEBAR.MY_PROFILE.HEADER" />}
            secondary={
              <FormattedMessage id="USER_SIDEBAR.MY_PROFILE.SUBHEADER" />
            }
          />
        </ListItemButton>

        {hasPermissions("read:logs") && (
          <ListItemButton
            onClick={() => {
              history.push("/webhook-logs");
              onClose();
            }}
            sx={{ px: 1, borderRadius: 1.5 }}
          >
            <ListItemAvatar>
              <Avatar
                // variant="rounded"
                sx={{
                  backgroundColor: (t) => t.palette.grey[300],
                  color: (t) => t.palette.primary.main,
                  borderRadius: 1,
                }}
              >
                <WebhookIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={<FormattedMessage id="HEADER.WEBHOOK_LOGS" />}
              secondary={
                <FormattedMessage id="USER_SIDEBAR.WEBHOOK_LOGS.SUBHEADER" />
              }
            />
          </ListItemButton>
        )}
      </List>
    </Drawer>
  );
};

export default UserDrawer;
