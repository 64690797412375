import { Delete as DeleteIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";

import { deleteCreditEvaluation as deleteCreditEvaluationAPI } from "api/creditEvaluations";
import { creditEvaluationsKeys } from "api/creditEvaluations/queries";
import { CreditEvaluationsType } from "api/creditEvaluations/types";

interface DeleteCreditEvaluationModalProps {
  isOpen: boolean;
  handleClose: () => void;
  creditEvaluation: CreditEvaluationsType | undefined;
}

const DeleteCreditEvaluationModal = ({
  isOpen,
  handleClose,
  creditEvaluation,
}: DeleteCreditEvaluationModalProps) => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const { mutateAsync: deleteCreditEvaluation, status } = useMutation(
    async (customerId: string) => {
      const res = await deleteCreditEvaluationAPI(customerId);
      await queryClient.invalidateQueries(creditEvaluationsKeys.list());
      return res.data;
    },
  );

  const handleDelete = async () => {
    await deleteCreditEvaluation(creditEvaluation!._id, {
      onSuccess: () => {
        handleClose();
        history.push("/credit-evaluations");
      },
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm">
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <DeleteIcon sx={{ mr: 1 }} />
          Delete Eval
        </Stack>
      </DialogTitle>

      <Divider />

      <DialogContent>
        Deleting Eval removes from records from Lendzee and HubSpot
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>
          <FormattedMessage
            // data-testid="delete-port-close-button"
            id="GLOBAL.CANCEL"
          />
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          onClick={handleDelete}
          loading={status === "loading"}
        >
          <FormattedMessage id="GLOBAL.DELETE" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCreditEvaluationModal;
