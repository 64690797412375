import { CustomersType } from "api/customers/types";

// TRADELINES
export type CreditEvaluationTradeline = {
  joint?: boolean;

  status: "opened" | "closed";
  creditor: string;
  balance: number;
  payment: number;
  hpb: number;
  creditLimit: number;
  opened: Date;
  reportDate: Date;
  accountType: string;
  utilizationRate: number;
  typeDetail: string;
};

// LOANS
export type CreditEvaluationLoan = {
  joint?: boolean;

  status: "opened" | "closed";
  creditor: string;
  balance: number;
  payment: number;
  hpb: number;
  limit: number;
  opened: Date;
  reportDate: Date;
  accountType: string;
  debitToCreditRatio: string;
  typeDetail: string;
};

// INCOMES
export enum CreditEvaluationIncomeTypeEnum {
  PAYSTUB = "paystub",
  SELF_EMPLOYMENT = "self-employment",
  ADDITIONAL_INCOME = "additional-income",
  HOUSING_ALLOWANCE = "housing-allowance",
}

export enum CreditEvaluationIncomePeriodsEnum {
  WEEKLY = "weekly",
  BIWEEKLY = "bi-weekly",
  MONTHLY = "mothly",
  QUARTERLY = "quarterly",
  BIMONTHLY = "bi-monthly",
  ANNUAL = "annual",
}

export enum CreditEvaluationIncomePaystubsEnum {
  "weekly" = 52,
  "bi-weekly" = 26,
  "monthly" = 12,
  "quarterly" = 4,
  "bi-monthly" = 24,
  "annual" = 1,
}

// DEBTS
export type CreditEvaluationDebtDetails = {
  debtPayment: number;
  overrideDebtPayment: number;
  calculatedDeferredStudentLoans: number;
  deferredStudentLoans: number;
  rentPayment: number;
  totalDebtPayment: number;
  totalDebtPaymentHalfMortage: number;
  spouseIncome: number;
  spousalDebt: number;
  totalPayment: number;
  mortgagePayment: number;
};

// SUMMARY OF INCOMES
export type CreditEvaluationSummaryOfIncomes = {
  incomeSources: {
    selected: boolean;
    year: number;
    source?: string;
    eoyExpected: number;
    type: CreditEvaluationIncomeTypeEnum;
  }[];
};

export enum CreditEvaluationIncomeOverviewEnum {
  STATED_INCOME = "stated-income",
  INDIVIDUAL_INCOME_CURRENT_YEAR = "individual-income-current-year",
  INDIVIDUAL_INCOME_PRIOR_YEAR = "individual-income-prior-year",
  INDIVIDUAL_INCOME_2_YEAR_AVERAGE = "individual-income-2-year-average",
  INDIVIDUAL_INCOME_3_YEAR_AVERAGE = "individual-income-3-year-average",
  INDIVIDUAL_INCOME_STUDENT_LOAN_ADJUSTED = "individual-income-student-loan-adjusted",
  INDIVIDUAL_INCOME_RENT_ADJUSTED = "individual-income-rent-adjusted",
  INDIVIDUAL_INCOME_HALF_MORTGAGE = "individual-income-half-mortgage",
  HOUSEHOLD_INCOME = "household-income",
}

// INCOME OVERVIEW
export type CreditEvaluationIncomeOverview = {
  _id: string;
  type: CreditEvaluationIncomeOverviewEnum;
  monthly: number;
  annual: number;
  dti: number;
};

export enum CreditEvaluationLoanAffordabilityEnum {
  SELECTED_INCOME = "selected-income",
  AFFORDABILITY_INCLUDING_STUDENT_LOAN_DEBT = "affordability-including-student-loan-debt",
  AFFORDABILITY_INCLUDING_RENT = "affordability-including-rent",
  AFFORDABILITY_INCLUDING_RENT_AND_DEFERRED_STUDENT_LOANS = "affordability-including-rent-and-deferred-student-loans",
  AFFRODABILITY_HALF_MORTAGE = "affordability-half-mortgage",
  HOUSEHOLD_INCOME = "household-income",
  HOUSEHOLD_AFFORDABILITY_INCLUDING_STUDENT_LOAN_DEBT = "household-affordability-including-student-loan-debt",
  HOUSEHOLD_AFFORDABILITY_INCLUDING_RENT = "household-affordability-including-rent",
  HOUSEHOLD_AFFORDABILITY_INCLUDING_RENT_AND_DEFERRED_STUDENT_LOANS = "household-affordability-including-rent-and-deferred-student-loans",
}

// LOAN AFFORDABILITY
export type CreditEvaluationLoanAffordability = {
  source: CreditEvaluationLoanAffordabilityEnum;
  rate: number;
  dti: number;

  annualTotal: number;
  monthlyTotal: number;
  monthlyTotalWithDebt: number;

  term60: number;
  term72: number;
  term84: number;
  term120: number;
  term144: number;
};

export type CreditEvaluationIncomeSource = {
  date: Date;
  // PAYSTUB
  amount?: number;
  ytd?: number;
  averageAnnual?: number;
  numberOfPeriodsToDate?: number;
  avgPerPeriod?: number;
  averageAnnual2?: number;
  numberOfPeriodsRemaining?: number;
  amountOfPayRemaining?: number;
  endOfYearExpectedIncome?: number;
  calculatedIncome?: number;
  // SELF EMPLOYMENT
  grossRevenue?: number;
  netProfit?: number;
  percentageOfProfit?: number;
  averageMonthlyGrossRevenue?: number;
  yearOverYearGrossGrowth?: number;
  averageMonthlyNetProfit?: number;
  yearOverYearNetGrowth?: number;
  annualWages?: number;
  mothlyWage?: number;
  // ADDITIONAL
  source?: string;
  monthlyBenefit?: number;
  previousIncomes?: {
    year: number;
    yearIncome: number;
    months: number;
  }[];
};

export type CreditEvaluationIncome = {
  _id: string;
  type: CreditEvaluationIncomeTypeEnum;
  source: string;
  payStubs?: CreditEvaluationIncomePaystubsEnum;
  period?: string;
  averageCheckAmount?: number;
  averageCheckAmountBasedOnYTD?: number;
  incomeSources: CreditEvaluationIncomeSource[];
};

export type CreditEvaluationCollection = {
  dateVerified: Date;
  status: string;
  memberNumber: string;
  narrativesCreditorAccountNumber: string;
  industryCode: string;
  dateReported: Date;
  amount: number;
  balance: number;
  dateClosed: Date;
};

export type CreditEvaluationPublicRecords = {
  courtNumber: string;
  dateReported: Date;
  memberNumber: string;
  amount: number;
  recordType: string;
  datePaid: Date;
  plaintiff: string;
  assets: string;
  courtType: string;
  accDesignator: string;
  attorney: string;
  liability: number;
  publicRecordDisposition: string;
  docket: string;
  industry: string;
  origDate: Date;
};

export type CreditEvaluationLatePayments = {
  creditor: string;
  reportDate: Date;
  rating30: number;
  rating60: number;
  rating90: number;
  paymentPattern: string[];
};

export type CreditEvaluationChargeoffs = {
  creditor: string;
  reportDate: Date;
  chargeoff: number;
  pastdue: number;
};

export enum CreditEvaluationAffordabilityEnum {
  PENDING_EVAL = "Pending Eval",
  LOW = "Low",
  MEDIUM = "Medium",
  HIGH = "High",
  NONE = "None",
}

export type CreditEvaluationsType = {
  _id: string;
  customer?: CustomersType;
  hubspotDealId: string;
  leadSource: string;
  // HTML and PDF
  html: string;
  pdf: string;
  reportDate: Date;
  firstCreditAccount: string;
  monitoringService: string;
  state: string;
  ageOfFile: Date;
  averageMonthsOfOpenRevolvingCredit: number;
  loanPackageAmount: number;
  statedMonthlyIncome: number;
  // Credit Scores
  creditScores: {
    type: "XPN";
    score: number;
  }[];
  // Inquires
  recentInquiries: {
    type: "XPN";
    lastSixMonths: number;
    lastTwelveMonths: number;
    inquiries: {
      name: string;
      date: Date;
    }[];
  }[];
  // Tradelines
  tradelines: CreditEvaluationTradeline[];
  // Loans
  loans: CreditEvaluationLoan[];
  // Incomes
  incomes: CreditEvaluationIncome[];
  // Debt Details
  debtDetails: CreditEvaluationDebtDetails;
  // Summary of Incomes
  summaryOfIncomes?: CreditEvaluationSummaryOfIncomes;
  // Income Overview
  incomesOverview: CreditEvaluationIncomeOverview[];
  selectedHouseholdIncome?: CreditEvaluationIncomeOverviewEnum;
  // Loan Affordability
  loanAffordability: CreditEvaluationLoanAffordability[];
  loanAffordabilityRate: number;

  // Collections
  collections: CreditEvaluationCollection[];
  // Public Records
  publicRecords: CreditEvaluationPublicRecords[];
  // Late Payments
  latePayments: CreditEvaluationLatePayments[];
  // Chargeoffs
  chargeOffs: CreditEvaluationChargeoffs[];

  // Affordability
  affordability: CreditEvaluationAffordabilityEnum;
  // Notes
  notes: string;
  // Deal Status
  dealStatus: string;
};

export type NewCreditEvaluationPayloadType = {
  customer: string | null;
};

export interface EditCreditEvaluationType
  extends NewCreditEvaluationPayloadType {
  _id: CreditEvaluationsType["_id"];
}
