import { Add as AddIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid, Link, Stack, Typography } from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Form as FormikForm, FormikProvider, useFormik } from "formik";
import { useMemo } from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import {
  getSingleCreditEvaluation,
  pushCreditEvaluationLoanApplications,
} from "api/creditEvaluations";
import { creditEvaluationsKeys } from "api/creditEvaluations/queries";
import {
  CreditEvaluationIncome,
  CreditEvaluationsType,
} from "api/creditEvaluations/types";
import { getLoanApplications } from "api/loanApplications";
import { loanApplicationsKeys } from "api/loanApplications/queries";
import { LoanApplicationsType } from "api/loanApplications/types";
import { QueryParamsType } from "api/utils";
import { ReactComponent as HubspotSVG } from "assets/icons/hubspot.svg";
import FormikAutocomplete from "components/forms/FormikAutocomplete";
import { hasPermissions } from "components/stores/UserStore";
import { dateFormatter } from "components/Table/formatters/dateFormatter";
import { useModal } from "utils/hooks/useModal";

import NewLoanApplicationModal from "../../loanApplications/LoanApplicationsTable/components/NewLoanApplicationModal";
import LoanApplicationsTable from "../../loanApplications/LoanApplicationsTable/LoanApplicationsTable";
import CreditEvaluationOverview from "./components/CreditEvaluationOverview";
import CreditScoreFormatter from "./components/CreditScore/CreditScoreFormatter";
import CreditScoreFormatterLoading from "./components/CreditScore/CreditScoreFormatterLoading";
import DebtPmtTable from "./components/DebtPtm/DebtPmtTable";
import DeleteCreditEvaluationModal from "./components/DeleteCreditEvaluationModal";
import AddIncomeModal from "./components/Income/AddIncomeModal";
import IncomesTable from "./components/Income/IncomesTable/IncomesTable";
import SummaryOfIncomesTable from "./components/Income/IncomesTable/SummaryOfIncomesTable";
import IncomeOverviewTable from "./components/IncomeOverview/IncomeOverviewTable/IncomeOveriewTable";
import LoanAffordabilities from "./components/LoanAffordabilities/LoanAffordabilities";
import LoanAffordabilitiesLoading from "./components/LoanAffordabilities/LoanAffordabilitiesLoading";
import LoanAffordabilitiesRate from "./components/LoanAffordabilities/LoanAffordabilitiesRate";
import CollectionsTable from "./components/tables/Collections/CollectionsTable";
import LoansTable from "./components/tables/Loans/LoansTable";
import PublicRecordsTable from "./components/tables/PublicRecords/PublicRecordsTable";
import TradelinesTable from "./components/tables/Tradelines/TradelinesTable";

const SinlgeCreditEvaluation = () => {
  const intl = useIntl();
  const queryClient = useQueryClient();

  const { creditEvaluationId } = useParams<{ creditEvaluationId: string }>();

  const {
    data: creditEvaluation,
    status: creditEvaluationStatus,
    isLoading: isCreditEvaluationLoading,
  } = useQuery(creditEvaluationsKeys.details(creditEvaluationId), async () => {
    const { data: res } = await getSingleCreditEvaluation(creditEvaluationId);
    return res.data;
  });

  const loanApplicationsFilter: QueryParamsType<LoanApplicationsType> = {
    filter: [
      { field: "creditEvaluation", operator: "eq", value: creditEvaluationId },
    ],
    limit: 1000,
  };

  const { data: loanApplications, status: loanApplicationsStatus } = useQuery(
    loanApplicationsKeys.listFiltered(loanApplicationsFilter),
    async () => {
      const { data: res } = await getLoanApplications(loanApplicationsFilter);
      return res.data;
    },
  );

  const {
    isOpen: isOpenAddIncome,
    handleClose: handleCloseAddIncome,
    handleOpen: handleOpenAddIncome,
  } = useModal<CreditEvaluationIncome>();

  const {
    isOpen: isOpenAddLoanApplication,
    handleClose: handleCloseAddLoanApplication,
    handleOpen: handleOpenAddLoanApplication,
  } = useModal<LoanApplicationsType>();

  const {
    isOpen: isOpenDelete,
    handleClose: handleCloseDelete,
    handleOpen: handleOpenDelete,
    context: deleteContext,
  } = useModal<CreditEvaluationsType>();

  const creditScoresInquires = useMemo(() => {
    if (creditEvaluation) {
      return creditEvaluation.creditScores.map((creditScore) => {
        const matchedRecentInquires = creditEvaluation.recentInquiries.find(
          (inquire) => inquire.type === creditScore.type,
        );

        return {
          lastSixMonths: 0,
          lastTwelveMonths: 0,
          inquiries: [],
          ...matchedRecentInquires,
          ...creditScore,
        };
      });
    }

    return [];
  }, [creditEvaluation]);

  const formik = useFormik({
    initialValues: {
      tradelinesStatus: "opened",
      loansStatus: "opened",
    },
    onSubmit: () => {},
  });

  const { values } = formik;

  const tradelines = useMemo(() => {
    if (values.tradelinesStatus && values.tradelinesStatus !== "") {
      return creditEvaluation?.tradelines?.filter((tradeline) => {
        if (tradeline.accountType === "Terminated") {
          return values.tradelinesStatus !== "opened";
        }

        return (
          tradeline.status === values.tradelinesStatus ||
          (tradeline.status === "closed" && tradeline.balance > 0)
        );
      });
    }
    return creditEvaluation?.tradelines;
  }, [creditEvaluation?.tradelines, values.tradelinesStatus]);

  const loans = useMemo(() => {
    if (values.loansStatus && values.loansStatus !== "") {
      return creditEvaluation?.loans?.filter(
        (loan) =>
          loan.status === values.loansStatus ||
          (loan.status === "closed" && loan.balance > 0),
      );
    }
    return creditEvaluation?.loans;
  }, [creditEvaluation?.loans, values.loansStatus]);

  const statusOptions = [
    { label: intl.formatMessage({ id: "GLOBAL.ALL" }), value: "" },
    { label: intl.formatMessage({ id: "GLOBAL.OPENED" }), value: "opened" },
    { label: intl.formatMessage({ id: "GLOBAL.CLOSED" }), value: "closed" },
  ];

  // const {} = useMutation()
  // pushCreditEvaluationLoanApplications

  const { mutateAsync: pushLoanApplications, isLoading: isPushLoading } =
    useMutation(async () => {
      const res =
        await pushCreditEvaluationLoanApplications(creditEvaluationId);
      await queryClient.invalidateQueries(loanApplicationsKeys.list());

      return res.data;
    });

  const hasUnpublishedLoanApplicaiton = useMemo(() => {
    if (loanApplications?.length) {
      return loanApplications.some(
        (loanApplication) => !loanApplication.upToDate,
      );
    }
    return false;
  }, [loanApplications]);

  return (
    <>
      <Helmet
        title={intl.formatMessage(
          {
            id: "CREDIT_EVALUATIONS.DETAILS.TITLE",
          },
          {
            deal_name: creditEvaluation
              ? `${creditEvaluation.customer?.firstName}
            ${creditEvaluation.customer?.lastName}
            ${dateFormatter(creditEvaluation.reportDate)}`
              : "",
          },
        )}
      />
      <Stack
        direction={{ md: "row", xs: "column" }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography variant="h2" fontWeight="bold" mb={3} mt={1}>
          Credit Evaluation Details: {creditEvaluation?.customer?.firstName}{" "}
          {creditEvaluation?.customer?.lastName}{" "}
          {dateFormatter(creditEvaluation?.reportDate)}
        </Typography>
        {hasPermissions("update:credit-evaluations") && (
          <Button
            onClick={() => handleOpenDelete(creditEvaluation)}
            variant="contained"
            color="error"
            sx={{ color: "#fff" }}
          >
            Delete Eval
          </Button>
        )}
      </Stack>
      <CreditEvaluationOverview
        creditEvaluation={creditEvaluation}
        isLoading={isCreditEvaluationLoading}
      />
      <Grid container spacing={2} sx={{ my: 3 }}>
        <Grid item xs={12} lg={4}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2}
          >
            <Typography variant="h4" fontWeight="bold">
              Income
            </Typography>
          </Stack>
          <IncomeOverviewTable
            status={creditEvaluationStatus}
            data={creditEvaluation?.incomesOverview || []}
            creditEvaluationId={creditEvaluationId}
            householdIncome={creditEvaluation?.selectedHouseholdIncome}
          />
          <DebtPmtTable
            creditEvaluationId={creditEvaluationId}
            initialValues={{
              overrideDebtPayment:
                creditEvaluation?.debtDetails.overrideDebtPayment,
              deferredStudentLoans:
                creditEvaluation?.debtDetails.deferredStudentLoans,
              rentPayment: creditEvaluation?.debtDetails.rentPayment,
              mortgagePayment: creditEvaluation?.debtDetails.mortgagePayment,
            }}
            debtDetails={creditEvaluation?.debtDetails}
          />
        </Grid>
        <Grid item xs={12} lg={4}>
          <Typography variant="h4" fontWeight="bold" mb={2}>
            Loan Affordability
          </Typography>

          <Box sx={{ mb: 2 }}>
            <LoanAffordabilitiesRate
              creditEvaluationId={creditEvaluationId}
              rate={
                !isCreditEvaluationLoading
                  ? creditEvaluation?.loanAffordabilityRate || 14
                  : undefined
              }
            />
          </Box>
          {isCreditEvaluationLoading ? (
            <>
              <LoanAffordabilitiesLoading />
              <LoanAffordabilitiesLoading />
            </>
          ) : (
            <LoanAffordabilities
              data={creditEvaluation?.loanAffordability || []}
            />
          )}
        </Grid>

        {isCreditEvaluationLoading ? (
          <Grid item xs={12} lg={4}>
            <CreditScoreFormatterLoading />
          </Grid>
        ) : (
          creditScoresInquires.map((creditScore) => (
            <Grid item xs={12} lg={4}>
              <CreditScoreFormatter creditScore={creditScore} />
            </Grid>
          ))
        )}
      </Grid>
      {/* 
      <Typography variant="h4" fontWeight="bold" mb={2} mt={5}>
        <FormattedMessage id="CREDIT_EVALUATIONS.RECENT_INQUIRIES.TITLE" />
      </Typography>
      <RecentInquiriesTable
        data={creditEvaluation?.recentInquiries || []}
        status={creditEvaluationStatus}
      /> */}{" "}
      <FormikProvider value={formik}>
        <FormikForm>
          <Stack
            direction="row"
            alignItems="center"
            mt={5}
            mb={2}
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Typography variant="h4" fontWeight="bold">
                <FormattedMessage id="LOAN_APPLICATIONS.TITLE" />
              </Typography>

              {creditEvaluation?.hubspotDealId && (
                <Link
                  href={`https://app.hubspot.com/contacts/44366077/record/0-3/${creditEvaluation.hubspotDealId}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <HubspotSVG style={{ height: "50px" }} />
                </Link>
              )}
            </Stack>

            <Stack direction="row" spacing={1}>
              {hasPermissions("update:credit-evaluations") && (
                <LoadingButton
                  sx={{
                    ml: "auto",
                    background: "#ff7a59",
                    "&:hover": {
                      background: "#ef6a49",
                    },
                  }}
                  variant="contained"
                  loading={isPushLoading}
                  onClick={() => pushLoanApplications()}
                  disabled={!hasUnpublishedLoanApplicaiton}
                >
                  {/* <AddIcon sx={{ mr: 0.5 }} /> */}
                  Push to Hubspot
                </LoadingButton>
              )}
              {hasPermissions("update:credit-evaluations") && (
                <Button
                  sx={{
                    ml: "auto",
                  }}
                  variant="contained"
                  onClick={() => handleOpenAddLoanApplication()}
                >
                  <AddIcon sx={{ mr: 0.5 }} />
                  <FormattedMessage id="LOAN_APPLICATIONS.ADD" />
                </Button>
              )}
            </Stack>
          </Stack>
          <LoanApplicationsTable
            data={loanApplications || []}
            status={loanApplicationsStatus}
          />

          <Stack
            direction="row"
            alignItems="center"
            mt={5}
            mb={2}
            justifyContent="space-between"
          >
            <Typography variant="h4" fontWeight="bold">
              <FormattedMessage id="CREDIT_EVALUATIONS.TRADELINES.TITLE" />
            </Typography>

            <FormikAutocomplete
              name="tradelinesStatus"
              label="Status"
              options={statusOptions}
              sx={{ width: "200px" }}
            />
          </Stack>
          <TradelinesTable
            data={tradelines || []}
            status={creditEvaluationStatus}
          />

          <Stack
            direction="row"
            alignItems="center"
            mt={5}
            mb={2}
            justifyContent="space-between"
          >
            <Typography variant="h4" fontWeight="bold">
              <FormattedMessage id="CREDIT_EVALUATIONS.LOANS.TITLE" />
            </Typography>
            <FormikAutocomplete
              name="loansStatus"
              label="Status"
              options={statusOptions}
              sx={{ width: "200px" }}
            />
          </Stack>
          <LoansTable data={loans || []} status={creditEvaluationStatus} />
        </FormikForm>
      </FormikProvider>
      {/* Collections */}
      <Stack
        direction="row"
        alignItems="center"
        mt={5}
        mb={2}
        justifyContent="space-between"
      >
        <Typography variant="h4" fontWeight="bold">
          Collections
        </Typography>
      </Stack>
      <CollectionsTable
        data={creditEvaluation?.collections || []}
        status={creditEvaluationStatus}
      />
      {/* Public Records */}
      <Stack
        direction="row"
        alignItems="center"
        mt={5}
        mb={2}
        justifyContent="space-between"
      >
        <Typography variant="h4" fontWeight="bold">
          Public Records
        </Typography>
      </Stack>
      <PublicRecordsTable
        data={creditEvaluation?.publicRecords || []}
        status={creditEvaluationStatus}
      />
      {/* Income Sources */}
      <Stack
        direction="row"
        alignItems="center"
        mt={5}
        mb={2}
        justifyContent="space-between"
      >
        <Typography variant="h4" fontWeight="bold">
          Income Sources
        </Typography>

        {hasPermissions("update:credit-evaluations") && (
          <Button
            sx={{
              ml: "auto",
            }}
            variant="contained"
            onClick={() => handleOpenAddIncome()}
          >
            <AddIcon sx={{ mr: 0.5 }} />
            <FormattedMessage id="CREDIT_EVALUATIONS.INCOMES.ADD" />
          </Button>
        )}
      </Stack>
      <IncomesTable
        creditEvaluationId={creditEvaluationId}
        data={creditEvaluation?.incomes || []}
        status={creditEvaluationStatus}
      />
      <Grid container spacing={2}>
        <Grid item xs={6}></Grid>
        <Grid item xs={6}>
          <Typography variant="h4" fontWeight="bold" mb={2} mt={5}>
            <FormattedMessage id="CREDIT_EVALUATIONS.INCOMES_SUMMARY.TITLE" />
          </Typography>

          <SummaryOfIncomesTable
            data={creditEvaluation?.summaryOfIncomes}
            status={creditEvaluationStatus}
            creditEvaluationId={creditEvaluationId}
          />
        </Grid>
      </Grid>
      {hasPermissions("update:credit-evaluations") && (
        <AddIncomeModal
          creditEvaluationId={creditEvaluationId}
          isOpen={isOpenAddIncome}
          handleClose={handleCloseAddIncome}
        />
      )}
      {hasPermissions("update:credit-evaluations") && (
        <NewLoanApplicationModal
          isOpen={isOpenAddLoanApplication}
          handleClose={handleCloseAddLoanApplication}
          creditEvaluationId={creditEvaluationId}
        />
      )}
      {hasPermissions("update:credit-evaluations") && (
        <>
          <DeleteCreditEvaluationModal
            isOpen={isOpenDelete}
            handleClose={handleCloseDelete}
            creditEvaluation={deleteContext}
          />
        </>
      )}
    </>
  );
};

export default SinlgeCreditEvaluation;
