import { Add as AddIcon } from "@mui/icons-material";
import { Dialog, DialogTitle, Divider, Stack } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

import { editLoanApplication as editLoanApplicationAPI } from "api/loanApplications";
import { loanApplicationsKeys } from "api/loanApplications/queries";
import {
  EditLoanApplicationPayloadType,
  LoanApplicationsType,
} from "api/loanApplications/types";

import LoanApplicationForm, {
  LoanApplicationFormValues,
} from "./LoanApplicationForm";

interface EditLoanApplicationModalProps {
  isOpen: boolean;
  handleClose: () => void;
  loanApplication: LoanApplicationsType | undefined;
}

const EditLoanApplicationModal: FC<EditLoanApplicationModalProps> = ({
  isOpen,
  handleClose,
  loanApplication,
}) => {
  const queryCache = useQueryClient();

  const initialValues: LoanApplicationFormValues = {
    _id: loanApplication?._id || "",
    lenderId: loanApplication?.lenderId || "",
    loanAmount: loanApplication?.loanAmount || null,
    monthlyPayment: loanApplication?.monthlyPayment || null,
    term: loanApplication?.term || null,
    creditInquiry: loanApplication?.creditInquiry || [],
    applicationDate: loanApplication?.applicationDate || null,
    status: loanApplication?.status || null,
    accountType: loanApplication?.accountType || "",
    interestRate: loanApplication?.interestRate || null,
    originationFee: loanApplication?.originationFee || null,
    reasonCode: loanApplication?.reasonCode || "",
  };

  const { mutateAsync: editLoanApplication, status } = useMutation(
    async (data: EditLoanApplicationPayloadType) => {
      const res = await editLoanApplicationAPI(data);
      await queryCache.invalidateQueries(loanApplicationsKeys.list());
      return res.data;
    },
  );

  const handleSubmit = async (values: LoanApplicationFormValues) => {
    const mappedValues: any = {
      ...values,
      creditEvaluation: loanApplication?.creditEvaluation,
    };

    await editLoanApplication(mappedValues, {
      onSuccess: () => handleClose(),
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm">
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <AddIcon sx={{ mr: 1 }} />
          <FormattedMessage id="LOAN_APPLICATIONS.EDIT" />
        </Stack>
      </DialogTitle>
      <Divider />

      <LoanApplicationForm
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        submitStatus={status}
        initialValues={initialValues}
      />
    </Dialog>
  );
};

export default EditLoanApplicationModal;
