import { createBrowserHistory } from "history";
import React from "react";
import { pdfjs } from "react-pdf";
import { Redirect, Switch } from "react-router-dom";

import CreditEvaluationsPage from "containers/creditEvaluations";
import SingleCreditReport from "containers/creditEvaluations/details";
import CustomersPage from "containers/customers";
import SingleCustomerPage from "containers/customers/details";
import FilePage from "containers/files";
import ForgotPasswordPage from "containers/forgotPassword";
import LoanPackagesPage from "containers/loanPackages";
import LoginPage from "containers/login";
import OrganisationPage from "containers/organisation";
import SingleOrganisationPage from "containers/organisation/details";
import RegisterPage from "containers/register";
import UserProfile from "containers/user/profile";
import WebhookLogs from "containers/user/webhookLogs/WebhookLogs";
import UsersPage from "containers/users";

import AuthRoute from "./components/routes/AuthRoute";
import PrivateRoute from "./components/routes/PrivateRoute";
import Providers from "./Providers";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

export const history = createBrowserHistory();

export default function App() {
  return (
    <Providers history={history}>
      <Switch>
        <AuthRoute exact component={LoginPage} path="/login" />
        <AuthRoute
          exact
          component={ForgotPasswordPage}
          path="/forgot-password"
        />
        <AuthRoute exact component={RegisterPage} path="/register/:id" />

        <PrivateRoute
          exact
          path="/credit-evaluations"
          component={CreditEvaluationsPage}
          breadcrumbs={[
            {
              translationId: "HEADER.CREDIT_EVALUATIONS",
              pathname: "/credit-evaluations",
            },
          ]}
          requiredPermissions={["read:credit-evaluations"]}
        />
        <PrivateRoute
          exact
          path="/credit-evaluations/:creditEvaluationId"
          component={SingleCreditReport}
          breadcrumbs={[
            {
              translationId: "HEADER.CREDIT_EVALUATIONS",
              pathname: "/credit-evaluations",
            },
          ]}
          requiredPermissions={["read:credit-evaluations"]}
        />
        <PrivateRoute
          exact
          path="/users"
          component={UsersPage}
          breadcrumbs={[{ translationId: "HEADER.USERS", pathname: "/users" }]}
          requiredPermissions={["read:users"]}
        />
        <PrivateRoute
          exact
          path="/organisations"
          component={OrganisationPage}
          breadcrumbs={[
            {
              translationId: "HEADER.ORGANISATIONS",
              pathname: "/organisations",
            },
          ]}
          requiredPermissions={["read:organisations"]}
        />
        <PrivateRoute
          exact
          path="/organisations/:organisationId"
          component={SingleOrganisationPage}
          breadcrumbs={[
            {
              translationId: "HEADER.ORGANISATIONS",
              pathname: "/organisations",
            },
          ]}
          requiredPermissions={["read:organisations"]}
        />
        <PrivateRoute
          exact
          path="/customers"
          component={CustomersPage}
          breadcrumbs={[
            { translationId: "HEADER.CUSTOMERS", pathname: "/customers" },
          ]}
          requiredPermissions={["read:customers"]}
        />
        <PrivateRoute
          exact
          path="/customers/:customerId"
          component={SingleCustomerPage}
          breadcrumbs={[
            { translationId: "HEADER.CUSTOMERS", pathname: "/customers" },
          ]}
          requiredPermissions={["read:customers"]}
        />
        <PrivateRoute
          exact
          path="/loan-packages"
          component={LoanPackagesPage}
          breadcrumbs={[
            {
              translationId: "HEADER.LOAN_PACKAGES",
              pathname: "/loan-packages",
            },
          ]}
          requiredPermissions={["read:loan-packages"]}
        />
        <PrivateRoute
          exact
          path="/user/profile"
          component={UserProfile}
          breadcrumbs={[
            {
              translationId: "HEADER.PROFILE",
              pathname: "/user/profile",
            },
          ]}
        />
        <PrivateRoute
          exact
          path="/webhook-logs"
          component={WebhookLogs}
          breadcrumbs={[
            {
              translationId: "HEADER.WEBHOOK_LOGS",
              pathname: "/webhook-logs",
            },
          ]}
          requiredPermissions={["read:logs"]}
        />
        <PrivateRoute
          exact
          path="/file/:fileId"
          component={FilePage}
          breadcrumbs={[
            {
              translationId: "HEADER.FILE",
              pathname: "/webhook-logs",
            },
          ]}
        />

        <Redirect to="/credit-evaluations" />
      </Switch>
    </Providers>
  );
}
