import { Home as HomeIcon, Menu as MenuIcon } from "@mui/icons-material";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  LinearProgress,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { useIsFetching } from "@tanstack/react-query";
import React, { Fragment, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

import useBreadcrumbStore from "components/stores/BreadcrumbStore";
import { useUserStore } from "components/stores/UserStore";
import UserAvatar from "components/UserAvatar/UserAvatar";
import { userInitials } from "utils/userInitials/userInitials";

import UserDrawer from "../UserDrawer";

interface TopbarProps {
  isMobile: boolean;
  toggleMobileDrawer: () => void;
  showBreadcrumbs: boolean;
}

const Topbar = ({
  isMobile,
  toggleMobileDrawer,
  showBreadcrumbs,
}: TopbarProps) => {
  const isFetching = useIsFetching();

  const { breadcrumbs } = useBreadcrumbStore((s) => ({
    breadcrumbs: s.breadcrumbs,
  }));

  const [isUserDrawerOpen, setIsUserDrawerOpen] = useState(false);
  const toggleUserDrawer = () => setIsUserDrawerOpen((prev) => !prev);

  const [user] = useUserStore((s) => [s.user]);

  const userName = user?.name;

  const generatedUserInitials = userInitials(userName);

  return (
    <AppBar
      color="inherit"
      position="sticky"
      elevation={0}
      sx={{
        borderTop: "none",
        borderRight: "none",
        borderLeft: "none",
        background: "#F6F8FB",
        borderBottom: "1px solid #DBDDE0",
        borderRadius: 0,
      }}
    >
      <Toolbar disableGutters>
        {isMobile && (
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ ml: 1 }}
            onClick={toggleMobileDrawer}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Box
          sx={{
            ml: isMobile ? 1 : 3,
            mr: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              color: (theme) => theme.palette.text.primary,
            }}
          >
            <Link
              to="/credit-evaluations"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <HomeIcon />
            </Link>
            <Typography mx={1}>/</Typography>
            {showBreadcrumbs &&
              breadcrumbs.map((breadcrumb, i, arr) => {
                return (
                  <Fragment key={i}>
                    <Link
                      to={breadcrumb.pathname}
                      style={{
                        textDecoration: "none",
                        textTransform: "uppercase",
                      }}
                    >
                      <Typography
                        component="div"
                        sx={{
                          color: (theme) => theme.palette.text.primary,
                          ":hover": {
                            color: (theme) => theme.palette.primary.main,
                          },
                        }}
                        fontSize={14}
                      >
                        {breadcrumb.translationId ? (
                          <FormattedMessage id={breadcrumb.translationId} />
                        ) : (
                          breadcrumb.title
                        )}
                      </Typography>
                    </Link>
                    {i < arr.length - 1 && (
                      <Typography
                        sx={{
                          mx: 0.5,
                          color: (theme) => theme.palette.grey[600],
                        }}
                        // variant="subtitle1"
                        component="span"
                      >
                        &#8226;
                      </Typography>
                    )}
                  </Fragment>
                );
              })}
          </Box>

          <Stack direction="row" alignItems="center">
            {/* {!isMobile && <LanguageSwitcher />} */}
            <Box>
              <Button
                sx={{
                  boxShaodw: "none",
                  textTransform: "none",
                  px: 2,
                  py: 1,
                  borderRadius: 0,

                  color: (theme) => theme.palette.grey[800],
                  ":hover": {
                    backgroundColor: (theme) => theme.palette.grey[200],
                  },
                }}
                onClick={toggleUserDrawer}
              >
                <Stack direction="row" alignItems="center" spacing={2}>
                  {!isMobile && (
                    <Box>
                      <Typography
                        sx={{
                          color: (theme) => theme.palette.text.secondary,
                        }}
                      >
                        {userName}
                      </Typography>
                    </Box>
                  )}
                  <UserAvatar
                    userName={userName || ""}
                    size={46}
                    fontSize={Math.min(35, 40 / generatedUserInitials.length)}
                  />
                </Stack>
              </Button>
              <UserDrawer
                isOpen={isUserDrawerOpen}
                onClose={toggleUserDrawer}
              />
            </Box>
          </Stack>
        </Box>
      </Toolbar>
      {isFetching ? <LinearProgress color="primary" /> : <Box height="4px" />}
    </AppBar>
  );
};

export default Topbar;
