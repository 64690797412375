import { Box, Paper } from "@mui/material";
import { QueryStatus } from "@tanstack/react-query";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { CellProps, Column } from "react-table";

import { CreditEvaluationIncomeSource } from "api/creditEvaluations/types";
import Table from "components/Table";
import { dateFormatter } from "components/Table/formatters/dateFormatter";
import { priceFormatter } from "components/Table/formatters/priceFormatter";

interface Props {
  data: CreditEvaluationIncomeSource[];
  status: QueryStatus;
}
const IncomesRetirementTable: FC<Props> = ({ data, status }) => {
  const intl = useIntl();

  const columns: Column<CreditEvaluationIncomeSource>[] = [
    {
      id: "#",
      Header: intl.formatMessage({
        id: "CREDIT_EVALUATIONS.INCOMES.TABLE.ID",
      }),

      Cell: ({ row }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{"#" + (row.index + 1)}</>
      ),
    },
    {
      accessor: "date",
      Header: "Income Start Date",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{dateFormatter(value)}</>
      ),
    },
    {
      accessor: "source",
      Header: "Source",
    },
    {
      accessor: "monthlyBenefit",
      Header: "Monthly Benefit",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{priceFormatter(value)}</>
      ),
    },
    {
      accessor: "previousIncomes",
      Header: "Actual Income",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>
          {value.map((previousIncome: { year: number; yearIncome: number }) => (
            <Box>
              {previousIncome.year}: {priceFormatter(previousIncome.yearIncome)}
            </Box>
          ))}
        </>
      ),
    },
  ];

  return (
    <Paper>
      <Table data={data} columns={columns} status={status} manualPagination />
    </Paper>
  );
};

export default IncomesRetirementTable;
