import { Delete as DeleteIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Stack,
} from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { FormattedMessage } from "react-intl";

// import { deleteIncome as deleteIncomeAPI } from "api/customers";
import { deleteCreditEvaluationIncome } from "api/creditEvaluations";
import { creditEvaluationsKeys } from "api/creditEvaluations/queries";
import { CreditEvaluationIncome } from "api/creditEvaluations/types";

import { incomeTypeFormatter } from "./IncomesTable/formatters/incomeTypeFormatter";

interface DeleteIncomeModalProps {
  isOpen: boolean;
  handleClose: () => void;
  creditEvaluationId: string;
  income: CreditEvaluationIncome | undefined;
}

const DeleteIncomeModal = ({
  isOpen,
  handleClose,
  creditEvaluationId,
  income,
}: DeleteIncomeModalProps) => {
  const queryClient = useQueryClient();

  const { mutateAsync: deleteIncome, status } = useMutation(
    async (incomeId: string) => {
      const res = await deleteCreditEvaluationIncome(
        creditEvaluationId,
        incomeId,
      );
      await queryClient.invalidateQueries(
        creditEvaluationsKeys.details(creditEvaluationId),
      );

      return res.data;
    },
  );

  const handleDelete = async () => {
    await deleteIncome(income!._id, {
      onSuccess: () => handleClose(),
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm">
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <DeleteIcon sx={{ mr: 1 }} />
          <FormattedMessage id="INCOMES.DELETE.TITLE" />
        </Stack>
      </DialogTitle>

      <Divider />

      <DialogContent>
        <FormattedMessage
          id="INCOMES.DELETE.WARNING"
          values={{
            type: <b>{incomeTypeFormatter(income)}</b>,
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>
          <FormattedMessage
            // data-testid="delete-port-close-button"
            id="GLOBAL.CANCEL"
          />
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          onClick={handleDelete}
          loading={status === "loading"}
        >
          <FormattedMessage id="GLOBAL.DELETE" />
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteIncomeModal;
