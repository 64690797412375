import { MenuItem, Select, Typography } from "@mui/material";
import React, { FC } from "react";
import { CellProps } from "react-table";

import {
  LoanApplicationStatus,
  LoanApplicationsType,
} from "api/loanApplications/types";
import { hasPermissions } from "components/stores/UserStore";

import { loanStatusFormatter } from "./loanStatusFormatter.tsx";

interface LoanStatusPickerProps extends CellProps<LoanApplicationsType> {
  onChange: ({
    creditEvaluationId,
    status,
  }: {
    creditEvaluationId: string;
    status: LoanApplicationStatus;
  }) => void;
}

const LoanStatusPicker: FC<LoanStatusPickerProps> = ({
  value,
  row,
  onChange,
}) => {
  return (
    <>
      {hasPermissions("update:credit-evaluations") ? (
        <Select
          variant="standard"
          value={value || ""}
          displayEmpty
          renderValue={() => (
            <Typography variant="body2" display="inline">
              {loanStatusFormatter(value)}
            </Typography>
          )}
          onChange={(event) => {
            const selected = event.target.value;

            onChange({
              creditEvaluationId: row.original._id,
              status: selected,
            });
          }}
          sx={{
            "&::before, &::after": {
              display: "none",
            },
            backgroundColor: "transparent !important",
            // ...sx
          }}
        >
          {Object.values(LoanApplicationStatus).map((option) => (
            <MenuItem key={option} value={option}>
              {loanStatusFormatter(option)}
            </MenuItem>
          ))}
        </Select>
      ) : (
        loanStatusFormatter(value)
      )}
    </>
  );
};

export default LoanStatusPicker;
