import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent } from "@mui/material";
import { MutationStatus } from "@tanstack/react-query";
import { Form as FormikForm, FormikProvider, useFormik } from "formik";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";

import FormikTextField from "components/forms/FormikTextField";
import MaskSocialInput from "components/forms/FormikTextField/MaskSocialInput";

export type AddSocialNumberFormValues = {
  social: string;
};

interface IAddSocialNumberForm {
  handleSubmit: (values: AddSocialNumberFormValues) => void;
  handleClose: () => void;
  initialValues?: AddSocialNumberFormValues;
  submitStatus?: MutationStatus;
}

const AddSocialNumberForm: FC<IAddSocialNumberForm> = ({
  handleClose,
  handleSubmit,
  initialValues = {
    social: "",
  },
  submitStatus,
}) => {
  const schema = yup.object({
    social: yup.string().required().nullable(),
  });

  const formik = useFormik({
    // enableReinitialize: true,
    initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  return (
    <FormikProvider value={formik}>
      <FormikForm>
        <DialogContent>
          <FormikTextField
            name="social"
            label="Social Number"
            InputProps={{
              inputComponent: MaskSocialInput as any,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            // data-testid="new-edit-port-close-button"
            onClick={handleClose}
          >
            <FormattedMessage id="GLOBAL.CLOSE" />
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={submitStatus === "loading"}
          >
            Save & Refetch
          </LoadingButton>
        </DialogActions>
      </FormikForm>
    </FormikProvider>
  );
};

export default AddSocialNumberForm;
