const loanApplicationsKeys = {
  all: ["loan-applications"] as const,
  list: () => [...loanApplicationsKeys.all, "list"],
  listFiltered: (filters: Record<string, unknown>) => [
    ...loanApplicationsKeys.list(),
    filters,
  ],
  details: (id: string) => [...loanApplicationsKeys.all, "detail", id] as const,
};

export { loanApplicationsKeys };
