import { Checkbox } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { FC } from "react";
import { Row } from "react-table";

import { editCreditEvaluationHouseholdIncome } from "api/creditEvaluations";
import { creditEvaluationsKeys } from "api/creditEvaluations/queries";
import {
  CreditEvaluationIncomeOverview,
  CreditEvaluationIncomeOverviewEnum,
} from "api/creditEvaluations/types";
import { hasPermissions } from "components/stores/UserStore";

interface IHouseholdIncomeFormatter {
  row: Row<CreditEvaluationIncomeOverview>;
  creditEvaluationId: string;
  householdIncome: CreditEvaluationIncomeOverviewEnum | undefined;
}
const HouseholdIncomeFormatter: FC<IHouseholdIncomeFormatter> = ({
  row,
  creditEvaluationId,
  householdIncome,
}) => {
  const queryClient = useQueryClient();

  const { mutateAsync: selectHouseholdIncome, isLoading } = useMutation(
    async () => {
      const res = await editCreditEvaluationHouseholdIncome(
        creditEvaluationId,
        row.original.type,
      );

      await queryClient.invalidateQueries(
        creditEvaluationsKeys.details(creditEvaluationId),
      );
      return res.data;
    },
  );

  const handleChange = async () => {
    await selectHouseholdIncome();
  };

  if (
    row.original.type === CreditEvaluationIncomeOverviewEnum.HOUSEHOLD_INCOME
  ) {
    return null;
  }
  return (
    <Checkbox
      disabled={isLoading || !hasPermissions("update:credit-evaluations")}
      checked={householdIncome === row.original.type}
      onChange={handleChange}
    />
  );
};

export default HouseholdIncomeFormatter;
