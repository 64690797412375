import { Paginated, QueryParamsType, normalizeQueryParams } from "api/utils";

import request, { APICall } from "../request";
import { LogsType } from "./types";

export const getLogs = (
  queryParams?: QueryParamsType<LogsType>,
): APICall<Paginated<LogsType[]>> =>
  request({
    url: `/log${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });
