import { Paper } from "@mui/material";
import { QueryStatus } from "@tanstack/react-query";
import { FC } from "react";
import { CellProps, Column } from "react-table";

import {
  CreditEvaluationIncomeOverview,
  CreditEvaluationIncomeOverviewEnum,
} from "api/creditEvaluations/types";
import Table from "components/Table";
import { precisePercentageFormatter } from "components/Table/formatters/percentageFormatter";
import { priceFormatter } from "components/Table/formatters/priceFormatter";

import { incomeOverviewTypeFormatter } from "../../Income/IncomesTable/formatters/incomeOverviewTypeFormatter";
import HouseholdIncomeFormatter from "./formatters/HouseholdIncomeFormatter";

export const incomeOverviewSort = (
  incomeOverviewList: CreditEvaluationIncomeOverview[],
) => {
  const incomeOverviewPriority = {
    [CreditEvaluationIncomeOverviewEnum.STATED_INCOME]: 0,
    [CreditEvaluationIncomeOverviewEnum.INDIVIDUAL_INCOME_CURRENT_YEAR]: 1,
    [CreditEvaluationIncomeOverviewEnum.INDIVIDUAL_INCOME_PRIOR_YEAR]: 2,
    [CreditEvaluationIncomeOverviewEnum.INDIVIDUAL_INCOME_2_YEAR_AVERAGE]: 3,
    [CreditEvaluationIncomeOverviewEnum.INDIVIDUAL_INCOME_3_YEAR_AVERAGE]: 4,
    [CreditEvaluationIncomeOverviewEnum.INDIVIDUAL_INCOME_STUDENT_LOAN_ADJUSTED]:
      -1,
    [CreditEvaluationIncomeOverviewEnum.INDIVIDUAL_INCOME_RENT_ADJUSTED]: -1,
    [CreditEvaluationIncomeOverviewEnum.INDIVIDUAL_INCOME_HALF_MORTGAGE]: -1,
    [CreditEvaluationIncomeOverviewEnum.HOUSEHOLD_INCOME]: 8,
  };

  return incomeOverviewList
    .filter((income) => incomeOverviewPriority[income.type] !== -1)
    .sort(
      (aIncome, bIncome) =>
        incomeOverviewPriority[aIncome.type] -
        incomeOverviewPriority[bIncome.type],
    );
};

interface IIncomeOverviewTable {
  data: CreditEvaluationIncomeOverview[];
  status: QueryStatus;
  creditEvaluationId: string;
  householdIncome: CreditEvaluationIncomeOverviewEnum | undefined;
}

const IncomeOverviewTable: FC<IIncomeOverviewTable> = ({
  data,
  status,
  creditEvaluationId,
  householdIncome,
}) => {
  const columns: Column<CreditEvaluationIncomeOverview>[] = [
    {
      id: "household",
      Header: "Household",
      Cell: ({ row }: CellProps<CreditEvaluationIncomeOverview>) => (
        <HouseholdIncomeFormatter
          row={row}
          creditEvaluationId={creditEvaluationId}
          householdIncome={householdIncome}
        />
      ),
    },
    {
      accessor: "type",
      Header: "#",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeOverview>) =>
        incomeOverviewTypeFormatter(value),
    },

    {
      accessor: "monthly",
      Header: "Monthly",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeOverview>) =>
        priceFormatter(value),
    },
    {
      accessor: "annual",
      Header: "Annual",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeOverview>) =>
        priceFormatter(value),
    },
    {
      accessor: "dti",
      Header: "DTI",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeOverview>) =>
        precisePercentageFormatter(value, 100),
    },
  ];

  return (
    <>
      <Paper>
        <Table
          data={incomeOverviewSort(data || [])}
          columns={columns}
          status={status}
          manualPagination
          pagination={{
            remote: {
              pageCount: 1,
              initialPageSize: 3,
              onPageChange: () => {},
            },
          }}
        />
      </Paper>
    </>
  );
};

export default IncomeOverviewTable;
