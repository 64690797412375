import { Box } from "@mui/material";

import { CreditEvaluationAffordabilityEnum } from "api/creditEvaluations/types";

export const AffordabilityColors = {
  [CreditEvaluationAffordabilityEnum.PENDING_EVAL]: "#F8BB32",
  [CreditEvaluationAffordabilityEnum.LOW]: "#FF5C5C",
  [CreditEvaluationAffordabilityEnum.MEDIUM]: "#1781EC",
  [CreditEvaluationAffordabilityEnum.HIGH]: "#40AD87",
  [CreditEvaluationAffordabilityEnum.NONE]: "#000000",
};

export const AffordabilityLabel = {
  [CreditEvaluationAffordabilityEnum.PENDING_EVAL]: "Pending Eval",
  [CreditEvaluationAffordabilityEnum.LOW]: "Low",
  [CreditEvaluationAffordabilityEnum.MEDIUM]: "Medium",
  [CreditEvaluationAffordabilityEnum.HIGH]: "High",
  [CreditEvaluationAffordabilityEnum.NONE]: "None",
};

export const AffordabilityFormatter = ({
  value,
}: {
  value: CreditEvaluationAffordabilityEnum | undefined;
}) => {
  if (!value) return null;

  return (
    <Box color={AffordabilityColors[value]} fontWeight="bold">
      {AffordabilityLabel[value]}
    </Box>
  );
};
