import { Paginated, QueryParamsType, normalizeQueryParams } from "api/utils";

import request, { APICall } from "../request";
import {
  EditLoanPackageType,
  LoanPackagesType,
  NewLoanPackagePayloadType,
} from "./types";

export const getLoanPackages = (
  queryParams?: QueryParamsType<LoanPackagesType>,
): APICall<Paginated<LoanPackagesType[]>> =>
  request({
    url: `/loan-package${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });

export const addLoanPackage = (
  loanPackage: NewLoanPackagePayloadType,
): APICall<LoanPackagesType> =>
  request({
    data: loanPackage,
    url: "/loan-package",
    method: "POST",
  });

export const editLoanPackage = (
  loanPackage: EditLoanPackageType,
): APICall<LoanPackagesType> =>
  request({
    data: loanPackage,
    url: `/loan-package/${loanPackage._id}`,
    method: "PUT",
  });

export const deleteLoanPackage = (loanPackageId: string): APICall<null> =>
  request({
    url: `/loan-package/${loanPackageId}`,
    method: "DELETE",
  });
