import { Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";

import OrganisationsTable from "./OrganisationsTable";

const OrganisationPage = () => {
  const intl = useIntl();

  return (
    <>
      <Helmet title={intl.formatMessage({ id: "ORGANISATIONS.TITLE" })} />

      <Typography variant="h2" fontWeight="bold" mt={1}>
        <FormattedMessage id="ORGANISATION.TABLE.TITLE" />
      </Typography>
      <OrganisationsTable />
    </>
  );
};

export default OrganisationPage;
