import { formatNumber } from "utils/formatNumber";

export const priceFormatter = (
  value: number | null | undefined,
  numberOfDecimals: number = 2,
) => {
  if (value !== null && value !== undefined && !Number.isNaN(value)) {
    return formatNumber(value, {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: numberOfDecimals,
      maximumFractionDigits: numberOfDecimals,
    });
  }
  return "-";
};
