import { CreditEvaluationLoanAffordabilityEnum } from "api/creditEvaluations/types";

export const loanAffordabilityFormatter = (
  value: CreditEvaluationLoanAffordabilityEnum | undefined | null,
) => {
  switch (value) {
    case CreditEvaluationLoanAffordabilityEnum.SELECTED_INCOME:
      return "Selected Income";
    case CreditEvaluationLoanAffordabilityEnum.AFFORDABILITY_INCLUDING_STUDENT_LOAN_DEBT:
      return "Affordability Including Student Loan Debt";
    case CreditEvaluationLoanAffordabilityEnum.AFFORDABILITY_INCLUDING_RENT:
      return "Affordability Including Rent";
    case CreditEvaluationLoanAffordabilityEnum.AFFORDABILITY_INCLUDING_RENT_AND_DEFERRED_STUDENT_LOANS:
      return "Affordability Including Rent & Student Loan Debt";
    case CreditEvaluationLoanAffordabilityEnum.AFFRODABILITY_HALF_MORTAGE:
      return "Affordability Half Mortgage";
    case CreditEvaluationLoanAffordabilityEnum.HOUSEHOLD_INCOME:
      return "Household Income";
    case CreditEvaluationLoanAffordabilityEnum.HOUSEHOLD_AFFORDABILITY_INCLUDING_STUDENT_LOAN_DEBT:
      return "Household Including Student Loan Debt";
    case CreditEvaluationLoanAffordabilityEnum.HOUSEHOLD_AFFORDABILITY_INCLUDING_RENT:
      return "Household Including Rent";
    case CreditEvaluationLoanAffordabilityEnum.HOUSEHOLD_AFFORDABILITY_INCLUDING_RENT_AND_DEFERRED_STUDENT_LOANS:
      return "Household Including Rent & Student Loan Debt";
    default:
      return "Income";
  }
};
