import { Paper } from "@mui/material";
import { QueryStatus } from "@tanstack/react-query";
import { FC } from "react";
// import { useIntl } from "react-intl";
import { CellProps, Column } from "react-table";

import { CreditEvaluationPublicRecords } from "api/creditEvaluations/types";
import Table from "components/Table";
import { dateFormatter } from "components/Table/formatters/dateFormatter";
import { priceFormatter } from "components/Table/formatters/priceFormatter";

interface IPublicRecordsTable {
  data: CreditEvaluationPublicRecords[];
  status: QueryStatus;
}

const PublicRecordsTable: FC<IPublicRecordsTable> = ({ data, status }) => {
  //   const intl = useIntl();

  const columns: Column<CreditEvaluationPublicRecords>[] = [
    {
      accessor: "courtNumber",
      Header: "Court Number",
    },
    {
      accessor: "memberNumber",
      Header: "Member Number",
    },
    {
      accessor: "amount",
      Header: "Amount",
      Cell: ({ value }: CellProps<CreditEvaluationPublicRecords>) =>
        priceFormatter(value),
    },
    {
      accessor: "recordType",
      Header: "Record Type",
    },
    {
      accessor: "datePaid",
      Header: "Date Paid",
      Cell: ({ value }: CellProps<CreditEvaluationPublicRecords>) =>
        dateFormatter(value),
    },
    {
      accessor: "plaintiff",
      Header: "Plaintiff",
    },
    {
      accessor: "assets",
      Header: "Assets",
    },
    {
      accessor: "courtType",
      Header: "Court Type",
    },
    {
      accessor: "accDesignator",
      Header: "Account Designator",
    },
    {
      accessor: "attorney",
      Header: "Attorney",
    },
    {
      accessor: "liability",
      Header: "Liability",
      Cell: ({ value }: CellProps<CreditEvaluationPublicRecords>) =>
        priceFormatter(value),
    },
    {
      accessor: "publicRecordDisposition",
      Header: "Public Record Disposition",
    },
    {
      accessor: "docket",
      Header: "Docket",
    },
    {
      accessor: "industry",
      Header: "Industry",
    },
    {
      accessor: "origDate",
      Header: "Original Date",
      Cell: ({ value }: CellProps<CreditEvaluationPublicRecords>) =>
        dateFormatter(value),
    },

    {
      accessor: "dateReported",
      Header: "Report Date",
      Cell: ({ value }: CellProps<CreditEvaluationPublicRecords>) =>
        dateFormatter(value),
    },
  ];

  return (
    <Paper>
      <Table
        data={data}
        columns={columns}
        status={status}
        initialState={{
          sortBy: [
            {
              id: "dateReported",
              desc: false,
            },
          ],
        }}
        manualPagination
      />
    </Paper>
  );
};

export default PublicRecordsTable;
