import { Paginated, QueryParamsType, normalizeQueryParams } from "api/utils";

import request, { APICall } from "../request";
import {
  CustomersType,
  EditCustomerType,
  NewCustomerPayloadType,
} from "./types";

export const getCustomers = (
  queryParams?: QueryParamsType<CustomersType>,
): APICall<Paginated<CustomersType[]>> =>
  request({
    url: `/customer${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });

export const getSingleCustomer = (
  customerId: string,
): APICall<{ data: CustomersType }> =>
  request({
    url: `/customer/${customerId}`,
    method: "GET",
  });

export const getHubspotCustomer = (
  email: string,
): APICall<{ data: CustomersType }> =>
  request({
    url: `/customer/hubspot?email=${email}`,
    method: "GET",
  });

export const addCustomer = (
  customer: NewCustomerPayloadType,
): APICall<NewCustomerPayloadType> =>
  request({
    data: customer,
    url: "/customer",
    method: "POST",
  });

export const editCustomer = (
  customer: EditCustomerType,
): APICall<EditCustomerType> =>
  request({
    data: customer,
    url: `/customer/${customer._id}`,
    method: "PUT",
  });

export const deleteCustomer = (customerId: string): APICall<null> =>
  request({
    url: `/customer/${customerId}`,
    method: "DELETE",
  });

export const saveAndRefetchCustomer = (
  customerId: string,
  customer: EditCustomerType,
): APICall<CustomersType> =>
  request({
    data: customer,
    url: `/customer/refetch/${customerId}`,
    method: "PUT",
  });

export const updateCustomerSpouse = (
  spouseId: string,
  customerId: string,
): APICall<EditCustomerType> =>
  request({
    data: { spouse: spouseId },
    url: `/customer/spouse/${customerId}`,
    method: "PUT",
  });

export const deleteCustomerSpouse = (
  customerId: string,
): APICall<EditCustomerType> =>
  request({
    url: `/customer/spouse/${customerId}`,
    method: "DELETE",
  });
