import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import WebhookLogsTable from "./WebhookLogsTable";

const WebhookLogs = () => {
  return (
    <>
      <Typography variant="h2" fontWeight="bold" mt={1}>
        <FormattedMessage id="WEBHOOK_LOGS.TABLE.TITLE" />
      </Typography>

      <WebhookLogsTable />
    </>
  );
};

export default WebhookLogs;
