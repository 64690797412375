import { Add as AddIcon } from "@mui/icons-material";
import { Dialog, DialogTitle, Divider, Stack } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

import { addLoanApplication as addLoanApplicationAPI } from "api/loanApplications";
import { loanApplicationsKeys } from "api/loanApplications/queries";
import { NewLoanApplicationPayloadType } from "api/loanApplications/types";

import AddLoanApplicationForm, {
  LoanApplicationFormValues,
} from "./LoanApplicationForm";

interface NewLoanApplicationModalProps {
  isOpen: boolean;
  handleClose: () => void;
  creditEvaluationId: string;
}

const NewLoanApplicationModal: FC<NewLoanApplicationModalProps> = ({
  isOpen,
  handleClose,
  creditEvaluationId,
}) => {
  const queryCache = useQueryClient();

  const { mutateAsync: addLoanApplication, status } = useMutation(
    async (data: NewLoanApplicationPayloadType) => {
      const res = await addLoanApplicationAPI(data);
      await queryCache.invalidateQueries(loanApplicationsKeys.list());
      return res.data;
    },
  );

  const handleSubmit = async (values: LoanApplicationFormValues) => {
    const mappedValues: any = {
      ...values,
      creditEvaluation: creditEvaluationId,
    };

    await addLoanApplication(mappedValues, {
      onSuccess: () => handleClose(),
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm">
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <AddIcon sx={{ mr: 1 }} />
          <FormattedMessage id="LOAN_APPLICATIONS.ADD" />
        </Stack>
      </DialogTitle>
      <Divider />

      <AddLoanApplicationForm
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        submitStatus={status}
      />
    </Dialog>
  );
};

export default NewLoanApplicationModal;
