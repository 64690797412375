import { Add as AddIcon, Search as SearchIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Cell, Column } from "react-table";

import { getCustomers } from "api/customers";
import { customersKeys } from "api/customers/queries";
import { CustomersType } from "api/customers/types";
import { hasPermissions } from "components/stores/UserStore";
import Table from "components/Table";
import useRemoteTableLogic from "components/Table/useRemoteTableLogic";
import UserAvatar from "components/UserAvatar/UserAvatar";
import { useModal } from "utils/hooks/useModal";

import DeleteCustomerModal from "./components/DeleteCustomerModal";
import EditCustomerModal from "./components/EditCustomerModal";
import NewCustomerModal from "./components/NewCustomerModal";
import CustomersActionsFormatter from "./formatters/CustomersActionsFormatter";
import { useCustomersFilter } from "./useCustomersFilter";

const CustomersTable: FC = () => {
  const intl = useIntl();

  const { filters, searchField } = useCustomersFilter();

  const { data, status, sortOptions, paginationOptions } = useRemoteTableLogic(
    customersKeys.list(),
    getCustomers,
    filters,
  );

  const { isOpen, handleClose, handleOpen } = useModal<CustomersType>();
  const {
    isOpen: isOpenDelete,
    handleClose: handleCloseDelete,
    handleOpen: handleOpenDelete,
    context: deleteContext,
  } = useModal<CustomersType>();
  const {
    isOpen: isEditOpen,
    handleClose: handleCloseEdit,
    handleOpen: handleOpenEdit,
    context: editContext,
  } = useModal<CustomersType>();

  const columns: Column<CustomersType>[] = [
    {
      id: "name",
      Header: intl.formatMessage({ id: "GLOBAL.NAME" }),
      Cell: ({ row }: Cell<CustomersType>) => (
        <Stack direction="row" alignItems="center">
          <UserAvatar
            size={36}
            fontSize={16}
            userName={`${row.original.firstName} ${row.original.lastName}`}
          />
          <Typography ml={1}>
            {row.original.firstName} {row.original.lastName}
          </Typography>
        </Stack>
      ),
    },
    {
      accessor: "email",
      Header: intl.formatMessage({ id: "GLOBAL.EMAIL" }),
    },
    {
      accessor: "social",
      Header: intl.formatMessage({ id: "CUSTOMERS.SOCIAL" }),
    },
    {
      accessor: "address",
      Header: intl.formatMessage({ id: "GLOBAL.ADDRESS" }),
    },
    {
      accessor: "city",
      Header: intl.formatMessage({ id: "CUSTOMERS.CITY" }),
    },
    {
      accessor: "state",
      Header: intl.formatMessage({ id: "CUSTOMERS.STATE" }),
    },

    {
      id: "actions",
      Header: intl.formatMessage({ id: "GLOBAL.ACTIONS" }),
      Cell: (cell: Cell<CustomersType>) => (
        <CustomersActionsFormatter
          {...cell}
          handleOpenEdit={handleOpenEdit}
          handleOpenDelete={handleOpenDelete}
        />
      ),
      align: "right",
    },
  ];

  return (
    <>
      <Grid container sx={{ py: 2 }} spacing={2}>
        <Grid item xs={5} lg={3}>
          <TextField
            fullWidth
            label={<FormattedMessage id="GLOBAL.SEARCH" />}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            helperText={<FormattedMessage id="CUSTOMERS.SEARCH_HELPER" />}
            {...searchField}
          />
        </Grid>
        <Grid item xs={1} lg={5}></Grid>
        <Grid item xs={6} lg={4}>
          {hasPermissions("write:customers") && (
            <Box ml="auto" marginTop={1}>
              <Button
                sx={{
                  ml: "auto",
                  whiteSpace: "nowrap",
                }}
                variant="contained"
                onClick={() => handleOpen()}
              >
                <AddIcon sx={{ mr: 0.5 }} />
                Add Customer
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>

      <Paper>
        <Table
          data={data}
          columns={columns}
          sort={sortOptions}
          pagination={paginationOptions}
          status={status}
          getRowProps={(row) => {
            let backgroundColor;
            if (row.original.cbcErrorMessage) {
              backgroundColor = "#fff2f2";
            }

            return {
              sx: { backgroundColor },
            };
          }}
        />
      </Paper>
      {hasPermissions("write:customers") && (
        <>
          <NewCustomerModal isOpen={isOpen} handleClose={handleClose} />
        </>
      )}

      {hasPermissions("delete:customers") && (
        <>
          <DeleteCustomerModal
            isOpen={isOpenDelete}
            handleClose={handleCloseDelete}
            customer={deleteContext}
          />
        </>
      )}
      {hasPermissions("update:customers") && (
        <>
          <EditCustomerModal
            isOpen={isEditOpen}
            handleClose={handleCloseEdit}
            customer={editContext}
          />
        </>
      )}
    </>
  );
};

export default CustomersTable;
