import { Paper } from "@mui/material";
import {
  QueryStatus,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { FC } from "react";
import { useIntl } from "react-intl";
import { Cell, CellProps, Column } from "react-table";

import { editLoanApplicationStatus } from "api/loanApplications";
import { loanApplicationsKeys } from "api/loanApplications/queries";
import {
  LoanApplicationStatus,
  LoanApplicationsType,
} from "api/loanApplications/types";
import Table from "components/Table";
import { dateFormatter } from "components/Table/formatters/dateFormatter";
import { precisePercentageFormatter } from "components/Table/formatters/percentageFormatter";
import { priceFormatter } from "components/Table/formatters/priceFormatter";
import { useModal } from "utils/hooks/useModal";

import DeleteLoanApplicationModal from "./components/DeleteLoanApplicationModal";
import EditLoanApplicationModal from "./components/EditLoanApplicationModal";
import { loanAccountTypeFormatter } from "./formatters/loanAccountTypeFormatter";
import LoanApplicationsActionsFormatter from "./formatters/LoanApplicationsActionsFormatter";
import { loanHubspotFormatter } from "./formatters/loanHubspotFormatter";
import LoanStatusPicker from "./formatters/LoanStatusPicker";

interface ILoanApplicationsTable {
  data: LoanApplicationsType[];
  status: QueryStatus;
}

const LoanApplicationsTable: FC<ILoanApplicationsTable> = ({
  data,
  status,
}) => {
  const intl = useIntl();
  const queryClient = useQueryClient();

  const { mutateAsync: editStatus } = useMutation(async (data: any) => {
    const res = await editLoanApplicationStatus(
      data.creditEvaluationId,
      data.status,
    );
    await queryClient.invalidateQueries(loanApplicationsKeys.list());

    return res.data;
  });

  const handleStatusChange = async (values: any) => {
    const mappedValues: any = {
      ...values,
    };

    await editStatus(mappedValues);
  };
  const {
    isOpen: isOpenDelete,
    handleClose: handleCloseDelete,
    handleOpen: handleOpenDelete,
    context: deleteContext,
  } = useModal<LoanApplicationsType>();
  const {
    isOpen: isEditOpen,
    handleClose: handleCloseEdit,
    handleOpen: handleOpenEdit,
    context: editContext,
  } = useModal<LoanApplicationsType>();

  const columns: Column<LoanApplicationsType>[] = [
    {
      accessor: "upToDate",
      Header: "Hubspot",
      Cell: ({ value }: CellProps<LoanApplicationsType>) =>
        loanHubspotFormatter(value),
    },
    {
      accessor: "name",
      Header: "Loan Name",
    },
    {
      accessor: "lender",
      Header: "Lender",
    },
    {
      accessor: "loanAmount",
      Header: "Loan Amount",
      Cell: ({ value }: CellProps<LoanApplicationsType>) =>
        priceFormatter(value),
    },
    {
      accessor: "monthlyPayment",
      Header: "Monthly Payment",
      Cell: ({ value }: CellProps<LoanApplicationsType>) =>
        priceFormatter(value),
    },
    {
      accessor: "term",
      Header: "Term",
    },
    {
      accessor: "creditInquiry",
      Header: "Credit Inquiry",
      Cell: ({ value }: CellProps<LoanApplicationsType>) => value.join(", "),
    },
    {
      accessor: "applicationDate",
      Header: "Application Date",
      Cell: ({ value }: CellProps<LoanApplicationsType>) =>
        dateFormatter(value),
    },
    {
      accessor: "status",
      Header: "Status",
      Cell: (cell: CellProps<LoanApplicationsType>) => (
        <LoanStatusPicker {...cell} onChange={handleStatusChange} />
      ),
    },
    {
      accessor: "accountType",
      Header: "Account Type",
      Cell: ({ value }: CellProps<LoanApplicationsType>) =>
        loanAccountTypeFormatter(value),
    },
    {
      accessor: "interestRate",
      Header: "Interest Rate",
      Cell: ({ value }: CellProps<LoanApplicationsType>) =>
        precisePercentageFormatter(value),
    },
    {
      accessor: "originationFeePercentage",
      Header: "Origination  Percentage",
      Cell: ({ value }: CellProps<LoanApplicationsType>) =>
        precisePercentageFormatter(value),
    },
    {
      accessor: "originationFee",
      Header: "Origination Fee Amount",
      Cell: ({ value }: CellProps<LoanApplicationsType>) =>
        priceFormatter(value),
    },
    {
      accessor: "apr",
      Header: "APR",
      Cell: ({ value }: CellProps<LoanApplicationsType>) =>
        precisePercentageFormatter(value),
    },
    {
      accessor: "reasonCode",
      Header: "Reason Code",
    },
    {
      id: "actions",
      Header: intl.formatMessage({ id: "GLOBAL.ACTIONS" }),
      Cell: (cell: Cell<LoanApplicationsType>) => (
        <LoanApplicationsActionsFormatter
          {...cell}
          handleOpenEdit={handleOpenEdit}
          handleOpenDelete={handleOpenDelete}
        />
      ),
      align: "right",
    },
  ];

  return (
    <>
      <Paper>
        <Table
          data={data}
          columns={columns}
          status={status}
          getRowProps={(row) => {
            let backgroundColor = "#fff";
            let color = "#000";

            switch (row.original.status) {
              case LoanApplicationStatus.CANCEL_PROCESS:
                backgroundColor = "#f5f5f5";
                break;
              case LoanApplicationStatus.LOAN_DECLINED:
                backgroundColor = "#ffb9b9";
                break;
              case LoanApplicationStatus.APPROVED_NOT_TAKEN:
                backgroundColor = "#ffe3e7";
                break;
              case LoanApplicationStatus.LOAN_APPROVED:
                backgroundColor = "#d8ffd9";
                break;
              case LoanApplicationStatus.APPLICATION_SUBMITTED:
              case LoanApplicationStatus.APPLICATION_DOCUMENTS_REQUIRED:
                backgroundColor = "#ffffc5";
                break;
              case LoanApplicationStatus.LOAN_FUNDED:
                backgroundColor = "#333";
                color = "#fff";
                break;
              default:
                break;
            }
            return {
              sx: {
                backgroundColor,
                ".MuiTableCell-body": {
                  color,
                },
                ".MuiTableCell-body .MuiInputBase-root": {
                  color,
                },
                ".MuiTableCell-body .MuiInputBase-root .MuiSelect-icon": {
                  color,
                },
              },
            };
          }}
          manualPagination
        />
      </Paper>
      <DeleteLoanApplicationModal
        isOpen={isOpenDelete}
        handleClose={handleCloseDelete}
        loanApplication={deleteContext}
      />
      <EditLoanApplicationModal
        isOpen={isEditOpen}
        handleClose={handleCloseEdit}
        loanApplication={editContext}
      />
    </>
  );
};

export default LoanApplicationsTable;
