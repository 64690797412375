import { Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf";
import { useParams } from "react-router-dom";

import { getFile } from "api/file";
import { fileKeys } from "api/file/queries";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";

const File = () => {
  const layoutRef = useRef<HTMLDivElement>(null);
  const [pages, setPages] = useState<number>(0);
  const [scale, setScale] = useState<number>(1);

  const { fileId } = useParams<{ fileId: string }>();
  const { data: file } = useQuery(
    fileKeys.details(fileId),
    async () => {
      const { data: res } = await getFile(fileId);

      return res;
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    },
  );

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setPages(numPages);
  }

  useEffect(() => {
    if (layoutRef.current) {
      const canvasWidth = 594;
      const layoutWidth = layoutRef.current.getBoundingClientRect().width;

      setScale((layoutWidth / canvasWidth) * 0.85);
    }
  }, []);

  return (
    <Box sx={{ height: "100%" }} ref={layoutRef}>
      {fileId.includes("html") && file && (
        <iframe
          title="file"
          srcDoc={file}
          frameBorder="none"
          style={{ width: "100%", height: "100%" }}
        />
      )}
      {fileId.includes("pdf") && file && (
        // <iframe
        //   title="file"
        //   src={window.URL.createObjectURL(
        //     new Blob([file], { type: "application/pdf" }),
        //   )}
        //   frameBorder="none"
        //   style={{ width: "100%", height: "100%" }}
        // />
        <Box sx={{ width: "100%" }}>
          <Document
            // renderMode="canvas"
            file={window.URL.createObjectURL(
              new Blob([file], { type: "application/pdf" }),
            )}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            {Array.from({ length: pages }).map((_, page) => (
              <Page scale={scale} pageNumber={page + 1} />
            ))}
          </Document>
        </Box>
      )}
    </Box>
  );
};

export default File;
