const loanPackagesKeys = {
  all: ["loan-packages"] as const,
  list: () => [...loanPackagesKeys.all, "list"],
  listFiltered: (filters: Record<string, unknown>) => [
    ...loanPackagesKeys.list(),
    filters,
  ],
  details: (id: string) => [...loanPackagesKeys.all, "detail", id] as const,
};

export { loanPackagesKeys };
