import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as H from "history";
import React, { FC, Suspense } from "react";
import { Router } from "react-router-dom";

import GlobalErrorBoundary from "components/providers/GlobalErrorBoundary";
import { I18nProvider } from "components/providers/I18nProvider";
import { LangaugeProvider } from "i18n";
import theme from "theme";

import ReactQueryProvider from "./components/providers/ReactQueryProvider";
import SEOProvider from "./components/providers/SEOProvider";
import SnackbarProvider from "./components/providers/SnackbarProvider";
import SplashScreenProvider from "./components/providers/SplashScreenProvider";

const Providers: FC<{ history: H.History }> = ({ children, history }) => {
  return (
    <>
      <CssBaseline />
      <LangaugeProvider>
        <I18nProvider>
          <ThemeProvider theme={theme}>
            <SEOProvider />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <GlobalErrorBoundary>
                <SplashScreenProvider>
                  <Suspense fallback={<></>}>
                    <Router history={history}>
                      <SnackbarProvider>
                        <ReactQueryProvider>{children}</ReactQueryProvider>
                      </SnackbarProvider>
                    </Router>
                  </Suspense>
                </SplashScreenProvider>
              </GlobalErrorBoundary>
            </LocalizationProvider>
          </ThemeProvider>
        </I18nProvider>
      </LangaugeProvider>
    </>
  );
};

export default Providers;
