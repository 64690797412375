import { Add as AddIcon } from "@mui/icons-material";
import { Dialog, DialogTitle, Divider, Stack } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FC } from "react";

import { saveAndRefetchCustomer } from "api/customers";
import { customersKeys } from "api/customers/queries";
import { CustomersType } from "api/customers/types";

import UpdateGeneralInfoForm, {
  UpdateGeneralInfoFormValues,
} from "./UpdateGeneralInfoForm";

interface UpdateGeneralInfoModalProps {
  isOpen: boolean;
  handleClose: () => void;
  customer: CustomersType;
}

const UpdateGeneralInfoModal: FC<UpdateGeneralInfoModalProps> = ({
  isOpen,
  handleClose,
  customer,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const queryCache = useQueryClient();

  const { mutateAsync: addSocialNumber, status } = useMutation(
    async (data: any) => {
      const res = await saveAndRefetchCustomer(customer._id, data);
      await queryCache.invalidateQueries(customersKeys.details(customer._id));
      return res.data;
    },
  );
  const handleSubmit = async (values: UpdateGeneralInfoFormValues) => {
    const mappedValues: any = {
      ...values,
    };

    await addSocialNumber(mappedValues, {
      onSuccess: () => handleClose(),
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm">
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <AddIcon sx={{ mr: 1 }} />
          Update General Info
        </Stack>
      </DialogTitle>
      <Divider />

      <UpdateGeneralInfoForm
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        submitStatus={status}
        initialValues={{
          firstName: customer.firstName,
          lastName: customer.lastName,
          middleName: customer.middleName,
          address: customer.address,
          city: customer.city,
          state: customer.state,
          zip: customer.zip,
          phone: customer.phone,
          social: customer.social,
          email: customer.email,
          birthday: customer.birthday,
        }}
      />
    </Dialog>
  );
};

export default UpdateGeneralInfoModal;
