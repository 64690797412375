import { Search as SearchIcon } from "@mui/icons-material";
import {
  Box,
  Chip,
  Grid,
  InputAdornment,
  Paper,
  TextField,
} from "@mui/material";
import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Cell, Column } from "react-table";

import { getLogs } from "api/logs";
import { logsKeys } from "api/logs/queries";
import { LogsType } from "api/logs/types";
import Table from "components/Table";
import { dateTimeFormatter } from "components/Table/formatters/dateTimeFormatter";
import useRemoteTableLogic from "components/Table/useRemoteTableLogic";

import { useWebhookLogsFilter } from "./useWebhookLogsFilter";

interface WebhookLogsTableProps {}

const WebhookLogsTable: FC<WebhookLogsTableProps> = () => {
  const { filters, searchField } = useWebhookLogsFilter();
  const intl = useIntl();

  const { data, status, sortOptions, paginationOptions } = useRemoteTableLogic(
    logsKeys.list(),
    getLogs,
    filters,
  );

  const columns: Column<LogsType>[] = [
    {
      accessor: "url",
      Header: intl.formatMessage({ id: "USER.WEBHOOK_LOGS.TABLE.URL" }),
    },
    {
      accessor: "method",
      Header: intl.formatMessage({ id: "USER.WEBHOOK_LOGS.TABLE.METHOD" }),
    },
    {
      accessor: "body",
      Header: intl.formatMessage({ id: "USER.WEBHOOK_LOGS.TABLE.BODY" }),
      disableSortBy: true,
      Cell: ({ value }: Cell<LogsType>) => (
        <Box>
          <pre>{JSON.stringify(JSON.parse(value), null, 2)}</pre>
        </Box>
      ),
    },
    {
      accessor: "statusCode",
      Header: intl.formatMessage({ id: "USER.WEBHOOK_LOGS.TABLE.STATUS_CODE" }),
      Cell: ({ value }: Cell<LogsType>) =>
        value ? (
          <Chip label={value} color={value < 400 ? "success" : "error"}></Chip>
        ) : (
          <></>
        ),
    },
    {
      accessor: "response",
      Header: intl.formatMessage({ id: "USER.WEBHOOK_LOGS.TABLE.RESPONSE" }),
      Cell: ({ value, row }: Cell<LogsType>) => (
        <Box>
          {value ? (
            <pre>{JSON.stringify(JSON.parse(value), null, 2)}</pre>
          ) : (
            row.original.statusMessage
          )}
        </Box>
      ),
    },
    {
      accessor: "createdAt",
      Header: intl.formatMessage({ id: "USER.WEBHOOK_LOGS.TABLE.CREATED_AT" }),
      Cell: ({ value }: Cell<LogsType>) => <>{dateTimeFormatter(value)}</>,
    },
  ];

  return (
    <>
      <Grid container sx={{ py: 2 }} spacing={2}>
        <Grid item xs={3}>
          <TextField
            fullWidth
            label={<FormattedMessage id="GLOBAL.SEARCH" />}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            helperText={
              <FormattedMessage id="USER.WEBHOOK_LOGS.TABLE.SEARCH_BY" />
            }
            {...searchField}
          />
        </Grid>
        <Grid item xs={9}></Grid>
      </Grid>

      <Paper>
        <Table
          data={data}
          columns={columns}
          sort={sortOptions}
          pagination={paginationOptions}
          status={status}
          initialState={{
            sortBy: [
              {
                id: "createdAt",
                desc: true,
              },
            ],
          }}
        />
      </Paper>
    </>
  );
};

export default WebhookLogsTable;
