import { CustomersType } from "api/customers/types";

export const generateCustomerValues = (customer?: CustomersType) => {
  return {
    spouse: customer?.spouse?._id || "",
    // GENERAL INFORMATION
    firstName: customer?.firstName || "",
    middleName: customer?.middleName || "",
    lastName: customer?.lastName || "",
    salutation: customer?.salutation || "",
    birthday: customer?.birthday || null,
    address: customer?.address || "",
    city: customer?.city || "",
    state: customer?.state || "",
    zip: customer?.zip || "",
    phone: customer?.phone || "",
    mobilePhone: customer?.mobilePhone || "",
    referralSource: customer?.referralSource || "",
    email: customer?.email || "",
    associatedBrand: customer?.associatedBrand || "",
    social: customer?.social || "",
    leadSource: customer?.leadSource || "",

    submissionEmail: customer?.submissionEmail || "",
    submissionPassword: customer?.submissionPassword || "",

    // PERSONAL INFORMATION
    personalInfo: {
      driversLicenseId: customer?.personalInfo?.driversLicenseId || null,
      driversLicenseIssueDate:
        customer?.personalInfo?.driversLicenseIssueDate || null,
      driversLicenseExpireDate:
        customer?.personalInfo?.driversLicenseExpireDate || null,
      creditUnion: customer?.personalInfo?.creditUnion || null,
      personalBank: customer?.personalInfo?.personalBank || null,
      militaryStatus: customer?.personalInfo?.militaryStatus || null,
      militaryAffiliation: customer?.personalInfo?.militaryAffiliation || null,
      bankRoutingNumber: customer?.personalInfo?.bankRoutingNumber || null,
      bankAccountNumber: customer?.personalInfo?.bankAccountNumber || null,
      creditRepairBefore: customer?.personalInfo?.creditRepairBefore || false,
      judgementsLiensBankruptcy:
        customer?.personalInfo?.judgementsLiensBankruptcy || null,
      previoiusFinanceCompany:
        customer?.personalInfo?.previoiusFinanceCompany || false,
      maritalStatus: customer?.personalInfo?.maritalStatus || null,
    },
    // HOUSING INFO
    housingInfo: {
      houstingStatus: customer?.housingInfo?.houstingStatus || null,
      monthlyHousingPayment:
        customer?.housingInfo?.monthlyHousingPayment || null,
      estimatedLengthOfTimeAtResidence:
        customer?.housingInfo?.estimatedLengthOfTimeAtResidence || null,
      moveInDate: customer?.housingInfo?.moveInDate || null,
      calculatedLengthOfTimeAtResidence:
        customer?.housingInfo?.calculatedLengthOfTimeAtResidence || null,
      yearsAtCurrentAddress:
        customer?.housingInfo?.yearsAtCurrentAddress || null,
    },
    // EMPLOYMENT INFO
    employmentInfo: {
      incomeType: customer?.employmentInfo?.incomeType || null,
      employerName: customer?.employmentInfo?.employerName || null,
      employerPhone: customer?.employmentInfo?.employerPhone || null,
      employerAddress: customer?.employmentInfo?.employerAddress || null,
      estimatedTimeAtJob: customer?.employmentInfo?.estimatedTimeAtJob || null,
      startDateWithEmployer:
        customer?.employmentInfo?.startDateWithEmployer || null,
      calculatedLengthOfEmployment:
        customer?.employmentInfo?.calculatedLengthOfEmployment || null,
      occupationPosition: customer?.employmentInfo?.occupationPosition || null,
      monthlyGrossIncome: customer?.employmentInfo?.monthlyGrossIncome || null,
      annualPersonalIncome:
        customer?.employmentInfo?.annualPersonalIncome || null,
      frontEndRtiRatio: customer?.employmentInfo?.frontEndRtiRatio || null,
      totalAnnualHouseholdIncome:
        customer?.employmentInfo?.totalAnnualHouseholdIncome || null,
      householdFrontEndDtiRatio:
        customer?.employmentInfo?.householdFrontEndDtiRatio || null,
      statedMonthlyIncome:
        customer?.employmentInfo?.statedMonthlyIncome || null,
      statedAnnualIncome: customer?.employmentInfo?.statedAnnualIncome || null,
      statedAnnualHouseholdIncome:
        customer?.employmentInfo?.statedAnnualHouseholdIncome || null,
    },
    // SECURITY QUESTIONS
    securityQuestions: {
      birthCity: customer?.securityQuestions?.birthCity || null,
      bronInForeignCountry:
        customer?.securityQuestions?.bronInForeignCountry || false,
      legalPermanentResident:
        customer?.securityQuestions?.legalPermanentResident || false,
      greenCardExpirationDate:
        customer?.securityQuestions?.greenCardExpirationDate || null,
      mothersMaidenName: customer?.securityQuestions?.mothersMaidenName || null,
      highSchoolMascot: customer?.securityQuestions?.highSchoolMascot || null,
      highSchoolCity: customer?.securityQuestions?.highSchoolCity || null,
      nameOfStreetYouGrewUp:
        customer?.securityQuestions?.nameOfStreetYouGrewUp || null,
      favoritePetsName: customer?.securityQuestions?.favoritePetsName || null,
    },
    // EDUCATION
    educationInfo: {
      collegeAttended: customer?.educationInfo?.collegeAttended || null,
      fieldOfStudy: customer?.educationInfo?.fieldOfStudy || null,
      degree: customer?.educationInfo?.degree || null,
      graduatedDate: customer?.educationInfo?.graduatedDate || null,
      graduateSchoolAttended:
        customer?.educationInfo?.graduateSchoolAttended || null,
      graduateSchoolFieldOfStudy:
        customer?.educationInfo?.graduateSchoolFieldOfStudy || null,
      graduateDegreeReceived:
        customer?.educationInfo?.graduateDegreeReceived || null,
      graduateGraduationDate:
        customer?.educationInfo?.graduateGraduationDate || null,
    },
    // ASSET INFORMATION
    assetInfo: {
      combinedCheckingSavingsBalance:
        customer?.assetInfo?.combinedCheckingSavingsBalance || null,
      stocksBondsMutualFunds:
        customer?.assetInfo?.stocksBondsMutualFunds || null,
      retirementAccountBalance:
        customer?.assetInfo?.retirementAccountBalance || null,
    },
    // PRIMARY RESIDENCE VALUATION
    primaryResidenceValuation: {
      avmValue: customer?.primaryResidenceValuation?.avmValue || null,
      marketValue: customer?.primaryResidenceValuation?.marketValue || null,
      zillowValue: customer?.primaryResidenceValuation?.zillowValue || null,
      estimatedPropertyValue:
        customer?.primaryResidenceValuation?.estimatedPropertyValue || null,
      estimatedEquityPrimaryResidence:
        customer?.primaryResidenceValuation?.estimatedEquityPrimaryResidence ||
        null,
      calculatedValue:
        customer?.primaryResidenceValuation?.calculatedValue || null,
      calculatedEquity:
        customer?.primaryResidenceValuation?.calculatedEquity || null,
    },
  };
};
