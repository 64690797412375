// COMPONENTS:
import { Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";

import CustomersTable from "./CustomersTable";

const CustomersPage = () => {
  const intl = useIntl();

  return (
    <>
      <Helmet title={intl.formatMessage({ id: "CUSTOMERS.TITLE" })} />

      <Typography variant="h2" fontWeight="bold" mt={1}>
        <FormattedMessage id="CUSTOMERS.TABLE.TITLE" />
      </Typography>
      <CustomersTable />
    </>
  );
};

export default CustomersPage;
