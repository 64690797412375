import { Add as AddIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Stack,
} from "@mui/material";
import { MutationStatus } from "@tanstack/react-query";
import {
  FieldArray,
  Form as FormikForm,
  FormikProvider,
  useFormik,
} from "formik";
import { debounce } from "lodash";
import { FC } from "react";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";

import FormikAutocomplete from "components/forms/FormikAutocomplete";
import FormikDatePicker from "components/forms/FormikDatePicker";
import FormikNumberField from "components/forms/FormikNumberField";
import FormikTextField from "components/forms/FormikTextField";

export type IncomeFormValues = {
  _id: string;
  type: string | null;
  period: string;
  source: string;
  incomes: {
    date: Date | null;
    amount: number | null;
    ytd: number | null;
    grossRevenue: number | null;
    netProfit: number | null;
    annualWages: number | null;
    source: string;
    monthlyBenefit: number | null;
  }[];
};

interface IIncomeForm {
  handleSubmit: (values: IncomeFormValues) => void;
  handleClose: () => void;
  initialValues?: IncomeFormValues;
  submitStatus?: MutationStatus;
  edit?: boolean;
}

const paymentsPerYear = {
  weekly: 52,
  "bi-weekly": 26,
  monthly: 12,
  "bi-monthly": 6,
  quarterly: 4,
  annual: 1,
};

const IncomeForm: FC<IIncomeForm> = ({
  handleSubmit,
  handleClose,
  initialValues = {
    _id: "",
    type: null,
    period: "",
    source: "",
    incomes: [
      {
        date: null,
        amount: null,
        ytd: null,
        grossRevenue: null,
        netProfit: null,
        annualWages: null,
        source: "",
        monthlyBenefit: null,
      },
    ],
  },
  submitStatus,
}) => {
  const schema = yup.object({
    type: yup.string().required().nullable(),
    period: yup.string().when("type", {
      is: "paystub",
      then: (schema) => schema.required(),
    }),
    incomes: yup.array().of(
      yup.object({
        date: yup.date().required().nullable(),
        // amount: yup
        //   .number()
        //   .nullable()
        //   .when("type", {
        //     is: "paystub",
        //     then: yup
        //       .number()
        //       .required()
        //       .nullable()
        //   }),
        // ytd: yup
        //   .number()
        //   .nullable()
        //   .when([yup.ref("type")], {
        //     is: "paystub",
        //     then: yup.number().required()
        //   })
      }),
    ),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  const { values, resetForm, setFieldValue } = formik;

  return (
    <>
      <FormikProvider value={formik}>
        <FormikForm>
          <DialogContent>
            <Grid container spacing={1} rowSpacing={0.5}>
              <Grid item xs={12}>
                <FormikAutocomplete
                  label="Type"
                  name="type"
                  options={[
                    {
                      value: "paystub",
                      label: "W2/Paystub",
                    },
                    {
                      value: "self-employment",
                      label: "Self Employment",
                    },
                    {
                      value: "additional-income",
                      label: "Additional Income",
                    },
                    {
                      value: "housing-allowance",
                      label: "Housing Allowance",
                    },
                  ]}
                  onChange={(e, data) => {
                    resetForm();
                    setFieldValue("type", data?.value);
                  }}
                />
                <FormikTextField label="Source" name="source" />
              </Grid>

              <Grid item xs={12}>
                <Divider sx={{ my: 1 }} />
              </Grid>

              {/* Paystub */}
              {values.type === "paystub" && (
                <>
                  <Grid item xs={12}>
                    <FormikAutocomplete
                      options={[
                        {
                          label: "Weekly",
                          value: "weekly",
                        },
                        {
                          label: "Bi-weekly",
                          value: "bi-weekly",
                        },
                        {
                          label: "Monthly",
                          value: "monthly",
                        },
                        {
                          label: "Bi-monthly",
                          value: "bi-monthly",
                        },
                        {
                          label: "Quarterly",
                          value: "quarterly",
                        },
                        {
                          label: "Annual",
                          value: "annual",
                        },
                      ]}
                      name="period"
                      label="Period"
                      onChange={(e, data) => {
                        setFieldValue(`period`, data?.value);
                        if (!data?.value) return;

                        values.incomes.forEach((income, index) => {
                          if (!income.ytd || income.amount) return;

                          const amount =
                            income.ytd! /
                            //@ts-expect-error
                            paymentsPerYear[data.value];

                          setFieldValue(
                            `incomes.${index}.amount`,
                            parseFloat(amount.toFixed(2)),
                          );
                        });
                      }}
                    />
                  </Grid>
                  <FieldArray
                    name="incomes"
                    render={(arrayHelpers) => {
                      return (
                        <>
                          {values.incomes.map((income, index) => (
                            <>
                              <Grid item xs={3.67}>
                                <FormikDatePicker
                                  name={`incomes.${index}.date`}
                                  label="Pay Date"
                                />
                              </Grid>
                              <Grid item xs={3.66}>
                                <FormikNumberField
                                  name={`incomes.${index}.amount`}
                                  label="Amount"
                                  prefix="$"
                                />
                              </Grid>
                              <Grid item xs={3.67}>
                                <FormikNumberField
                                  name={`incomes.${index}.ytd`}
                                  label="YTD"
                                  prefix="$"
                                  onChange={(value) => {
                                    if (!value) return;

                                    const debounceFunction = debounce(
                                      function () {
                                        if (
                                          values.period &&
                                          !values.incomes[index].amount
                                        ) {
                                          const amount =
                                            value /
                                            //@ts-expect-error
                                            paymentsPerYear[values.period];

                                          setFieldValue(
                                            `incomes.${index}.amount`,
                                            parseFloat(amount.toFixed(2)),
                                          );
                                        }
                                      },
                                      2000,
                                    );
                                    debounceFunction();
                                  }}
                                />
                              </Grid>
                              <Grid item xs={1}>
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="center"
                                  sx={{ height: "100%", maxHeight: "50px" }}
                                >
                                  <IconButton
                                    color="error"
                                    onClick={() => arrayHelpers.remove(index)}
                                    disabled={values.incomes.length <= 1}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Stack>
                              </Grid>
                            </>
                          ))}
                          <Grid item xs={3}>
                            <Button
                              variant="contained"
                              onClick={() =>
                                arrayHelpers.push({
                                  date: null,
                                  amount: null,
                                  ytd: null,
                                  grossRevenue: null,
                                  netProfit: null,
                                  annualWages: null,
                                  source: "",
                                  monthlyBenefit: null,
                                })
                              }
                            >
                              <AddIcon />
                            </Button>
                          </Grid>
                        </>
                      );
                    }}
                  />
                </>
              )}

              {/* Self Employment */}
              {values.type === "self-employment" && (
                <FieldArray
                  name="incomes"
                  render={(arrayHelpers) => {
                    return (
                      <>
                        {values.incomes.map((income, index) => (
                          <>
                            <Grid item xs={2.75}>
                              <FormikDatePicker
                                name={`incomes.${index}.date`}
                                label="Date"
                              />
                            </Grid>
                            <Grid item xs={2.75}>
                              <FormikNumberField
                                name={`incomes.${index}.grossRevenue`}
                                label="Gross Revenue"
                                prefix="$"
                              />
                            </Grid>
                            <Grid item xs={2.75}>
                              <FormikNumberField
                                name={`incomes.${index}.netProfit`}
                                label="Net Profit"
                                prefix="$"
                              />
                            </Grid>
                            <Grid item xs={2.75}>
                              <FormikNumberField
                                name={`incomes.${index}.annualWages`}
                                label="Annual Wages"
                                prefix="$"
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                                sx={{ height: "100%", maxHeight: "50px" }}
                              >
                                <IconButton
                                  color="error"
                                  onClick={() => arrayHelpers.remove(index)}
                                  disabled={values.incomes.length <= 1}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Stack>
                            </Grid>
                          </>
                        ))}
                        <Grid item xs={4}>
                          <Button
                            variant="contained"
                            onClick={() =>
                              arrayHelpers.push({
                                date: null,
                                amount: null,
                                ytd: null,
                                grossRevenue: null,
                                netProfit: null,
                                annualWages: null,
                                source: "",
                                monthlyBenefit: null,
                              })
                            }
                          >
                            <AddIcon />
                          </Button>
                        </Grid>
                      </>
                    );
                  }}
                />
              )}

              {/* Additional Income */}
              {values.type === "additional-income" && (
                <FieldArray
                  name="incomes"
                  render={(arrayHelpers) => {
                    return (
                      <>
                        {values.incomes.map((income, index) => (
                          <>
                            <Grid item xs={3.4}>
                              <FormikDatePicker
                                name={`incomes.${index}.date`}
                                label="Income Start Date"
                              />
                            </Grid>
                            <Grid item xs={3.4}>
                              <FormikTextField
                                name={`incomes.${index}.source`}
                                label="Source"
                              />
                            </Grid>
                            <Grid item xs={3.4}>
                              <FormikNumberField
                                name={`incomes.${index}.monthlyBenefit`}
                                label="Monthly Benefit"
                                prefix="$"
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                                sx={{ height: "100%", maxHeight: "50px" }}
                              >
                                <IconButton
                                  color="error"
                                  onClick={() => arrayHelpers.remove(index)}
                                  disabled={values.incomes.length <= 1}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Stack>
                            </Grid>
                          </>
                        ))}
                        <Grid item xs={4}>
                          <Button
                            variant="contained"
                            onClick={() =>
                              arrayHelpers.push({
                                date: null,
                                amount: null,
                                ytd: null,
                                grossRevenue: null,
                                netProfit: null,
                                annualWages: null,
                                source: "",
                                monthlyBenefit: null,
                              })
                            }
                          >
                            <AddIcon />
                          </Button>
                        </Grid>
                      </>
                    );
                  }}
                />
              )}

              {/* Housing Allowance */}
              {values.type === "housing-allowance" && (
                <FieldArray
                  name="incomes"
                  render={(arrayHelpers) => {
                    return (
                      <>
                        {values.incomes.map((income, index) => (
                          <>
                            <Grid item xs={3.4}>
                              <FormikDatePicker
                                name={`incomes.${index}.date`}
                                label="Housing Allowance Date"
                              />
                            </Grid>
                            <Grid item xs={3.4}>
                              <FormikTextField
                                name={`incomes.${index}.source`}
                                label="Source"
                              />
                            </Grid>
                            <Grid item xs={3.4}>
                              <FormikNumberField
                                name={`incomes.${index}.monthlyBenefit`}
                                label="Monthly Benefit"
                                prefix="$"
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                                sx={{ height: "100%", maxHeight: "50px" }}
                              >
                                <IconButton
                                  color="error"
                                  onClick={() => arrayHelpers.remove(index)}
                                  disabled={values.incomes.length <= 1}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Stack>
                            </Grid>
                          </>
                        ))}
                        <Grid item xs={4}>
                          <Button
                            variant="contained"
                            onClick={() =>
                              arrayHelpers.push({
                                date: null,
                                amount: null,
                                ytd: null,
                                grossRevenue: null,
                                netProfit: null,
                                annualWages: null,
                                source: "",
                                monthlyBenefit: null,
                              })
                            }
                          >
                            <AddIcon />
                          </Button>
                        </Grid>
                      </>
                    );
                  }}
                />
              )}
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              // data-testid="new-edit-port-close-button"
              onClick={handleClose}
            >
              <FormattedMessage id="GLOBAL.CLOSE" />
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              loading={submitStatus === "loading"}
            >
              <FormattedMessage id="GLOBAL.SAVE" />
            </LoadingButton>
          </DialogActions>
        </FormikForm>
      </FormikProvider>
    </>
  );
};

export default IncomeForm;
