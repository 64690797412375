import { Paginated, QueryParamsType, normalizeQueryParams } from "api/utils";

import request, { APICall } from "../request";
import {
  CreditEvaluationIncomeOverviewEnum,
  CreditEvaluationsType,
  EditCreditEvaluationType,
  NewCreditEvaluationPayloadType,
} from "./types";

export const getCreditEvaluations = (
  queryParams?: QueryParamsType<CreditEvaluationsType>,
): APICall<Paginated<CreditEvaluationsType[]>> =>
  request({
    url: `/credit-evaluation${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });

export const getSingleCreditEvaluation = (
  creditEvaluationId: string,
): APICall<{ data: CreditEvaluationsType }> =>
  request({
    url: `/credit-evaluation/${creditEvaluationId}`,
    method: "GET",
  });

export const addCreditEvaluation = (
  creditEvaluation: NewCreditEvaluationPayloadType,
): APICall<CreditEvaluationsType> =>
  request({
    data: creditEvaluation,
    url: "/credit-evaluation",
    method: "POST",
  });

export const editCreditEvaluation = (
  creditEvaluation: EditCreditEvaluationType,
): APICall<EditCreditEvaluationType> =>
  request({
    data: creditEvaluation,
    url: `/credit-evaluation/${creditEvaluation._id}`,
    method: "PUT",
  });

export const deleteCreditEvaluation = (
  creditEvaluationId: string,
): APICall<null> =>
  request({
    url: `/credit-evaluation/${creditEvaluationId}`,
    method: "DELETE",
  });

export const addCreditEvaluationIncome = (
  creditEvaluationId: string,
  income: any,
): APICall<CreditEvaluationsType> =>
  request({
    data: income,
    url: `/credit-evaluation/income/${creditEvaluationId}`,
    method: "POST",
  });

export const editCreditEvaluationIncome = (
  creditEvaluationId: string,
  incomeId: string,
  income: any,
): APICall<CreditEvaluationsType> =>
  request({
    data: income,
    url: `/credit-evaluation/income/${creditEvaluationId}/${incomeId}`,
    method: "PUT",
  });

export const deleteCreditEvaluationIncome = (
  creditEvaluationId: string,
  incomeId: string,
): APICall<CreditEvaluationsType> =>
  request({
    url: `/credit-evaluation/income/${creditEvaluationId}/${incomeId}`,
    method: "DELETE",
  });

export const editCreditEvaluationSummaryOfIncomes = (
  creditEvaluationId: string,
  incomeSourceId: string,
  selected: boolean,
): APICall<CreditEvaluationsType> =>
  request({
    data: { selected },
    url: `/credit-evaluation/summary-of-income/${creditEvaluationId}/${incomeSourceId}`,
    method: "PUT",
  });

export const editCreditEvaluationDebt = (
  creditEvaluationId: string,
  debt: any,
): APICall<CreditEvaluationsType> =>
  request({
    data: debt,
    url: `/credit-evaluation/debt/${creditEvaluationId}`,
    method: "PUT",
  });

export const pushCreditEvaluationLoanApplications = (
  creditEvaluationId: string,
): APICall<CreditEvaluationsType> =>
  request({
    url: `/credit-evaluation/loan-applications/hubspot/${creditEvaluationId}`,
    method: "PUT",
  });

export const editCreditEvaluationHouseholdIncome = (
  creditEvaluationId: string,
  type: CreditEvaluationIncomeOverviewEnum,
): APICall<CreditEvaluationsType> =>
  request({
    data: { type },
    url: `/credit-evaluation/household-income/${creditEvaluationId}`,
    method: "PUT",
  });

export const editCreditEvaluationAffordability = (
  creditEvaluationId: string,
  affordability: string,
): APICall<CreditEvaluationsType> =>
  request({
    data: { affordability },
    url: `/credit-evaluation/affordability/${creditEvaluationId}`,
    method: "PUT",
  });

export const editCreditEvaluationLoanAffordabilityRate = (
  creditEvaluationId: string,
  rate: number,
): APICall<CreditEvaluationsType> =>
  request({
    data: { rate },
    url: `/credit-evaluation/loan-affordability/rate/${creditEvaluationId}`,
    method: "PUT",
  });

export const editCreditEvaluationHubspotDealId = (
  creditEvaluationId: string,
  hubspotDealId: string,
): APICall<CreditEvaluationsType> =>
  request({
    data: { hubspotDealId },
    url: `/credit-evaluation/hubspot-deal-id/${creditEvaluationId}`,
    method: "PUT",
  });
