import {
  // Add as AddIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import {
  Box,
  // Box,
  // Button,
  Grid,
  InputAdornment,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { FC, useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { useIntl } from "react-intl";
import { Link as RouteLink } from "react-router-dom";
import { Cell, Row } from "react-table";

import { getCreditEvaluations } from "api/creditEvaluations";
import { creditEvaluationsKeys } from "api/creditEvaluations/queries";
import { CreditEvaluationsType } from "api/creditEvaluations/types";
import { hasRole } from "components/stores/UserStore";
import Table, { TableColumn } from "components/Table";
import { dateFormatter } from "components/Table/formatters/dateFormatter";
import useRemoteTableLogic from "components/Table/useRemoteTableLogic";
import UserAvatar from "components/UserAvatar/UserAvatar";
import { generateCreditScoreColor } from "utils/generateCreditScoreColor/generateCreditScoreColor";
import { getFileLink } from "utils/getFile";

import { AffordabilityFormatter } from "./formatters/AffordabilityFormatter";
import { useCreditEvaluationsFilter } from "./useCreditEvaluationsFilter";

const CreditEvaluationsTable: FC = () => {
  const intl = useIntl();

  const { filters, searchField } = useCreditEvaluationsFilter();

  const { data, status, sortOptions, paginationOptions } = useRemoteTableLogic(
    creditEvaluationsKeys.list(),
    getCreditEvaluations,
    filters,
  );

  // const {
  //   // isOpen: isOpenDelete,
  //   // handleClose: handleCloseDelete,
  //   handleOpen: handleOpenDelete
  //   // context: deleteContext,
  // } = useModal<CreditEvaluationsType>();
  // const {
  //   // isOpen: isEditOpen,
  //   // handleClose: handleCloseEdit,
  //   handleOpen: handleOpenEdit
  //   // context: editContext,
  // } = useModal<CreditEvaluationsType>();

  const columns: TableColumn<CreditEvaluationsType>[] = [
    {
      id: "customer",
      Header: intl.formatMessage({
        id: "CREDIT_EVALUATIONS.TABLE.CUSTOMER",
      }),
      Cell: ({ row }: Cell<CreditEvaluationsType>) => (
        <Stack direction="row" alignItems="center">
          <UserAvatar
            size={36}
            fontSize={16}
            userName={`${row.original.customer?.firstName} ${row.original.customer?.lastName}`}
          />
          <Typography ml={1}>
            {`${row.original.customer?.firstName} ${row.original.customer?.lastName}`}
          </Typography>
        </Stack>
      ),
    },
    {
      id: "creditScore",
      Header: "Credit Score",
      Cell: ({ row }: Cell<CreditEvaluationsType>) => (
        <>
          {row.original.creditScores.map((creditScore) => (
            <Typography>
              {creditScore.type}:{" "}
              <span
                style={{
                  fontWeight: "bold",
                  color: generateCreditScoreColor(creditScore.score),
                }}
              >
                {creditScore.score}
              </span>
            </Typography>
          ))}
        </>
      ),
    },
    {
      id: "affordability",
      accessor: "affordability",
      Header: "Affordability",
      Cell: ({ value }: Cell<CreditEvaluationsType>) => (
        <AffordabilityFormatter value={value} />
      ),
    },
    // {
    //   id: "notes",
    //   accessor: "notes",
    //   Header: "Notes",
    //   Cell: ({ value }: Cell<CreditEvaluationsType>) => (
    //     <Box
    //       className="line-clamp"
    //       sx={{
    //         display: "-webkit-box",
    //         maxWidth: "400px",
    //         whiteSpace: "normal",
    //         cursor: "pointer",

    //         "&.line-clamp": {
    //           overflow: "hidden",
    //           lineClamp: "3",
    //           WebkitLineClamp: "3",
    //           WebkitBoxOrient: "vertical"
    //         }
    //       }}
    //       onClick={el => {
    //         if (el.target) {
    //           //@ts-expect-error
    //           el.target.classList.remove("line-clamp");
    //         }
    //       }}
    //     >
    //       {value}
    //     </Box>
    //   )
    // },
    {
      id: "dealStatus",
      accessor: "dealStatus",
      Header: "Deal Status",
    },
    {
      display: hasRole("admin"),
      accessor: "leadSource",
      Header: "Lead Source",
    },
    {
      display: hasRole("admin"),
      id: "reports",
      Header: intl.formatMessage({
        id: "CREDIT_EVALUATIONS.TABLE.REPORTS",
      }),
      Cell: ({ row }: Cell<CreditEvaluationsType>) => (
        <Stack direction="column" spacing={0.5}>
          <Link
            component={RouteLink}
            to={`/credit-evaluations/${row.original._id}`}
          >
            <FormattedMessage id="CREDIT_EVALUATIONS.TABLE.CREDIT_EVALUATION" />
          </Link>
          <Link
            component={RouteLink}
            to={`/customers/${row.original.customer?._id}`}
          >
            <FormattedMessage id="CREDIT_EVALUATIONS.TABLE.CUSTOMER_DETAILS" />
          </Link>
          <Link
            component={RouteLink}
            to={getFileLink(row.original.html.replace("html", "pdf"))}
          >
            <FormattedMessage id="CREDIT_EVALUATIONS.TABLE.PDF_REPORT" />
          </Link>
        </Stack>
      ),
    },
    {
      accessor: "reportDate",
      Header: "Report Date",
      align: "right",
      Cell: ({ value }: Cell<CreditEvaluationsType>) => dateFormatter(value),
    },
    // {
    //   Header: intl.formatMessage({ id: "GLOBAL.ACTIONS" }),
    //   Cell: (cell: Cell<CreditEvaluationsType>) => (
    //     <CreditEvaluationsActionsFormatter
    //       {...cell}
    //       handleOpenEdit={handleOpenEdit}
    //       handleOpenDelete={handleOpenDelete}
    //     />
    //   ),
    //   align: "right"
    // }
  ];

  const getSubRows = useCallback(
    (row: any) => (row.notes ? [row.notes] : []),
    [],
  );

  const expandedAllRows = useMemo(() => {
    const expanded: { [key: string]: boolean } = {};

    if (data.length) {
      data.forEach((d, index) => {
        expanded[`${index}`] = true;
      });
    }

    return expanded;
    //eslint-disable-next-line
  }, [data.length]);

  return (
    <>
      <Grid container sx={{ py: 2 }} spacing={2}>
        <Grid item xs={5} lg={3}>
          <TextField
            fullWidth
            label={<FormattedMessage id="GLOBAL.SEARCH" />}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            helperText={
              <FormattedMessage id="CREDIT_EVALUATIONS.TABLE.SEARCH_BY" />
            }
            {...searchField}
          />
        </Grid>
        <Grid item xs={1} lg={5}></Grid>
        <Grid item xs={6} lg={4}>
          {/* <Box ml="auto" marginTop={1}>
            <Button
              sx={{
                ml: "auto",
                whiteSpace: "nowrap"
              }}
              variant="contained"
            >
              <AddIcon sx={{ mr: 0.5 }} />
              <FormattedMessage id="CREDIT_EVALUATIONS.TABLE.ADD_CREDIT_EVALUATION" />
            </Button>
          </Box> */}
        </Grid>
      </Grid>

      <Paper>
        <Table
          data={data}
          columns={columns}
          sort={sortOptions}
          pagination={paginationOptions}
          status={status}
          getSubRows={getSubRows}
          initialState={{
            expanded: expandedAllRows,
            sortBy: [
              {
                id: "reportDate",
                desc: true,
              },
            ],
          }}
          getRowProps={(row) => {
            if (row.original.notes) {
              return { sx: { ".MuiTableCell-root": { borderBottom: "none" } } };
            }
            return {};
          }}
          subComponent={(row: Row<any>) => {
            return (
              <Box
                component="td"
                colSpan={columns.length + 1}
                sx={{
                  px: "40px",
                  py: "10px",
                  bgcolor: "#fff",
                  borderBottom: "1px solid #e0e0e0",
                }}
              >
                <Box
                  sx={{
                    maxWidth: "50%",
                    minWidth: "600px",
                    whiteSpace: "break-spaces",
                    fontSize: "16px",
                    lineHeight: 1.3,
                  }}
                >
                  {row.original.trim()}
                </Box>
              </Box>
            );
          }}
        />
      </Paper>
    </>
  );
};

export default CreditEvaluationsTable;
