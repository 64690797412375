const creditEvaluationsKeys = {
  all: ["credit-evaluations"] as const,
  list: () => [...creditEvaluationsKeys.all, "list"],
  listFiltered: (filters: Record<string, unknown>) => [
    ...creditEvaluationsKeys.list(),
    filters,
  ],
  details: (id: string) =>
    [...creditEvaluationsKeys.all, "detail", id] as const,
};

export { creditEvaluationsKeys };
