import { SnackbarProvider as SnackbarActualProvider } from "notistack";
import React, { FC } from "react";

const SnackbarProvider: FC = ({ children }) => {
  return (
    <SnackbarActualProvider maxSnack={3}>{children}</SnackbarActualProvider>
  );
};

export default SnackbarProvider;
