import { LoanApplicationCreditInquiry } from "api/loanApplications/types";

export enum HubspotLenderCreditBureauEnum {
  Experian = LoanApplicationCreditInquiry.EXPERIAN,
  Equifax = LoanApplicationCreditInquiry.EQUIFAX,
  TransUnion = LoanApplicationCreditInquiry.TRANSUNION,
  "Tri-Merge" = LoanApplicationCreditInquiry.TRI_MERGE,
  "Soft-Pull" = LoanApplicationCreditInquiry.SOFT_PULL,
}

export type HubspotLenderType = {
  id: string;
  lender_name: string;
  origination_fee_total: string;
  origination_fee: string;
  credit_inquiry: string;
};
