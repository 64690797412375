import { LoanApplicationStatus } from "api/loanApplications/types";

export const loanStatusFormatter = (
  value: LoanApplicationStatus | undefined | null,
) => {
  switch (value) {
    case LoanApplicationStatus.APPLICATION_PENDING_SUBMISSION:
      return "Application Pending Submission";
    case LoanApplicationStatus.APPLICATION_SUBMITTED:
      return "Application Submitted";
    case LoanApplicationStatus.APPLICATION_DOCUMENTS_REQUIRED:
      return "Additional Documents Required";
    case LoanApplicationStatus.LOAN_APPROVED:
      return "Loan Approved";
    case LoanApplicationStatus.APPROVED_NOT_TAKEN:
      return "Approved – Not Taken";
    case LoanApplicationStatus.LOAN_FUNDED:
      return "Loan Funded";
    case LoanApplicationStatus.LOAN_DECLINED:
      return "Loan Declined";
    case LoanApplicationStatus.CANCEL_PROCESS:
      return "Cancel Process";
    default:
      return "-";
  }
};
