import { Box, Paper, Stack } from "@mui/material";
import { QueryStatus } from "@tanstack/react-query";
import { FormikProvider, useFormik } from "formik";
import React, { FC, useMemo } from "react";
import { useIntl } from "react-intl";
import { CellProps } from "react-table";

import { CreditEvaluationTradeline } from "api/creditEvaluations/types";
import FormikNumberField from "components/forms/FormikNumberField";
import Table, { TableColumn } from "components/Table";
import { dateFormatter } from "components/Table/formatters/dateFormatter";
import { percentageFormatter } from "components/Table/formatters/percentageFormatter";
import { priceFormatter } from "components/Table/formatters/priceFormatter";
import { calcaulatePaydown } from "utils/creditEvaluation/calculatePaydown";
import { useDebounce } from "utils/hooks/useDebounce";

interface Props {
  data: CreditEvaluationTradeline[];
  status: QueryStatus;
}

const TradelinesTable: FC<Props> = ({ data, status }) => {
  const intl = useIntl();

  const formik = useFormik({
    initialValues: {
      paydown: 60,
    },
    enableReinitialize: false,
    onSubmit: () => {},
  });

  const { values } = formik;
  const debouncedPaydown = useDebounce(values.paydown, 1500);

  const columns: TableColumn<CreditEvaluationTradeline>[] = useMemo(
    () => [
      {
        accessor: "status",
        Header: "Status",
        Cell: ({ value }: CellProps<CreditEvaluationTradeline>) => (
          <Box textTransform="capitalize">{value}</Box>
        ),
      },
      {
        accessor: "creditor",
        Header: intl.formatMessage({ id: "GLOBAL.CREDITOR" }),
      },
      {
        accessor: "balance",
        Header: intl.formatMessage({ id: "GLOBAL.BALANCE" }),
        Cell: ({ value }: CellProps<CreditEvaluationTradeline>) =>
          priceFormatter(value),
      },
      {
        accessor: "payment",
        Header: intl.formatMessage({ id: "GLOBAL.PAYMENT" }),
        Cell: ({ value }: CellProps<CreditEvaluationTradeline>) =>
          priceFormatter(value),
      },
      {
        accessor: "hpb",
        Header: "HPB",
        Cell: ({ value }: CellProps<CreditEvaluationTradeline>) =>
          priceFormatter(value),
      },
      {
        accessor: "creditLimit",
        Header: intl.formatMessage({
          id: "CREDIT_EVALUATIONS.TRADELINES.TABLE.CREDIT_LIMIT",
        }),
        Cell: ({ value }: CellProps<CreditEvaluationTradeline>) =>
          priceFormatter(value),
      },
      {
        accessor: "opened",
        Header: intl.formatMessage({ id: "GLOBAL.OPENED" }),
        Cell: ({ value }: CellProps<CreditEvaluationTradeline>) =>
          dateFormatter(value),
      },
      {
        accessor: "accountType",
        Header: intl.formatMessage({ id: "GLOBAL.ACCOUNT_TYPE" }),
      },
      {
        accessor: "utilizationRate",
        Header: intl.formatMessage({
          id: "CREDIT_EVALUATIONS.TRADELINES.TABLE.UTILIZATION_RATE",
        }),
        Cell: ({ value }: CellProps<CreditEvaluationTradeline>) =>
          percentageFormatter(value * 100),
      },
      // {
      //   id: "paydown50",
      //   Header: "Paydown 50%",
      //   Cell: ({ row }: CellProps<CreditEvaluationTradeline>) =>
      //     priceFormatter(calcaulatePaydown(50, row.original)),
      //   display: false,
      // },
      {
        id: "paydown-dynamic",
        Header: () => (
          <FormikProvider value={formik}>
            <Stack direction="row" alignItems="center">
              Paydown
              <FormikNumberField
                min={0}
                max={100}
                onBlur={(e) => {
                  e.preventDefault();
                }}
                name="paydown"
                suffix="%"
                variant="filled"
                sx={{
                  m: 0,

                  "&.MuiFormControl-root": {
                    width: 42,
                    ml: 1,
                  },

                  "& .MuiInputBase-root": {
                    fontWeight: 600,
                    backgroundColor: "transparent!important",
                  },

                  "& .MuiInputBase-input": {
                    padding: 0,
                  },
                }}
              />
            </Stack>
          </FormikProvider>
        ),
        Cell: ({ row }: CellProps<CreditEvaluationTradeline>) =>
          priceFormatter(calcaulatePaydown(debouncedPaydown, row.original)),
      },
      {
        id: "paydown75",
        Header: "Paydown 75%",
        Cell: ({ row }: CellProps<CreditEvaluationTradeline>) =>
          priceFormatter(calcaulatePaydown(75, row.original)),
        display: false,
      },
      {
        accessor: "typeDetail",
        Header: "Type Detail",
      },
      {
        accessor: "reportDate",
        Header: intl.formatMessage({ id: "GLOBAL.REPORT_DATE" }),
        Cell: ({ value }: CellProps<CreditEvaluationTradeline>) =>
          dateFormatter(value),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedPaydown, intl],
  );

  return (
    <>
      <Paper>
        <Table
          data={data}
          columns={columns}
          status={status}
          initialState={{
            sortBy: [
              {
                id: "reportDate",
                desc: false,
              },
            ],
          }}
          getRowProps={(row) => {
            let additionalProperties: { [key: string]: any } = {};

            if (row.original.joint) {
              additionalProperties.backgroundColor = "#c49ec4";
            } else if (row.original.accountType === "Joint Account") {
              additionalProperties.backgroundColor = "#bedfff";

              if (row.original.utilizationRate >= 0.5) {
                additionalProperties["& .MuiTableCell-root:nth-child(2)"] = {
                  backgroundColor: "#edc3cd",
                };
              }
            } else if (row.original.status === "closed") {
              additionalProperties.backgroundColor = "#fffbab";
            } else if (row.original.utilizationRate > 0.5) {
              additionalProperties.backgroundColor = "#edc3cd";
            }

            const utilizationChildren = [3, 4, 6, 9, 10];
            if (row.original.utilizationRate >= 0.5) {
              utilizationChildren.forEach((child) => {
                additionalProperties[
                  `& .MuiTableCell-root:nth-child(${child})`
                ] = {
                  backgroundColor: "#edc3cd",
                };
              });
            }

            if (row.original.status === "closed") {
              additionalProperties[`& .MuiTableCell-root:nth-child(1)`] = {
                backgroundColor: "#fffbab",
              };
            }

            return {
              sx: {
                ...additionalProperties,
              },
            };
          }}
          additionalRows={[
            {
              cells: [
                { value: <b>Total:</b> },
                { value: null },
                {
                  value: (
                    <b>
                      {priceFormatter(
                        data.reduce(
                          (prev: number, tradeline) => prev + tradeline.balance,
                          0,
                        ),
                      )}
                    </b>
                  ),
                },
                {
                  value: (
                    <b>
                      {priceFormatter(
                        data.reduce(
                          (prev: number, tradeline) => prev + tradeline.payment,
                          0,
                        ),
                      )}
                    </b>
                  ),
                },
                { value: null },
                {
                  value: (
                    <b>
                      {priceFormatter(
                        data.reduce(
                          (prev: number, tradeline) =>
                            prev + tradeline.creditLimit,
                          0,
                        ),
                      )}
                    </b>
                  ),
                },
                { value: null },
                { value: null },
                { value: null },
                { value: null },
                { value: null },
                { value: null },
                { value: null },
              ],
            },
          ]}
          manualPagination
        />
      </Paper>
    </>
  );
};

export default TradelinesTable;
