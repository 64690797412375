import { Done as DoneIcon, Warning as WarningIcon } from "@mui/icons-material";
import { Box, Tooltip } from "@mui/material";

export const loanHubspotFormatter = (value: boolean | undefined | null) => {
  if (value) {
    return (
      <Tooltip title="Hubspot is up to date!">
        <Box display="inline-flex">
          <DoneIcon sx={{ mt: "5px" }} color="success" />
        </Box>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title="Hubspot requires sync!">
        <Box display="inline-flex">
          <WarningIcon sx={{ mt: "5px", color: "#ed6c02" }} color="warning" />
        </Box>
      </Tooltip>
    );
  }
};
