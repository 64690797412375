// COMPONENTS:
import { Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import LoanPackagesTable from "./LoanPackagesTable";

const LoanPackagesPage = () => {
  return (
    <>
      <Typography variant="h2" fontWeight="bold" mt={1}>
        <FormattedMessage id="LOAN_PACKAGES.TABLE.TITLE" />
      </Typography>
      <LoanPackagesTable />
    </>
  );
};

export default LoanPackagesPage;
