import {
  DeleteOutlined as DeleteIcon,
  EditOutlined as EditIcon,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Cell } from "react-table";

import { UsersType } from "api/users/types";
import { hasPermissions } from "components/stores/UserStore";

interface Props<T extends {}> extends Cell<T> {
  handleOpenEdit: (ctx?: T) => void;
  handleOpenDelete: (ctx?: T) => void;
}

const ActionsFormatter = ({
  row,
  handleOpenEdit,
  handleOpenDelete,
}: // handleOpenEdit,
// handleOpenDelete,
Props<UsersType>) => {
  return (
    <div>
      {hasPermissions("update:users") && (
        <IconButton
          // data-testid="edit-port-button"
          color="warning"
          onClick={() => handleOpenEdit(row.original)}
        >
          <EditIcon />
        </IconButton>
      )}
      {hasPermissions("delete:users") && (
        <IconButton
          // data-testid="delete-port-button"
          color="error"
          onClick={() => handleOpenDelete(row.original)}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </div>
  );
};

export default ActionsFormatter;
