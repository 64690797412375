const logsKeys = {
  all: ["logs"] as const,
  list: () => [...logsKeys.all, "list"],
  listFiltered: (filters: Record<string, unknown>) => [
    ...logsKeys.list(),
    filters,
  ],
};

export { logsKeys };
