import { LoadingButton } from "@mui/lab";
import { Box, Button, DialogActions, DialogContent } from "@mui/material";
import { MutationStatus, useQuery } from "@tanstack/react-query";
import { Form as FormikForm, FormikProvider, useFormik } from "formik";
import {
  filter as _filter,
  find as _find,
  map as _map,
  sortBy as _sortBy,
} from "lodash";
import React, { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";

// COMPONENTS:

import { getCustomers } from "api/customers";
import { customersKeys } from "api/customers/queries";
import { CustomersType } from "api/customers/types";
import FormikAutocomplete from "components/forms/FormikAutocomplete";

export type SpouseFormValues = {
  spouse: string;
};

interface ISpouseForm {
  handleSubmit: (values: SpouseFormValues) => void;
  handleClose: () => void;
  initialValues?: SpouseFormValues;
  submitStatus?: MutationStatus;
  customerId: string;
}

const SpouseForm: FC<ISpouseForm> = ({
  handleSubmit,
  handleClose,
  initialValues = {
    spouse: "",
  },
  submitStatus,
  customerId,
}) => {
  const schema = yup.object({
    spouse: yup.string().nullable().required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  const { data: spouses, isLoading: areSpousesLoading } = useQuery(
    customersKeys.list(),
    async () => {
      const { data: res } = await getCustomers({ limit: 10_000 });
      return res.data;
    },
  );

  const customer = useMemo(() => {
    if (spouses?.length) {
      return _find(spouses, (spouse) => spouse._id === customerId);
    }

    return null;
  }, [spouses, customerId]);
  const spouseOptions = useMemo(() => {
    if (spouses?.length) {
      // const customer = _find(spouses, (spouse) => spouse._id === customerId);
      let filteredSpouses = _filter(
        spouses,
        (spouse) => spouse._id !== customerId,
      );

      return filteredSpouses.map((spouse) => ({
        value: spouse._id,
        label: `${spouse.firstName} ${spouse.lastName}`,
      }));
    }

    return [];
  }, [spouses, customerId]);

  return (
    <FormikProvider value={formik}>
      <FormikForm>
        <DialogContent>
          <FormikAutocomplete
            label={<FormattedMessage id="CREDIT_EVALUATIONS.SPOUSE" />}
            name="spouse"
            loading={areSpousesLoading}
            // onInputChange={(event, newInputValue) => {
            //   setSearch(newInputValue);
            // }}
            options={spouseOptions}
            filterOptions={(options, { inputValue }) => {
              if (!spouses?.length) return [];
              let matchedSpouses: CustomersType[] = [];

              // Find Matched Spouses
              matchedSpouses = _map(spouses, (spouse) => {
                let score = 0;

                inputValue.split(" ").forEach((search) => {
                  let wordScore = 0;
                  if (search) {
                    if (
                      spouse.email.toLowerCase().includes(search.toLowerCase())
                    ) {
                      wordScore = 3;
                    } else if (
                      spouse.lastName
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      wordScore = 2;
                    } else if (
                      spouse.firstName
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    ) {
                      wordScore = 1;
                    }
                  } else {
                    if (
                      spouse.lastName === customer?.lastName &&
                      spouse.state === customer?.state
                    ) {
                      wordScore = 2;
                    } else if (spouse.lastName === customer?.lastName) {
                      wordScore = 1;
                    }
                  }

                  if (wordScore > 0) score = wordScore;
                });

                return {
                  ...spouse,
                  score,
                };
              });
              // Remove with score 0
              //@ts-expect-error
              matchedSpouses = _filter(
                matchedSpouses,
                (spouse: CustomersType & { score: number }) =>
                  Boolean(spouse.score),
              );

              // Sort Matched Spouses
              matchedSpouses = _sortBy(matchedSpouses, (spouse) => [
                //@ts-expect-error
                spouse.score,
                spouse.firstName,
              ]);

              return _map(matchedSpouses, (spouse) => ({
                value: spouse._id,
                label: `${spouse.firstName} ${spouse.lastName} (${spouse.email})`,
              })).slice(0, 100);
            }}
            renderOption={(props, option) => (
              <Box component="li" {...props} key={option.value}>
                {option.label}
              </Box>
            )}
          />
        </DialogContent>

        <DialogActions>
          <Button
            // data-testid="new-edit-port-close-button"
            onClick={handleClose}
          >
            <FormattedMessage id="GLOBAL.CLOSE" />
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={submitStatus === "loading"}
          >
            <FormattedMessage id="GLOBAL.SAVE" />
          </LoadingButton>
        </DialogActions>
      </FormikForm>
    </FormikProvider>
  );
};

export default SpouseForm;
