import { Box, TextField } from "@mui/material";
import {
  DatePicker,
  DatePickerProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useField } from "formik";
import React, { useMemo } from "react";

import { MakeOptional } from "utils/types";

import FieldErrorFeedbackFormatter from "../FieldErrorFeedbackFormatter";

interface FormikDatePickerProps<T extends Date>
  extends MakeOptional<
    DatePickerProps<T, any>,
    "value" | "onChange" | "renderInput"
  > {
  name: string;
  label: string;
  timezone?: string;
}

const FormikDatePicker = <T extends Date>({
  name,
  label,
  timezone = "default",
  ...rest
}: FormikDatePickerProps<T>) => {
  const [field, meta, helpers] = useField<Date | string | null>(name);

  let styles = useMemo(() => {
    if (meta.touched && !!meta.error) {
      return {
        // color: "error.main",
        ".MuiOutlinedInput-notchedOutline": {
          borderColor: "error.main",
        },
      };
    }

    return {};
  }, [meta]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        //@ts-expect-error
        value={field.value ? dayjs(field.value) : null}
        label={label}
        sx={{
          width: "100%",
          ...styles,
        }}
        onChange={(value: any) => {
          if (value) {
            helpers.setValue(dayjs(value).toDate());
          } else {
            helpers.setValue(null);
          }
        }}
        timezone={timezone}
        renderInput={(params) => <TextField {...params} />}
        {...rest}
      />
      {meta.touched && meta.error ? (
        <Box
          sx={{ fontSize: "0.75rem", color: "error.main", pl: 1.8, pt: 0.1 }}
        >
          <FieldErrorFeedbackFormatter error={meta.error} />
        </Box>
      ) : (
        <></>
      )}
    </LocalizationProvider>
  );
};

export default FormikDatePicker;
