import { Paper } from "@mui/material";
import { QueryStatus } from "@tanstack/react-query";
import React, { FC } from "react";
// import { useIntl } from "react-intl";
import { CellProps, Column } from "react-table";

import { LoanApplicationsType } from "api/loanApplications/types";
import Table from "components/Table";
import { dateFormatter } from "components/Table/formatters/dateFormatter";
import { precisePercentageFormatter } from "components/Table/formatters/percentageFormatter";
import { priceFormatter } from "components/Table/formatters/priceFormatter";
import { loanAccountTypeFormatter } from "containers/loanApplications/LoanApplicationsTable/formatters/loanAccountTypeFormatter";
import { loanStatusFormatter } from "containers/loanApplications/LoanApplicationsTable/formatters/loanStatusFormatter.tsx";
// import { loanHubspotFormatter } from "containers/loanApplications/LoanApplicationsTable/formatters/loanHubspotFormatter";

interface Props {
  data: LoanApplicationsType[];
  status: QueryStatus;
}
const LoanApplicationsTable: FC<Props> = ({ data, status }) => {
  // const intl = useIntl();

  const columns: Column<LoanApplicationsType>[] = [
    // {
    //   accessor: "upToDate",
    //   Header: "Hubspot",
    //   Cell: ({ value }: CellProps<LoanApplicationsType>) =>
    //     loanHubspotFormatter(value)
    // },
    {
      accessor: "name",
      Header: "Loan Name",
    },
    {
      accessor: "lender",
      Header: "Lender",
    },
    {
      accessor: "loanAmount",
      Header: "Loan Amount",
      Cell: ({ value }: CellProps<LoanApplicationsType>) =>
        priceFormatter(value),
    },
    {
      accessor: "monthlyPayment",
      Header: "Monthly Payment",
      Cell: ({ value }: CellProps<LoanApplicationsType>) =>
        priceFormatter(value),
    },
    {
      accessor: "term",
      Header: "Term",
    },
    {
      accessor: "creditInquiry",
      Header: "Credit Inquiry",
      Cell: ({ value }: CellProps<LoanApplicationsType>) => value.join(", "),
    },
    {
      accessor: "applicationDate",
      Header: "Application Date",
      Cell: ({ value }: CellProps<LoanApplicationsType>) =>
        dateFormatter(value),
    },
    {
      accessor: "status",
      Header: "Status",
      Cell: ({ value }: CellProps<LoanApplicationsType>) =>
        loanStatusFormatter(value),
    },
    {
      accessor: "accountType",
      Header: "Account Type",
      Cell: ({ value }: CellProps<LoanApplicationsType>) =>
        loanAccountTypeFormatter(value),
    },
    {
      accessor: "interestRate",
      Header: "Interest Rate",
      Cell: ({ value }: CellProps<LoanApplicationsType>) =>
        precisePercentageFormatter(value),
    },
    {
      accessor: "originationFeePercentage",
      Header: "Origination  Percentage",
      Cell: ({ value }: CellProps<LoanApplicationsType>) =>
        precisePercentageFormatter(value),
    },
    {
      accessor: "originationFee",
      Header: "Origination Fee Amount",
      Cell: ({ value }: CellProps<LoanApplicationsType>) =>
        priceFormatter(value),
    },
    {
      accessor: "apr",
      Header: "APR",
      Cell: ({ value }: CellProps<LoanApplicationsType>) =>
        precisePercentageFormatter(value),
    },
    {
      accessor: "reasonCode",
      Header: "Reason Code",
    },
    // {
    //   id: "actions",
    //   Header: intl.formatMessage({ id: "GLOBAL.ACTIONS" }),
    //   Cell: (cell: Cell<LoanApplicationsType>) => (
    //     <LoanApplicationsActionsFormatter
    //       {...cell}
    //       handleOpenEdit={handleOpenEdit}
    //       handleOpenDelete={handleOpenDelete}
    //     />
    //   ),
    //   align: "right"
    // }
  ];
  return (
    <Paper>
      <Table data={data} columns={columns} status={status} manualPagination />
    </Paper>
  );
};

export default LoanApplicationsTable;
