// COMPONENTS:
import { Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";

import CreditReportsTable from "./CreditEvaluationsTable";

const CreditEvaluationsPage = () => {
  const intl = useIntl();

  return (
    <>
      <Helmet title={intl.formatMessage({ id: "CREDIT_EVALUATIONS.TITLE" })} />

      <Typography variant="h2" fontWeight="bold" mt={1}>
        <FormattedMessage id="CREDIT_EVALUATIONS.TABLE.TITLE" />
      </Typography>
      <CreditReportsTable />
    </>
  );
};

export default CreditEvaluationsPage;
