import { Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

import UsersTable from "./UsersTable";

const SingleOrganisationPage = () => {
  return (
    <>
      <Typography variant="h2" fontWeight="bold" mt={1}>
        <FormattedMessage
          id="ORGANISATION_DETAILS.USERS.TABLE.TITLE"
          values={{
            organisationName: "",
          }}
        />
      </Typography>
      <UsersTable />
    </>
  );
};

export default SingleOrganisationPage;
