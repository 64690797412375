import { Add as AddIcon, Search as SearchIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Paper,
  TextField,
} from "@mui/material";
import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { Cell, CellProps, Column } from "react-table";

import { getUsers } from "api/users";
import { usersKeys } from "api/users/queries";
import { UsersType } from "api/users/types";
import { hasPermissions } from "components/stores/UserStore";
import Table from "components/Table";
import useRemoteTableLogic from "components/Table/useRemoteTableLogic";
import { useModal } from "utils/hooks/useModal";

import DeleteUserModal from "./components/DeleteUserModal";
import EditUserModal from "./components/EditUserModal";
import NewUserModal from "./components/NewUserModal";
import UsersActionsFormatter from "./formatters/UsersActionsFormatter";
import { useUsersFilter } from "./useUsersFilter";

const UsersTable: FC = () => {
  const { organisationId } = useParams<{ organisationId: string }>();

  const intl = useIntl();

  const { filters, searchField } = useUsersFilter({
    filter: [
      {
        field: "organisation",
        operator: "is",
        value: organisationId ?? "",
      },
    ],
  });

  const { isOpen, handleClose, handleOpen } = useModal<UsersType>();
  const {
    isOpen: isOpenDelete,
    handleClose: handleCloseDelete,
    handleOpen: handleOpenDelete,
    context: deleteContext,
  } = useModal<UsersType>();

  const {
    isOpen: isEditOpen,
    handleClose: handleCloseEdit,
    handleOpen: handleOpenEdit,
    context: editContext,
  } = useModal<UsersType>();

  const { data, status, sortOptions, paginationOptions } = useRemoteTableLogic(
    usersKeys.listFiltered(filters),
    getUsers,
    filters,
  );

  const columns: Column<UsersType>[] = [
    {
      accessor: "role",
      Header: intl.formatMessage({ id: "GLOBAL.ROLE" }),
      Cell: ({ value }: CellProps<UsersType>) => (
        <Box textTransform="capitalize">{value}</Box>
      ),
    },
    {
      accessor: "email",
      Header: intl.formatMessage({ id: "GLOBAL.EMAIL" }),
    },
    {
      accessor: "name",
      Header: intl.formatMessage({ id: "GLOBAL.NAME" }),
    },
    {
      id: "confirmed", // Make accessor "confirmed"
      Header: intl.formatMessage({ id: "GLOBAL.CONFIRMED" }),
      Cell: ({ row }: CellProps<UsersType>) =>
        Boolean(row.values.name) ? (
          <FormattedMessage id="GLOBAL.CONFIRMED" />
        ) : (
          <FormattedMessage id="GLOBAL.NOT_CONFIRMED" />
        ),
    },
    // {
    //   accessor: "createdAt",
    //   Header: intl.formatMessage({ id: "GLOBAL.DATE" })
    // },
    {
      id: "actions",
      Header: intl.formatMessage({ id: "GLOBAL.ACTIONS" }),
      Cell: (cell: Cell<UsersType>) => (
        <UsersActionsFormatter
          {...cell}
          handleOpenEdit={handleOpenEdit}
          handleOpenDelete={handleOpenDelete}
        />
      ),
      align: "right",
    },
  ];

  return (
    <>
      <Grid container sx={{ py: 2 }} spacing={2}>
        <Grid item xs={5} lg={3}>
          <TextField
            fullWidth
            label={<FormattedMessage id="GLOBAL.SEARCH" />}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            helperText={<FormattedMessage id="USERS.TABLE.SEARCH_BY" />}
            {...searchField}
          />
        </Grid>
        <Grid item xs={1} lg={5}></Grid>
        <Grid item xs={6} lg={4}>
          {hasPermissions("write:users") && (
            <Box ml="auto" marginTop={1}>
              <Button
                sx={{
                  ml: "auto",
                  whiteSpace: "nowrap",
                }}
                variant="contained"
                onClick={() => handleOpen()}
              >
                <AddIcon sx={{ mr: 0.5 }} />
                <FormattedMessage id="USERS.ADD" />
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>

      <Paper>
        <Table
          data={data}
          columns={columns}
          sort={sortOptions}
          pagination={paginationOptions}
          status={status}
        />
      </Paper>
      {hasPermissions("write:users") && (
        <>
          <NewUserModal isOpen={isOpen} handleClose={handleClose} />
        </>
      )}
      {hasPermissions("delete:users") && (
        <>
          <DeleteUserModal
            isOpen={isOpenDelete}
            handleClose={handleCloseDelete}
            user={deleteContext}
          />
        </>
      )}
      {hasPermissions("update:users") && (
        <EditUserModal
          isOpen={isEditOpen}
          handleClose={handleCloseEdit}
          user={editContext}
        />
      )}
    </>
  );
};

export default UsersTable;
