import { Edit as EditIcon } from "@mui/icons-material";
import { Dialog, DialogTitle, Divider, Stack } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { FormattedMessage } from "react-intl";

import { editCustomer as editCustomerAPI } from "api/customers";
import { customersKeys } from "api/customers/queries";
import { CustomersType, EditCustomerType } from "api/customers/types";
import { generateCustomerValues } from "utils/generateCustomerValues/generateCustomerValues";

import CustomerForm, { CustomerFormValues } from "./CustomerForm";

interface EditWasteModalProps {
  isOpen: boolean;
  handleClose: () => void;
  customer: CustomersType | undefined;
}

const EditCustomerModal = ({
  isOpen,
  handleClose,
  customer,
}: EditWasteModalProps) => {
  const queryClient = useQueryClient();

  const initialValues: CustomerFormValues = generateCustomerValues(customer);

  const { mutateAsync: editCustomer, status } = useMutation(
    async (data: EditCustomerType) => {
      const res = await editCustomerAPI(data);
      await queryClient.invalidateQueries(customersKeys.details(data._id));
      return res.data;
    },
  );

  const handleSubmit = async (values: CustomerFormValues) => {
    const mappedValues: EditCustomerType = {
      _id: customer!._id,
      ...values,
    };
    await editCustomer(mappedValues, {
      onSuccess: () => handleClose(),
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="lg">
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <EditIcon sx={{ mr: 1 }} />
          <FormattedMessage id="CUSTOMERS.EDIT.TITLE" />
        </Stack>
      </DialogTitle>
      <Divider />

      <CustomerForm
        edit
        submitStatus={status}
        initialValues={initialValues}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        customerId={customer?._id}
      />
    </Dialog>
  );
};

export default EditCustomerModal;
