import { Box, Divider, Stack, Typography } from "@mui/material";
import React, { FC } from "react";
import { FormattedMessage } from "react-intl";

import { CreditEvaluationsType } from "api/creditEvaluations/types";
import { ReactComponent as ExperianSVG } from "assets/icons/experian.svg";
import dayjs from "utils/dayjs";
import { generateCreditScoreColor } from "utils/generateCreditScoreColor/generateCreditScoreColor";

interface ICreditScoreFormatter {
  creditScore: CreditEvaluationsType["recentInquiries"][0] & { score: number };
}

const CreditScoreFormatter: FC<ICreditScoreFormatter> = ({ creditScore }) => {
  return (
    <Stack direction="column" alignItems="center" sx={{ my: 2 }}>
      <Box
        sx={{
          mb: 3,
          svg: {
            maxWidth: "100%",
            maxHeight: "80px",
            objectFit: "contain",
          },
        }}
      >
        <ExperianSVG />
      </Box>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          border: (t) =>
            `3px solid ${generateCreditScoreColor(creditScore.score)}`,
          width: "80px",
          height: "80px",
          borderRadius: "50%",
        }}
      >
        <Typography variant="h4">{creditScore.score}</Typography>
      </Stack>

      <Stack alignItems="center" justifyContent="center" sx={{ mt: 4 }}>
        <Typography fontWeight="bold">
          <FormattedMessage id="CREDIT_EVALUATIONS.CREDIT_SCORE_FORMAT.LAST_6_MONTHS" />
        </Typography>
        <Divider sx={{ width: "100%", my: 1 }} />
        <Typography>{creditScore.lastSixMonths}</Typography>

        <Typography fontWeight="bold" sx={{ mt: 1 }}>
          <FormattedMessage id="CREDIT_EVALUATIONS.CREDIT_SCORE_FORMAT.LAST_12_MONTHS" />
        </Typography>
        <Divider sx={{ width: "100%", my: 1 }} />
        <Typography>{creditScore.lastTwelveMonths}</Typography>

        <Typography fontWeight="bold" sx={{ mt: 1 }}>
          Hard Inquiries
        </Typography>
        <Divider sx={{ width: "100%", my: 1 }} />

        <Box>
          {creditScore.inquiries
            ?.sort((inquiryA: { date: Date }, inquiryB: { date: Date }) =>
              dayjs(inquiryA.date).isAfter(dayjs(inquiryB.date)) ? 1 : -1,
            )
            .map((inquiry) => (
              <Stack direction="row" justifyContent="space-between" spacing={3}>
                <Typography variant="body2">{inquiry.name}</Typography>
                <Typography variant="body2">
                  {dayjs(inquiry.date).format("MM/YYYY")}
                </Typography>
              </Stack>
            ))}
        </Box>
      </Stack>
    </Stack>
  );
};

export default CreditScoreFormatter;
