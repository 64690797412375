import { Add as AddIcon } from "@mui/icons-material";
import { Dialog, DialogTitle, Divider, Stack } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { FormattedMessage } from "react-intl";

import { addCreditEvaluationIncome } from "api/creditEvaluations";
import { creditEvaluationsKeys } from "api/creditEvaluations/queries";

import AddIncomeForm, { IncomeFormValues } from "./IncomeForm";

interface AddIncomeModalProps {
  creditEvaluationId: string;
  isOpen: boolean;
  handleClose: () => void;
  initialValues?: any;
}

const AddIncomeModal: FC<AddIncomeModalProps> = ({
  creditEvaluationId,
  isOpen,
  handleClose,
  initialValues = undefined,
}) => {
  const queryClient = useQueryClient();

  const { mutateAsync: addIncome, status } = useMutation(async (data: any) => {
    const res = await addCreditEvaluationIncome(creditEvaluationId, data);
    await queryClient.invalidateQueries(
      creditEvaluationsKeys.details(creditEvaluationId),
    );

    return res.data;
  });

  const handleSubmit = async (values: IncomeFormValues) => {
    const mappedValues: any = {
      ...values,
    };

    await addIncome(mappedValues, {
      onSuccess: () => handleClose(),
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md">
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <AddIcon sx={{ mr: 1 }} />
          <FormattedMessage id="CREDIT_EVALUATIONS.INCOMES.ADD" />
        </Stack>
      </DialogTitle>
      <Divider />

      <AddIncomeForm
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        submitStatus={status}
        initialValues={initialValues}
      />
    </Dialog>
  );
};

export default AddIncomeModal;
