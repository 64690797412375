import { Edit } from "@mui/icons-material";
import { Dialog, DialogTitle, Divider, Stack } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";

import { editCreditEvaluationHubspotDealId as editCreditEvaluationHubspotDealIdAPI } from "api/creditEvaluations";
import { creditEvaluationsKeys } from "api/creditEvaluations/queries";

import HubspotDealIdForm, {
  HubspotDealIdFormValues,
} from "./HubspotDealIdForm";

interface EditHubspotDealIdModalProps {
  isOpen: boolean;
  handleClose: () => void;
  hubspotDealId: string;
  creditEvaluationId: string;
}

const EditHubspotDealIdModal = ({
  isOpen,
  handleClose,
  hubspotDealId,
  creditEvaluationId,
}: EditHubspotDealIdModalProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const queryClient = useQueryClient();

  const { mutateAsync: editCreditEvaluationHubspotDealId, status } =
    useMutation(async (data: { hubspotDealId: string }) => {
      const res = await editCreditEvaluationHubspotDealIdAPI(
        creditEvaluationId,
        data.hubspotDealId,
      );
      await queryClient.invalidateQueries(
        creditEvaluationsKeys.details(creditEvaluationId),
      );
      return res.data;
    });

  const handleSubmit = async (values: HubspotDealIdFormValues) => {
    await editCreditEvaluationHubspotDealId(values, {
      onSuccess: () => handleClose(),
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <Edit sx={{ mr: 1 }} />
          Edit Hubspot Deal Id
        </Stack>
      </DialogTitle>
      <Divider />

      <HubspotDealIdForm
        initialValues={{ hubspotDealId }}
        submitStatus={status}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
      />
    </Dialog>
  );
};

export default EditHubspotDealIdModal;
