// COMPONENTS:
// import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Paper, Stack, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";
import { useParams } from "react-router-dom";

import { getSingleCustomer } from "api/customers";
// import {
//   pullCustomerFromHubspot as pullCustomerFromHubspotAPI,
//   pushCustomerToHubspot as pushCustomerToHubspotAPI
// } from "api/customers";
import { customersKeys } from "api/customers/queries";
import { CustomersType } from "api/customers/types";
import { hasPermissions } from "components/stores/UserStore";
import { useModal } from "utils/hooks/useModal";

import DeleteCustomerModal from "../CustomersTable/components/DeleteCustomerModal";
import EditCustomerModal from "../CustomersTable/components/EditCustomerModal";
import AddSocialNumberModal from "./components/AddSocialNumberModal";
import CustomerCreditEvaluationsTable from "./components/CustomerCreditEvaluationsTable";
import CustomerOverview from "./components/CustomerOverview";
import UpdateGeneralInfoModal from "./components/UpdateGeneralInfoModal";
// import UsersTable from "./UsersTable";

const SingleCustomerPage = () => {
  const intl = useIntl();
  const { customerId } = useParams<{ customerId: string }>();

  const {
    isOpen: isAddSocialNumberOpen,
    handleClose: handleCloseAddSocialNumber,
    handleOpen: handleOpenAddSocialNumber,
  } = useModal<CustomersType>();

  const {
    isOpen: isUpdateGeneralOpen,
    handleClose: handleCloseUpdateGeneral,
    handleOpen: handleOpenUpdateGeneral,
  } = useModal<CustomersType>();

  const {
    isOpen: isEditOpen,
    handleClose: handleCloseEdit,
    handleOpen: handleOpenEdit,
    context: editContext,
  } = useModal<CustomersType>();

  const {
    isOpen: isOpenDelete,
    handleClose: handleCloseDelete,
    handleOpen: handleOpenDelete,
    context: deleteContext,
  } = useModal<CustomersType>();

  const { data: customer, isLoading: isCustomerLoading } = useQuery(
    customersKeys.details(customerId),
    async () => {
      const { data: res } = await getSingleCustomer(customerId);
      return res.data;
    },
  );

  // const {
  //   mutateAsync: pushCustomerToHubspot,
  //   isLoading: isCustomerToHubspotLoading
  // } = useMutation(async () => {
  //   const res = await pushCustomerToHubspotAPI(customerId);
  //   await queryClient.invalidateQueries(customersKeys.details(customerId));

  //   return res.data;
  // });

  // const {
  //   mutateAsync: pullCustomerFromHubspot,
  //   isLoading: isCustomerFromHubspotLoading
  // } = useMutation(async () => {
  //   const res = await pullCustomerFromHubspotAPI(customerId);
  //   await queryClient.invalidateQueries(customersKeys.details(customerId));

  //   return res.data;
  // });

  return (
    <>
      <Helmet
        title={intl.formatMessage(
          { id: "CUSTOMERS.DETAILS.TITLE" },
          {
            name: customer
              ? `${customer.firstName} ${customer.middleName} ${customer.lastName}`
              : "",
            email: customer?.email ?? "",
          },
        )}
      />

      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h2" fontWeight="bold" mb={3} mt={1}>
          <FormattedMessage
            id="CUSTOMER.DETAILS.HEADER"
            values={{
              userName: (
                <>
                  {customer?.firstName} {customer?.middleName}{" "}
                  {customer?.lastName}
                </>
              ),
            }}
          />
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          {hasPermissions("update:customers") && (
            <Button
              onClick={() => handleOpenEdit(customer)}
              variant="contained"
            >
              Edit Customer
            </Button>
          )}
          {hasPermissions("delete:customers") && (
            <Button
              onClick={() => handleOpenDelete(customer)}
              variant="contained"
              color="error"
              sx={{ color: "#fff" }}
            >
              Delete Customer
            </Button>
          )}
        </Stack>
      </Stack>

      {Boolean(customer?.cbcErrorMessage) && (
        <Box>
          <Alert sx={{ mb: 3 }} severity="error" color="error">
            <Typography fontWeight="bold">CBC Error Message</Typography>
            <Typography>{customer?.cbcErrorMessage}</Typography>

            {hasPermissions("update:customers") ? (
              customer?.cbcErrorMessage?.includes("SS# REQUIRED") ? (
                <Button
                  sx={{ mt: 1 }}
                  variant="contained"
                  onClick={() => handleOpenAddSocialNumber()}
                >
                  Add Social Number
                </Button>
              ) : (
                <Button
                  sx={{ mt: 1 }}
                  variant="contained"
                  onClick={() => handleOpenUpdateGeneral()}
                >
                  Update General Info
                </Button>
              )
            ) : null}
          </Alert>
        </Box>
      )}

      <Stack direction="row" spacing={2} mb={3} justifyContent="end">
        {/* {hasPermissions("update:customers") && (
          <LoadingButton
            sx={{
              background: "#ff7a59",
              "&:hover": {
                background: "#ef6a49"
              }
            }}
            variant="contained"
            loading={isCustomerToHubspotLoading}
            onClick={() => pushCustomerToHubspot()}
          >
            Push to Hubspot
          </LoadingButton>
        )}
        {hasPermissions("update:customers") && (
          <LoadingButton
            sx={{
              background: "#ff7a59",
              "&:hover": {
                background: "#ef6a49"
              }
            }}
            variant="contained"
            loading={isCustomerFromHubspotLoading}
            onClick={() => pullCustomerFromHubspot()}
          >
            Pull from Hubspot
          </LoadingButton>
        )} */}
      </Stack>

      <Paper>
        <CustomerOverview customer={customer} isLoading={isCustomerLoading} />
      </Paper>

      <Typography variant="h4" fontWeight="bold" mb={2} mt={5}>
        <FormattedMessage id="CUSTOMER.DETAILS.CREDIT_EVALUATIONS.TITLE" />
      </Typography>
      <CustomerCreditEvaluationsTable customerId={customerId} />
      {/* <UsersTable /> */}

      <AddSocialNumberModal
        isOpen={isAddSocialNumberOpen}
        handleClose={handleCloseAddSocialNumber}
        customerId={customerId}
      />
      {customer && (
        <UpdateGeneralInfoModal
          isOpen={isUpdateGeneralOpen}
          handleClose={handleCloseUpdateGeneral}
          customer={customer}
        />
      )}

      {hasPermissions("update:customers") && (
        <>
          <EditCustomerModal
            isOpen={isEditOpen}
            handleClose={handleCloseEdit}
            customer={editContext}
          />
        </>
      )}

      {hasPermissions("delete:customers") && (
        <>
          <DeleteCustomerModal
            isOpen={isOpenDelete}
            handleClose={handleCloseDelete}
            customer={deleteContext}
          />
        </>
      )}
    </>
  );
};

export default SingleCustomerPage;
