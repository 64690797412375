import { Add as AddIcon } from "@mui/icons-material";
import { Dialog, DialogTitle, Divider, Stack } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { FormattedMessage } from "react-intl";

import { creditEvaluationsKeys } from "api/creditEvaluations/queries";
import { updateCustomerSpouse as updateCustomerSpouseAPI } from "api/customers";

import SpouseForm, { SpouseFormValues } from "./SpouseForm";

interface AddSpouseModalProps {
  isOpen: boolean;
  handleClose: () => void;
  customerId: string;
  creditEvaluationId: string;
}

const AddSpouseModal = ({
  isOpen,
  handleClose,
  customerId,
  creditEvaluationId,
}: AddSpouseModalProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const queryClient = useQueryClient();

  const { mutateAsync: updateCustomerSpouse, status } = useMutation(
    async (data: { spouse: string }) => {
      const res = await updateCustomerSpouseAPI(data.spouse, customerId);
      await queryClient.invalidateQueries(
        creditEvaluationsKeys.details(creditEvaluationId),
      );
      return res.data;
    },
  );

  const handleSubmit = async (values: SpouseFormValues) => {
    await updateCustomerSpouse(values, {
      onSuccess: () => handleClose(),
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <AddIcon sx={{ mr: 1 }} />
          <FormattedMessage id="CREDIT_EVALUATIONS.ADD_SPOUSE" />
        </Stack>
      </DialogTitle>
      <Divider />

      <SpouseForm
        submitStatus={status}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        customerId={customerId}
      />
    </Dialog>
  );
};

export default AddSpouseModal;
