import { LoadingButton } from "@mui/lab";
import { Grid, Stack } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Form as FormikForm, FormikProvider, useFormik } from "formik";
import React, { FC } from "react";

import { editCreditEvaluationLoanAffordabilityRate } from "api/creditEvaluations";
import { creditEvaluationsKeys } from "api/creditEvaluations/queries";
import FormikNumberField from "components/forms/FormikNumberField";
import { hasPermissions } from "components/stores/UserStore";

interface ILoanAffordabilitiesRate {
  creditEvaluationId: string;
  rate: number | undefined;
}

const LoanAffordabilitiesRate: FC<ILoanAffordabilitiesRate> = ({
  creditEvaluationId,
  rate,
}) => {
  const queryClient = useQueryClient();

  const { mutateAsync: editLoanAffordabilitiesRate, isLoading } = useMutation(
    async (value: number) => {
      const res = await editCreditEvaluationLoanAffordabilityRate(
        creditEvaluationId,
        value,
      );

      await queryClient.invalidateQueries(
        creditEvaluationsKeys.details(creditEvaluationId),
      );
      return res.data;
    },
  );

  const handleSubmit = async (values: any) => {
    await editLoanAffordabilitiesRate(values.rate);
  };

  const formik = useFormik({
    initialValues: {
      rate,
    },
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <FormikProvider value={formik}>
      <FormikForm>
        <Grid container spacing={2} sx={{ width: "100%" }}>
          <Grid item xs={6}>
            <FormikNumberField
              name="rate"
              label="Rate"
              sx={{ m: 0 }}
              suffix="%"
              disabled={!hasPermissions("update:credit-evaluations")}
            />
          </Grid>

          <Grid item xs={6}>
            <Stack
              alignItems="end"
              justifyContent="center"
              sx={{ width: "100%", height: "100%" }}
            >
              {hasPermissions("update:credit-evaluations") && (
                <LoadingButton
                  type="submit"
                  variant="contained"
                  sx={{ ml: "auto" }}
                  loading={isLoading}
                >
                  Save
                </LoadingButton>
              )}
            </Stack>
          </Grid>
        </Grid>
      </FormikForm>
    </FormikProvider>
  );
};

export default LoanAffordabilitiesRate;
