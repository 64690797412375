import { Add } from "@mui/icons-material";
import { Box, Button, Checkbox, Paper, Stack } from "@mui/material";
import { QueryStatus } from "@tanstack/react-query";
import { mean } from "lodash";
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { CellProps, Column } from "react-table";

import {
  CreditEvaluationIncome,
  CreditEvaluationIncomeSource,
} from "api/creditEvaluations/types";
import { hasPermissions } from "components/stores/UserStore";
import Table from "components/Table";
import { dateFormatter } from "components/Table/formatters/dateFormatter";
import { percentageFormatter } from "components/Table/formatters/percentageFormatter";
import { priceFormatter } from "components/Table/formatters/priceFormatter";
import { useModal } from "utils/hooks/useModal";

import AddIncomeModal from "../../AddIncomeModal";

interface IncomesSelfEmploymentTableProps {
  income: CreditEvaluationIncome;
  creditEvaluationId: string;
  data: CreditEvaluationIncomeSource[];
  status: QueryStatus;
}
const IncomesSelfEmploymentTable: FC<IncomesSelfEmploymentTableProps> = ({
  income,
  creditEvaluationId,
  data,
  status,
}) => {
  const intl = useIntl();
  const [selectedRows, setSelectedRows] = React.useState<string[]>([]);

  const {
    isOpen: isOpenAddIncome,
    handleClose: handleCloseAddIncome,
    handleOpen: handleOpenAddIncome,
    context: addIncomeContext,
  } = useModal<any>();

  const columns: Column<CreditEvaluationIncomeSource>[] = [
    {
      id: "#",
      Header: intl.formatMessage({
        id: "CREDIT_EVALUATIONS.INCOMES.TABLE.ID",
      }),

      Cell: ({ row }: CellProps<CreditEvaluationIncomeSource>) => (
        <Stack
          direction="row"
          alignItems="center"
          // sx={{ ml: -1.5 }}
          spacing={1}
        >
          {"#" + (row.index + 1)}

          <Checkbox
            // @ts-expect-error
            checked={selectedRows.includes(row.original._id)}
            onChange={() => {
              setSelectedRows((p) => {
                //@ts-expect-error
                if (p.includes(row.original._id)) {
                  //@ts-expect-error
                  return p.filter((i) => i !== row.original._id);
                }

                //@ts-expect-error
                return [...p, row.original._id];
              });
            }}
          />
        </Stack>
      ),
    },

    {
      accessor: "date",
      Header: "Date",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{dateFormatter(value)}</>
      ),
    },
    {
      accessor: "grossRevenue",
      Header: "Gross Revenue",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{priceFormatter(value)}</>
      ),
    },
    {
      accessor: "netProfit",
      Header: "Net Profit",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{priceFormatter(value)}</>
      ),
    },
    {
      accessor: "percentageOfProfit",
      Header: "Percentage Of Profit",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{percentageFormatter(value, 100)}</>
      ),
    },
    {
      accessor: "averageMonthlyGrossRevenue",
      Header: "Avg. Monthly Gross Revenue",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{priceFormatter(value)}</>
      ),
    },
    {
      accessor: "yearOverYearGrossGrowth",
      Header: "Year over Year Growth",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{percentageFormatter(value, 100)}</>
      ),
    },
    {
      accessor: "averageMonthlyNetProfit",
      Header: "Avg. Monthly Net Profit",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{priceFormatter(value)}</>
      ),
    },
    {
      accessor: "yearOverYearNetGrowth",
      Header: "Year over Year Growth",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{percentageFormatter(value || undefined, 100)}</>
      ),
    },
    {
      accessor: "annualWages",
      Header: "Annual Wages",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{priceFormatter(value)}</>
      ),
    },
    {
      accessor: "mothlyWage",
      Header: "Monthly Wage",
      Cell: ({ value }: CellProps<CreditEvaluationIncomeSource>) => (
        <>{priceFormatter(value)}</>
      ),
    },
  ];

  return (
    <>
      <Paper>
        <Table
          data={data}
          columns={columns}
          status={status}
          additionalRows={
            selectedRows.length >= 2
              ? [
                  {
                    cells: [
                      {
                        value: (
                          <Box sx={{ width: 10 }}>
                            <Button
                              sx={{
                                ml: "auto",
                              }}
                              variant="contained"
                              onClick={() => {
                                const average = mean(
                                  data
                                    //@ts-expect-error
                                    .filter((d) => selectedRows.includes(d._id))
                                    .map((d) => d.averageMonthlyNetProfit),
                                );

                                handleOpenAddIncome({
                                  type: "additional-income",
                                  incomes: [
                                    {
                                      date: null,
                                      source: income.source ?? "",
                                      monthlyBenefit: average.toFixed(2),
                                    },
                                  ],
                                });
                              }}
                            >
                              <Add sx={{ mr: 0.5 }} />
                              Send Average To Additional income
                            </Button>
                          </Box>
                        ),
                      },
                      { value: null },
                      { value: null },
                      { value: null },
                      { value: null },
                      { value: null },
                      { value: null },
                      { value: null },
                      { value: null },
                      { value: null },
                      { value: null },
                    ],
                  },
                ]
              : []
          }
          manualPagination
        />
      </Paper>

      {hasPermissions("update:credit-evaluations") && (
        <AddIncomeModal
          creditEvaluationId={creditEvaluationId}
          isOpen={isOpenAddIncome}
          handleClose={handleCloseAddIncome}
          initialValues={addIncomeContext}
        />
      )}
    </>
  );
};

export default IncomesSelfEmploymentTable;
