import { Box, Divider, Skeleton, Stack, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";

const CreditScoreFormatterLoading = () => {
  return (
    <Stack direction="column" alignItems="center" sx={{ my: 2 }}>
      <Box
        sx={{
          mb: 3,
          svg: {
            maxWidth: "100%",
            maxHeight: "80px",
            objectFit: "contain",
          },
        }}
      >
        <Skeleton sx={{ height: "80px", width: "220px" }} />
      </Box>
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{
          width: "80px",
          borderWidth: "3px",
          borderColor: "#c3c3c3",
          borderStyle: "solid",
          height: "80px",
          borderRadius: "50%",
        }}
      >
        <Typography variant="h4">
          <Skeleton sx={{ width: "30px" }} />
        </Typography>
      </Stack>

      <Stack alignItems="center" justifyContent="center" sx={{ mt: 4 }}>
        <Typography fontWeight="bold">
          <FormattedMessage id="CREDIT_EVALUATIONS.CREDIT_SCORE_FORMAT.LAST_6_MONTHS" />
        </Typography>
        <Divider sx={{ width: "100%", my: 1 }} />
        <Typography>
          <Skeleton sx={{ width: "30px" }} />
        </Typography>

        <Typography fontWeight="bold" sx={{ mt: 1 }}>
          <FormattedMessage id="CREDIT_EVALUATIONS.CREDIT_SCORE_FORMAT.LAST_12_MONTHS" />
        </Typography>
        <Divider sx={{ width: "100%", my: 1 }} />
        <Typography>
          {" "}
          <Skeleton sx={{ width: "30px" }} />
        </Typography>

        <Typography fontWeight="bold" sx={{ mt: 1 }}>
          Hard Inquiries
        </Typography>
        <Divider sx={{ width: "100%", my: 1 }} />
        <Typography textAlign="center">
          <Skeleton sx={{ width: "280px" }} />
          <Skeleton sx={{ width: "280px" }} />
          <Skeleton sx={{ width: "280px" }} />
        </Typography>
      </Stack>
    </Stack>
  );
};

export default CreditScoreFormatterLoading;
