import { Add as AddIcon, Search as SearchIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Paper,
  TextField,
} from "@mui/material";
import React, { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Cell,
  // CellProps,
  Column,
} from "react-table";

import { getOrganisations } from "api/organisations";
import { organisationsKeys } from "api/organisations/queries";
import { OrganisationsType } from "api/organisations/types";
import { hasPermissions } from "components/stores/UserStore";
import Table from "components/Table";
import useRemoteTableLogic from "components/Table/useRemoteTableLogic";
import { useModal } from "utils/hooks/useModal";

// import DeleteUserModal from "./components/DeleteUserModal";
// import EditUserModal from "./components/EditUserModal";
// import NewUserModal from "./components/NewUserModal";
import EditOrganisationModal from "./components/EditOrganisationModal";
import NewOrganisationModal from "./components/NewOrganisationModal";
import OrganisationsActionsFormatter from "./formatters/OrganisationsActionsFormatter";
import { useOrganisationsFilter } from "./useOrganisationsFilter";

const OrganisationsTable: FC = () => {
  const intl = useIntl();

  const { filters, searchField } = useOrganisationsFilter();

  const { isOpen, handleClose, handleOpen } = useModal<OrganisationsType>();
  const {
    // isOpen: isOpenDelete,
    // handleClose: handleCloseDelete,
    handleOpen: handleOpenDelete,
    // context: deleteContext
  } = useModal<OrganisationsType>();

  const {
    isOpen: isEditOpen,
    handleClose: handleCloseEdit,
    handleOpen: handleOpenEdit,
    context: editContext,
  } = useModal<OrganisationsType>();

  const { data, status, sortOptions, paginationOptions } = useRemoteTableLogic(
    organisationsKeys.list(),
    getOrganisations,
    filters,
  );

  const columns: Column<OrganisationsType>[] = [
    {
      accessor: "type",
      Header: "Type",
      Cell: ({ value }: Cell<OrganisationsType>) => (
        <Box textTransform="capitalize">{value}</Box>
      ),
    },
    {
      accessor: "name",
      Header: "Organisation Name",
    },
    {
      accessor: "email",
      Header: "Organisation Email",
    },
    {
      accessor: "active",
      Header: "Active",
      Cell: ({ value }: Cell<OrganisationsType>) => (value ? "Yes" : "No"),
    },
    {
      accessor: "leadSource",
      Header: "Lead Source",
    },
    {
      accessor: "brand",
      Header: "Brand",
    },
    {
      id: "partnerPayout",
      Header: "Partner Payout",
      Cell: ({ row }: Cell<OrganisationsType>) => {
        if (row.original.partnerPayout.type === "they-invoice") {
          return "They Invoice";
        } else if (row.original.partnerPayout.type === "percentage") {
          return `${row.original.partnerPayout.value}%`;
        }

        return "";
      },
    },

    // {
    //   accessor: "createdAt",
    //   Header: intl.formatMessage({ id: "GLOBAL.DATE" })
    // },
    {
      id: "actions",
      Header: intl.formatMessage({ id: "GLOBAL.ACTIONS" }),
      Cell: (cell: Cell<OrganisationsType>) => (
        <OrganisationsActionsFormatter
          {...cell}
          handleOpenEdit={handleOpenEdit}
          handleOpenDelete={handleOpenDelete}
        />
      ),
      align: "right",
    },
  ];

  return (
    <>
      <Grid container sx={{ py: 2 }} spacing={2}>
        <Grid item xs={5} lg={3}>
          <TextField
            fullWidth
            label={<FormattedMessage id="GLOBAL.SEARCH" />}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            helperText={<FormattedMessage id="ORGANISATIONS.TABLE.SEARCH_BY" />}
            {...searchField}
          />
        </Grid>
        <Grid item xs={1} lg={5}></Grid>
        <Grid item xs={6} lg={4}>
          {hasPermissions("write:organisations") && (
            <Box ml="auto" marginTop={1}>
              <Button
                sx={{
                  ml: "auto",
                  whiteSpace: "nowrap",
                }}
                variant="contained"
                onClick={() => handleOpen()}
              >
                <AddIcon sx={{ mr: 0.5 }} />
                <FormattedMessage id="ORGANISATIONS.ADD" />
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>

      <Paper>
        <Table
          data={data}
          columns={columns}
          sort={sortOptions}
          pagination={paginationOptions}
          status={status}
        />
      </Paper>
      {hasPermissions("write:organisations") && (
        <>
          <NewOrganisationModal isOpen={isOpen} handleClose={handleClose} />
        </>
      )}
      {hasPermissions("update:organisations") && (
        <>
          <EditOrganisationModal
            isOpen={isEditOpen}
            handleClose={handleCloseEdit}
            organisation={editContext}
          />
        </>
      )}
      {hasPermissions("delete:organisations") && (
        <>
          {/* <DeleteUserModal
            isOpen={isOpenDelete}
            handleClose={handleCloseDelete}
            user={deleteContext}
          /> */}
        </>
      )}
    </>
  );
};

export default OrganisationsTable;
