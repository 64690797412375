import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Formik, Form as FormikForm } from "formik";
import { FormattedMessage } from "react-intl";
import { useHistory } from "react-router-dom";
import * as yup from "yup";

import { axiosInstance } from "api/request";
import { postLogin } from "api/user";
import { LoginPayloadType } from "api/user/types";
import FormikTextField from "components/forms/FormikTextField";

const LoginForm = () => {
  const history = useHistory();
  const initialValues = {
    email: "",
    password: "",
  };

  const schema = yup.object({
    email: yup.string().email("Wrong email format").required(),
    password: yup.string().required(),
  });

  const { mutateAsync: login, status } = useMutation(
    async (values: LoginPayloadType) => {
      const { data: loginData } = await postLogin(values);

      localStorage.setItem("JWToken", loginData?.token);
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${loginData?.token}`;
    },
  );

  const handleSubmit = async (values: LoginPayloadType) => {
    await login(values, {
      onSuccess: () => history.push("/credit-evaluations"),
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
    >
      <FormikForm>
        <FormControl fullWidth sx={{ marinBottom: 2 }}>
          <Typography mb={0.3}>
            <FormattedMessage id="LOGIN.EMAIL" />
          </Typography>
          <FormikTextField name="email" />
        </FormControl>
        <FormControl fullWidth>
          <Typography mb={0.3}>
            <FormattedMessage id="LOGIN.PASSWORD" />
          </Typography>
          <FormikTextField name="password" type="password" />
        </FormControl>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mt={2}
          mb={3.5}
        >
          <FormGroup>
            <FormControlLabel
              control={<Checkbox defaultChecked />}
              label="Remember Me"
              sx={{ fontSize: 14 }}
            />
          </FormGroup>
        </Stack>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={status === "loading"}
          fullWidth
        >
          Login
        </LoadingButton>
      </FormikForm>
    </Formik>
  );
};

export default LoginForm;
