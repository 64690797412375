import { Box, ButtonBase, Stack, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

import { CustomersType } from "api/customers/types";
import UserAvatar from "components/UserAvatar/UserAvatar";

const CreditEvaluationCustomer = ({
  customer,
}: {
  customer?: CustomersType;
}) => {
  const history = useHistory();

  return (
    <Stack
      onClick={() => history.push(`/customers/${customer?._id}`)}
      component={ButtonBase}
      sx={{ px: 1 }}
      direction="row"
    >
      <UserAvatar
        userName={
          customer ? `${customer?.firstName} ${customer?.lastName}` : "-"
        }
      />
      <Box ml={2}>
        <Typography variant="h4" textAlign="left" fontWeight="bold" mb={0.5}>
          {customer?.firstName} {customer?.lastName}
        </Typography>
        <Typography
          mb={0.5}
          sx={{
            color: "primary.main",
          }}
        >
          {customer?.email}
        </Typography>
        {/* <Typography mb={0.5}>*** *** 1245</Typography> */}
      </Box>
    </Stack>
  );
};

export default CreditEvaluationCustomer;
