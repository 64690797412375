import { Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import { FormattedMessage, useIntl } from "react-intl";

import UsersTable from "./UsersTable";

const UsersPage = () => {
  const intl = useIntl();

  return (
    <>
      <Helmet title={intl.formatMessage({ id: "USERS.TITLE" })} />

      <Typography variant="h2" fontWeight="bold" mt={1}>
        <FormattedMessage id="USERS.TABLE.TITLE" />
      </Typography>
      <UsersTable />
    </>
  );
};

export default UsersPage;
