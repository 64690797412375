import { ExpandMore } from "@mui/icons-material";
import { Grid, Paper, Skeleton, Stack, Typography } from "@mui/material";
const LoanAffordabilitiesLoading = () => {
  return (
    <>
      <Grid container mb={2} justifyContent="flex-start">
        {/* Header */}
        <Grid item xs={12}>
          <Paper sx={{ border: "none" }}>
            <Stack
              sx={{
                py: 1.5,
                px: 2,
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                background: "#e6eaf4",
              }}
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography fontWeight="bold">
                <Skeleton sx={{ width: "120px" }} />
              </Typography>
              <ExpandMore
                sx={{ color: "#73757b", transform: "rotate(180deg)" }}
              />
            </Stack>
          </Paper>
        </Grid>

        {/* Details */}
        <Grid container mb={2} justifyContent="flex-start">
          <Grid item xs={12}>
            <Paper>
              <Stack
                sx={{
                  py: 1.5,
                  px: 2,
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body2">Term</Typography>
                <Typography variant="body2">
                  Rate:{" "}
                  <Typography component="span" variant="body2" color="green">
                    <Skeleton sx={{ display: "inline-block" }} width="50px" />
                  </Typography>
                </Typography>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper>
              <Stack
                sx={{
                  py: 1.5,
                  px: 2,
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body2">60</Typography>
                <Typography variant="body2">
                  <Skeleton width="80px" />
                </Typography>
              </Stack>
              <Stack
                sx={{
                  py: 1.5,
                  px: 2,
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body2">72</Typography>
                <Typography variant="body2">
                  <Skeleton width="80px" />
                </Typography>
              </Stack>
              <Stack
                sx={{
                  py: 1.5,
                  px: 2,
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body2">84</Typography>
                <Typography variant="body2">
                  <Skeleton width="80px" />
                </Typography>
              </Stack>
              <Stack
                sx={{
                  py: 1.5,
                  px: 2,
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body2">120</Typography>
                <Typography variant="body2">
                  <Skeleton width="80px" />
                </Typography>
              </Stack>
              <Stack
                sx={{
                  py: 1.5,
                  px: 2,
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body2">144</Typography>
                <Typography variant="body2">
                  <Skeleton width="80px" />
                </Typography>
              </Stack>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper>
              <Stack
                sx={{
                  py: 1.5,
                  px: 2,
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body2">DTI</Typography>
                <Typography variant="body2">
                  <Skeleton width="30px" />
                </Typography>
              </Stack>
              <Stack
                sx={{
                  py: 1.5,
                  px: 2,
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body2">
                  <Skeleton width="80px" />
                </Typography>
                <Typography variant="body2">
                  <Skeleton width="80px" />
                </Typography>
              </Stack>
              <Stack
                sx={{
                  py: 1.5,
                  px: 2,
                  borderBottom: "1px solid rgba(224, 224, 224, 1)",
                }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography variant="body2">
                  <Skeleton width="60px" />
                </Typography>
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default LoanAffordabilitiesLoading;
