import { LoadingButton } from "@mui/lab";
import {
  Box,
  Divider,
  Grid,
  Stack,
  //   TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { Formik, Form as FormikForm } from "formik";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";

import { updateProfilePassword as updateProfilePasswordAPI } from "api/user";
import FormikTextField from "components/forms/FormikTextField";

const PasswordForm = () => {
  const initialValues = {
    password: "",
    repeatPassword: "",
  };

  const schema = yup.object({
    password: yup
      .string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Password is required"),
    repeatPassword: yup
      .string()
      .required("Password confirmation is required")
      .when("password", {
        is: (val: string | any[]) => (val && val.length > 0 ? true : false),
        then: (schema) =>
          schema.oneOf(
            [yup.ref("password")],
            "Password and Confirm Password didn't match",
          ),
      }),
  });

  const { mutateAsync: updatePassword, isLoading } = useMutation(
    async (values: any) => {
      await updateProfilePasswordAPI(values);
    },
  );

  const handleSubmit = async (values: any) => {
    await updatePassword(values, {
      onSuccess: () => {
        //     if (user) setUser({ ...user, name: values.name, email: values.email });
      },
    });
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        <FormikForm>
          <Box sx={{ p: 3 }}>
            <Grid container columnSpacing={2} rowSpacing={3}>
              <Grid item xs={12} md={12}>
                <Typography variant="h5">Change Password</Typography>
              </Grid>

              <Grid item xs={12} md={6}>
                <FormikTextField
                  name="password"
                  type="password"
                  label="New Password"
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormikTextField
                  name="repeatPassword"
                  type="password"
                  label="Repeat Password"
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
          <Divider />
          <Stack
            direction="row"
            justifyContent="flex-end"
            spacing={1}
            sx={{ p: 2 }}
          >
            <LoadingButton
              size="medium"
              variant="contained"
              loading={isLoading}
              type="submit"
            >
              <FormattedMessage id="GLOBAL.SAVE" />
            </LoadingButton>
          </Stack>
        </FormikForm>
      </Formik>
    </>
  );
};

export default PasswordForm;
