import {
  DeleteOutlined as DeleteIcon,
  EditOutlined as EditIcon,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { CellProps } from "react-table";

import { CreditEvaluationIncome } from "api/creditEvaluations/types";
import { hasPermissions } from "components/stores/UserStore";

interface Props<T extends {}> extends CellProps<T> {
  handleOpenEdit: (ctx?: T) => void;
  handleOpenDelete: (ctx?: T) => void;
}

const ActionsFormatter = ({
  row,
  handleOpenEdit,
  handleOpenDelete,
}: Props<CreditEvaluationIncome>) => {
  return (
    <div>
      {hasPermissions("update:credit-evaluations") && (
        <IconButton
          // data-testid="edit-port-button"
          color="warning"
          onClick={() => handleOpenEdit(row.original)}
        >
          <EditIcon />
        </IconButton>
      )}
      {hasPermissions("update:credit-evaluations") && (
        <IconButton
          // data-testid="delete-port-button"
          color="error"
          onClick={() => handleOpenDelete(row.original)}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </div>
  );
};

export default ActionsFormatter;
