import { Paginated, QueryParamsType, normalizeQueryParams } from "api/utils";

import request, { APICall } from "../request";
import {
  EditLoanApplicationPayloadType,
  LoanApplicationsType,
  NewLoanApplicationPayloadType,
} from "./types";

export const getLoanApplications = (
  queryParams?: QueryParamsType<LoanApplicationsType>,
): APICall<Paginated<LoanApplicationsType[]>> =>
  request({
    url: `/loan-application${normalizeQueryParams(queryParams)}`,
    method: "GET",
  });

export const addLoanApplication = (
  loanApplication: NewLoanApplicationPayloadType,
): APICall<LoanApplicationsType> =>
  request({
    data: loanApplication,
    url: "/loan-application",
    method: "POST",
  });

export const editLoanApplication = (
  LoanApplication: EditLoanApplicationPayloadType,
): APICall<LoanApplicationsType> =>
  request({
    data: LoanApplication,
    url: `/loan-application/${LoanApplication._id}`,
    method: "PUT",
  });

export const editLoanApplicationStatus = (
  LoanApplicationId: string,
  status: LoanApplicationsType["status"],
): APICall<LoanApplicationsType> =>
  request({
    data: { status },
    url: `/loan-application/status/${LoanApplicationId}`,
    method: "PUT",
  });

export const deleteLoanApplication = (
  LoanApplicationId: string,
): APICall<null> =>
  request({
    url: `/loan-application/${LoanApplicationId}`,
    method: "DELETE",
  });
