import { CreditEvaluationIncomeOverviewEnum } from "api/creditEvaluations/types";

export const incomeOverviewTypeFormatter = (
  value: CreditEvaluationIncomeOverviewEnum | undefined | null,
) => {
  switch (value) {
    case CreditEvaluationIncomeOverviewEnum.STATED_INCOME:
      return "Stated Income";
    case CreditEvaluationIncomeOverviewEnum.INDIVIDUAL_INCOME_CURRENT_YEAR:
      return "Individual Income | Current Year";
    case CreditEvaluationIncomeOverviewEnum.INDIVIDUAL_INCOME_PRIOR_YEAR:
      return "Individual Income | Prior Year";
    case CreditEvaluationIncomeOverviewEnum.INDIVIDUAL_INCOME_2_YEAR_AVERAGE:
      return "Individual Income | 2 Year Average";
    case CreditEvaluationIncomeOverviewEnum.INDIVIDUAL_INCOME_3_YEAR_AVERAGE:
      return "Individual Income | 3 Year Average";
    case CreditEvaluationIncomeOverviewEnum.INDIVIDUAL_INCOME_RENT_ADJUSTED:
      return "Individual Income | Rent Adjusted";
    case CreditEvaluationIncomeOverviewEnum.INDIVIDUAL_INCOME_STUDENT_LOAN_ADJUSTED:
      return "Current Income | Student Loan Adjusted";
    case CreditEvaluationIncomeOverviewEnum.INDIVIDUAL_INCOME_HALF_MORTGAGE:
      return "Current Income | Half Mortgage";
    case CreditEvaluationIncomeOverviewEnum.HOUSEHOLD_INCOME:
      return "Household Income";
    default:
      return "Income";
  }
};
