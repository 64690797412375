import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent } from "@mui/material";
import { MutationStatus } from "@tanstack/react-query";
import { Form as FormikForm, FormikProvider, useFormik } from "formik";
import React, { FC, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import * as yup from "yup";

// COMPONENTS:

// import FormikAutocomplete from "components/forms/FormikAutocomplete";
import FormikAutocomplete from "components/forms/FormikAutocomplete";
import FormikNumberField from "components/forms/FormikNumberField";
import FormikTextField from "components/forms/FormikTextField";

export type OrganisationFormValues = {
  name: string;
  email: string;
  leadSource: string;
  brand: string | null;
  partnerPayout: {
    active: boolean;
    type: "they-invoice" | "percentage";
    value: number | null;
  };
};

interface IOrganisationForm {
  handleSubmit: (values: OrganisationFormValues) => void;
  handleClose: () => void;
  initialValues?: OrganisationFormValues;
  submitStatus?: MutationStatus;
}

const OrganisationForm: FC<IOrganisationForm> = ({
  handleSubmit,
  handleClose,
  initialValues = {
    name: "",
    email: "",
    leadSource: "",
    brand: null,
    partnerPayout: {
      active: false,
      type: "they-invoice",
      value: null,
    },
  },
  submitStatus,
}) => {
  // const { formatMessage } = useIntl();
  const schema = yup.object({
    name: yup.string().required(),
    email: yup.string().email("Wrong email format").required(),
    leadSource: yup.string().required(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: handleSubmit,
  });

  const { values, setFieldValue } = formik;

  const partnerPayoutType = useMemo(() => {
    if (!values.brand) {
      return [{ label: "They Invoice", value: "they-invoice" }];
    }

    return [
      { label: "They Invoice", value: "they-invoice" },
      {
        label: "Percentage",
        value: "percentage",
      },
    ];
  }, [values.brand]);
  return (
    <FormikProvider value={formik}>
      <FormikForm>
        <DialogContent>
          <FormikTextField
            name="name"
            label="Company Name"
            // label={<FormattedMessage id="GLOBAL.EMAIL" />}
          />
          <FormikTextField name="email" label="Company Email" />
          <FormikTextField
            name="leadSource"
            label="Lead Source"
            // label={<FormattedMessage id="GLOBAL.EMAIL" />}
          />
          <FormikAutocomplete
            name="brand"
            label="Brand"
            // label={<FormattedMessage id="GLOBAL.EMAIL" />}
            options={[
              {
                label: "XS Capital Solutions",
                value: "XS Capital Solutions",
              },
              {
                label: "Green Leaf Money",
                value: "Green Leaf Money",
              },
              {
                label: "GLM Enterprises, LTD",
                value: "GLM Enterprises, LTD",
              },
              {
                label: "CBFsg",
                value: "CBFsg",
              },
              {
                label: "CBF",
                value: "CBF",
              },
            ]}
            onChange={(e, value) => {
              if (value?.value) {
                setFieldValue("brand", value.value);
              } else {
                setFieldValue("brand", null);
                setFieldValue("partnerPayout.type", "they-invoice");
              }
            }}
          />
          <FormikAutocomplete
            name="partnerPayout.type"
            label="Referral Partner Payout"
            options={partnerPayoutType}
          />
          {values.partnerPayout.type !== "they-invoice" && (
            <FormikNumberField
              name="partnerPayout.value"
              label="Referral Partner Payout Value"
              suffix="%"
            />
          )}
        </DialogContent>

        <DialogActions>
          <Button
            // data-testid="new-edit-port-close-button"
            onClick={handleClose}
          >
            <FormattedMessage id="GLOBAL.CLOSE" />
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={submitStatus === "loading"}
          >
            <FormattedMessage id="GLOBAL.SAVE" />
          </LoadingButton>
        </DialogActions>
      </FormikForm>
    </FormikProvider>
  );
};

export default OrganisationForm;
