import React, { forwardRef } from "react";
//@ts-expect-error
import { IMaskInput } from "react-imask";

type CustomProps = {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
};

const MaskSocialInput = forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="000000000"
        definitions={{
          "0": /[0-9]/,
        }}
        inputRef={ref}
        onAccept={(value: any) => {
          let newValue: string = value;

          onChange({ target: { name: props.name, value: newValue } });
        }}
        lazy={false}
        autofix
        overwrite
      />
    );
  },
);

export default MaskSocialInput;
