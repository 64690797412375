import { Grid, Paper, Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import loginImage from "assets/images/login-image.png";

import RegisterForm from "./RegisterForm";

const RegisterPage = () => {
  return (
    <Grid container py={8} px={6} height="100vh" sx={{ overflowY: "scroll" }}>
      <Grid
        item
        sm={6}
        width="50%"
        height="100%"
        sx={{ display: { md: "block", xs: "none" } }}
      >
        <img
          src={loginImage}
          alt="Login"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      </Grid>
      <Grid item xl={2} lg={1}></Grid>
      <Grid item xl={4} lg={5} md={6} xs={12}>
        <Stack direction="row" alignItems="center" height="100%">
          <Paper
            sx={{
              padding: { lg: 7, xs: 5 },
              width: "100%",
              marginLeft: { lg: 0, sm: "50px" },
            }}
          >
            <Typography variant="h2" fontWeight="bold" mb={5.5}>
              <FormattedMessage id="REGISTER.TITLE" />
            </Typography>

            <RegisterForm />
          </Paper>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default RegisterPage;
