import {
  DeleteOutlined as DeleteIcon,
  EditOutlined as EditIcon,
  // VisibilityOutlined as VisibilityIcon
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
// import { useHistory } from "react-router-dom";
import { Cell } from "react-table";

import { LoanApplicationsType } from "api/loanApplications/types";
import { hasPermissions } from "components/stores/UserStore";
// import { hasPermissions } from "components/stores/UserStore";

// LOGIC:
// import { hasPermissions } from "components/stores/UserStore";

interface Props<T extends {}> extends Cell<T> {
  handleOpenEdit: (ctx?: T) => void;
  handleOpenDelete: (ctx?: T) => void;
}

const LoanApplicationsActionsFormatter = ({
  row,
  handleOpenDelete,
  handleOpenEdit,
}: Props<LoanApplicationsType>) => {
  // const history = useHistory();

  return (
    <>
      {hasPermissions("update:credit-evaluations") && (
        <IconButton
          // data-testid="edit-port-button"
          color="warning"
          onClick={() => handleOpenEdit(row.original)}
        >
          <EditIcon />
        </IconButton>
      )}
      {hasPermissions("update:credit-evaluations") && (
        <IconButton
          // data-testid="delete-port-button"
          color="error"
          onClick={() => handleOpenDelete(row.original)}
        >
          <DeleteIcon />
        </IconButton>
      )}
    </>
  );
};

export default LoanApplicationsActionsFormatter;
