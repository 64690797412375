import { Add as AddIcon } from "@mui/icons-material";
import { Dialog, DialogTitle, Divider, Stack } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React from "react";
import { FormattedMessage } from "react-intl";

import { inviteUser as inviteUserAPI } from "api/users";
import { usersKeys } from "api/users/queries";
import { NewUserPayloadType } from "api/users/types";
import { useUserStore } from "components/stores/UserStore";

import UserForm, { UserFormValues } from "./UserForm";

// LOGIC:

// TYPES:

interface NewUserModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const NewUserModal = ({ isOpen, handleClose }: NewUserModalProps) => {
  const [user] = useUserStore((s) => [s.user]);

  const queryClient = useQueryClient();

  const { mutateAsync: inviteUser, status } = useMutation(
    async (data: NewUserPayloadType) => {
      const res = await inviteUserAPI(data);
      await queryClient.invalidateQueries(usersKeys.list());
      return res.data;
    },
  );

  const handleSubmit = async (values: UserFormValues) => {
    await inviteUser(values, {
      onSuccess: () => handleClose(),
    });
  };

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Stack direction="row" alignItems="center">
          <AddIcon sx={{ mr: 1 }} />
          <FormattedMessage id="USERS.FORM.NEW_USER.HEADER" />
        </Stack>
      </DialogTitle>
      <Divider />

      <UserForm
        submitStatus={status}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        initialValues={{
          organisation: user?.organisation._id ?? "",
          email: "",
          role: null,
        }}
      />
    </Dialog>
  );
};

export default NewUserModal;
